import {menus} from './menu';
import {Component, Input, OnInit} from '@angular/core';
import {GlobalService} from '../../service/global.service';
import { CoreStatusService } from '../../service/core-status.service';
// import { MediaChange, ObservableMedia } from '@angular/flex-layout';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public menus;

  @Input() isVisible = true;
  visibility = 'shown';

  sideNavOpened = true;
  matDrawerOpened = false;
  matDrawerShow = true;
  sideNavMode = 'side';
  itemsShown = false;
  constructor(public globalService: GlobalService) {
  }
  ngOnInit() {
    this.visibility = this.isVisible ? 'shown' : 'hidden';
    this.sideNavMode = 'over';
    this.sideNavOpened = false;
    this.matDrawerOpened = false;
    this.matDrawerShow = false;
    this.menus = menus;
   

  }


}
