import { Directive, Input} from '@angular/core';
import { NeededPermission } from '../../service/permission.service';
import { HasPermissionDirective } from './has-permission.directive';
import { BasePermissionDirective } from './base-permission-directive';

@Directive({
  selector: '[hasAllPermissions]'
})
export class HasAllPermissionsDirective extends BasePermissionDirective {
  @Input()
  set hasAllPermissions(p) {
    this.checkChanged(p);
  }

  protected checkPermissions(perms: NeededPermission[]) : Promise<boolean> {
    return this.permissionService.hasAllPermissions(perms);
  }
} 
