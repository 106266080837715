import { Component, ElementRef, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'page-ribbon',
  template: `
    <div class="ribbon">
      <a href="" *ngIf="ribbonEnv !== 'Production'">{{ ribbonEnv }}</a>
    </div>
  `,
  styleUrls: ['page-ribbon.scss']
})
export class PageRibbonComponent implements OnInit {
  ribbonEnv: string;

  constructor() {}

  ngOnInit() {
  this.ribbonEnv = environment.mode;
  }
}
