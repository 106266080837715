import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IProgram } from 'src/app/shared/model/cityIdcore/program.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simple-program-selector',
  templateUrl: './simple-program-selector.component.html',
  styleUrls: ['./simple-program-selector.component.scss']
})
export class SimpleProgramSelectorComponent implements OnInit, OnDestroy {
  protected programs: IProgram[] = [];
  @Input() programId: number;
  @Input() readOnly: boolean = false;
  @Output() onChange = new EventEmitter<number>();

  public programCtrl: FormControl<IProgram> = new FormControl<IProgram>(null);
  public programFilterCtrl: FormControl<string> = new FormControl<string>('');
  public filteredPrograms: ReplaySubject<IProgram[]> = new ReplaySubject<IProgram[]>(1);
  @ViewChild('singleSelect', {static: true}) singleSelect: MatSelect;
  protected _onDestroy = new Subject<void>();


  constructor( private programService: ProgramService) { }

  ngOnInit(): void {
    if (this.programId) {
      let currentProgram = {id: this.programId} as IProgram;
      this.programCtrl.setValue(currentProgram);
      if ( this.readOnly) {
        this.programCtrl.disable();
      }
    }

    this.programCtrl.valueChanges.subscribe(change => {
      this.programSelected(change?.id);
    });

    
    this.programService.query({'withorgname': true}).subscribe(res => {  
      this.programs = res.body.sort((a, b) => ( a.organisationName+a.name).localeCompare( b.organisationName+b.name));

      this.filteredPrograms.next(this.programs.slice());  //set initial program list
      //listen for changes
      this.programFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe( () => {
          this.filterPrograms()
        });

    });

  }


  ngAfterViewInit() {
    this.setInitialValue();
  }

  //Sets the initial value after the filtered programs are loaded initially
  protected setInitialValue() {
    this.filteredPrograms
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredPRograms are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: IProgram, b: IProgram) => a && b && a.id === b.id;
      });
  }


  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected filterPrograms() {
    if (!this.programs) {
      return;
    }
    // get the search keyword
    let search = this.programFilterCtrl.value;
    if (!search) {
      this.filteredPrograms.next(this.programs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the programs
    this.filteredPrograms.next(
      this.programs.filter(program => program.name.toLowerCase().indexOf(search) > -1)
    );
  }  


  programSelected(newProgramId: number) {
    this.onChange.emit(newProgramId);
  }

}
