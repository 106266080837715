import { Directive, Input } from "@angular/core"
import { BasePermissionDirective } from "./base-permission-directive";

@Directive({ selector: '[hasEditPermission]' })
export class HasEditPermissionDirective extends BasePermissionDirective {
    @Input() 
    set hasEditPermission(s) {
        this.checkChanged(s);
    }

    protected doSetValue(r: boolean): void {
        let element = this.elementRef.nativeElement;
        let tag = element.tagName.toLowerCase();
        if (tag == 'app-local-date-selector') { 
            this.control.valueAccessor.setDisabledState(!r); // dit zou bij alle custom form elementen moeten werken
        } else if (tag.includes("check") || tag.includes("select") || tag.includes("toggle")) {
            if (this.control) {
                if (r) {
                    this.control.control['enable']();
                }
                else {
                    this.control.control['disable']();
                }
            }
            else {
                console.log("WARN: cannot disable select/checkbox outside form");
            }
        } else {
            if (r) {
                element.removeAttribute('readonly');
            }
            else {
                element.setAttribute('readonly', true);
            }
        }
    }
}