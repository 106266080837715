<mat-toolbar class="table-header">
  <h1 class="mat-headline-5 center-align">{{'cityIdApp.report.title' | translate}}</h1>
  <button *ngIf="selectedReport && hasDeletePermission" mat-raised-button (click)="delete(selectedId)" class="button-style">Delete</button>
  <button *ngIf="selectedReport && hasReadOrUpdatePermission" mat-raised-button [routerLink]="['/report', selectedId, 'edit']" class="button-style">{{getActionLabel()}}</button>
  <button *ngIf="hasCreatePermission" mat-raised-button [routerLink]="['/report', 'new']" class="button-style">Add</button>
</mat-toolbar>

<div class="input-container">
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <div *ngIf="reportsLoaded" fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
      <mat-form-field appearance="outline"   class="fieldwidthMedium">
        <mat-label translate="cityIdApp.report.select"></mat-label>      
        <mat-select [disabled]="isRunning" [(ngModel)]="selectedId" (selectionChange)="onReportChange($event)">
          <mat-option *ngFor="let report of reports" [value]="report.id">{{report.id}} - {{report.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="favorites" >
        <div *ngIf="isFavorite(true)" fxLayoutGap="10px">
          <button mat-icon-button color="warn" (click)="favorite(false)">
            <mat-icon>favorite</mat-icon>
          </button>
          <span class="is-favorite">{{ 'cityIdApp.report.unmarkFavorite' | translate }}</span>
        </div>
        <div *ngIf="!isFavorite(false)" fxLayoutGap="10px">
          <button mat-icon-button  (click)="favorite(true)">      
            <mat-icon>favorite_border</mat-icon>
          </button>
          <span>{{ 'cityIdApp.report.makeFavorite' | translate }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="selectedReport" class="description"> 
      {{selectedReport.description}}
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" *ngIf="selectedReport">
  <div>
    <app-budget-button *ngIf="selectBudgets" [disabled]="isRunning" (budgetIds)="onBudgetIdsChange($event)" ></app-budget-button>
  </div>
  <div>
    <app-program-selector-button [tag]="tag" *ngIf="selectPrograms" [disabled]="isRunning" (ids)="onProgramIdsChange($event)" ></app-program-selector-button>
  </div>
   <div>
      <app-local-date-selector *ngIf="selectStartDate" [(localDate)]="startDate" (localDateChange)="onDateChange()" [placeholderKey]="'cityIdApp.report.startDate'"></app-local-date-selector>
  </div>
  <div >
    <app-local-date-selector *ngIf="selectEndDate" [(localDate)]="endDate" (localDateChange)="onDateChange()" [placeholderKey]="'cityIdApp.report.endDate'"></app-local-date-selector>
  </div> 
</div>

<mat-toolbar class="bottom-bar"> 
  <button *ngIf="!hideGenerate()" mat-raised-button [disabled]="hideGenerate()" (click)="generate()" class="button-style" color="primary">{{'cityIdApp.report.generate' | translate}}</button>
</mat-toolbar>

<div style="display: flex; justify-content: center; align-items: center;" *ngIf="isRunning">
  <mat-spinner diameter="100"></mat-spinner>
</div>
<div style="display: flex; justify-content: center; align-items: center;" *ngIf="noResults">
  <P>Geen resultaten</P>
</div>
<app-export *ngIf="isGenerated" [title]="reportName" [data]="reportData" [saveName]="reportName"></app-export>