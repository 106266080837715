/* tslint:disable:no-string-literal */
import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {ActivatedRoute } from '@angular/router';
import {TransactionService} from '../../shared/service/transaction.service';
import {GlobalService} from '../../shared/service/global.service';
import {AlertService} from '../../shared/component/alert';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NeededPermission } from 'src/app/shared/service/permission.service';
import { Cancel } from 'src/app/shared/model/cityIdcore/cancel.model';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: "app-transaction",
  templateUrl: "./transaction.component.html",
  styleUrls: ["./transaction.component.scss"],
  // providers: [
  //   {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
  // ],
})
export class TransactionComponent implements OnInit, OnDestroy {
  routeData: any;
  programId: number;
  partnerId: number;
  selection = new SelectionModel<string>(true, []);
  updatePermission = [NeededPermission.TransactionUpdate];
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private transactionService: TransactionService,
    public datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.programId = this.globalService.getProgram().id;
    this.globalService.programChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.programId = this.globalService.getProgram().id;
      });

    this.activatedRoute.queryParams.subscribe((params) => {
      //  console.log('Router param change: params = ', params);
      //   this.updateFilterFromLS(this.storeFormFieldValueService.readFieldValuesFromStorageForPage(this.localStorageKey));
      //console.log('TransacitonComponent, query params = ', params);
      if (params.partnerId) {
        // this.filter.provider = Number(params.providerId);
        //todo
        this.partnerId = +params.partnerId;
      }
      // this.loadAll();
    });
  }

  ngOnDestroy(): void {
    //console.log('destroy');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  previousState() {
    window.history.back();
  }

  cancelTransaction() {
    const dialogRef = this.dialog.open(CancelDialogComponent);
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.transactionService.cancelTransaction(data).subscribe(
          (res) => {
            this.alertService.success("Transaction " + data.id + " cancelled");
           
          },
          (error) => {
            this.alertService.error(
              "Error cancelling transaction " +
                data.id +
                " " +
                error.error.message
            );
          }
        );
      }
    });
  }
}
