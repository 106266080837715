import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgramSelectorDialogComponent } from '../program-selector-dialog/program-selector-dialog.component';
import { StoredSelection } from '../program-selector.component';

@Component({
  selector: 'app-program-selector-button',
  templateUrl: './program-selector-button.component.html',
  styleUrls: ['./program-selector-button.component.scss']
})
export class ProgramSelectorButtonComponent implements OnInit {
  selectedIds: number[] = [];
  count: number = 0;
  @Input() tag: String;
  @Output() ids = new EventEmitter<number[]>();
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getStoredProgramIds();
    this.count = this.selectedIds ? this.selectedIds.length : 0;
    if (this.selectedIds) {
      this.ids.emit(this.selectedIds);
    }
  }

  selectIds() {
    let dialogRef = null;
    dialogRef = this.dialog.open(ProgramSelectorDialogComponent, {
      minWidth: '500px',
      panelClass: 'custom-mat-dialog-panel',
      data: {
        tag: this.tag,
        save: (result) => {
          this.count = result.ids.length;
          this.ids.emit(result.ids)
          dialogRef.close();
        },
        cancel: () => {
          dialogRef.close();
        },
      }
    });
  }

  loadStoredSelection(): StoredSelection {
    if (!localStorage) {
      return;
    }
    let loadedInfo = localStorage.getItem(this.tag + `-program-selections`);
    if (loadedInfo) {
      return JSON.parse(loadedInfo);
    }
    let empty: StoredSelection;
    empty = new StoredSelection();
    empty.orgarnisations = [];
    return empty;
  }

  private getStoredProgramIds() {
    let storedSelection = this.loadStoredSelection();
    for (let organization of storedSelection.orgarnisations) {
      for (let program of organization.programSelectionModelArray.filter(p => p.selected === true)) {
        this.selectedIds.push(program.programId);
      }
    }
  }

}