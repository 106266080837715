import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IProgramParticipant} from '../model/cityIdcore/program-participant.model';

import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';

type EntityResponseType = HttpResponse<IProgramParticipant>;
type EntityArrayResponseType = HttpResponse<IProgramParticipant[]>;

@Injectable({providedIn: 'root'})
export class ProgramParticipantService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/program-participants';

  constructor(protected http: HttpClient) {
  }


  findByParticipantId(participantId: number): Observable<EntityResponseType> {
    return this.http.get<IProgramParticipant>(`${this.resourceUrl}-byparticipantId/${participantId}`, {observe: 'response'});
  }



  findByProgramIdAndNotProvisioning(programId: number, budgetId: number, partFilter :string): Observable<EntityArrayResponseType> {
    return this.http.get<IProgramParticipant[]>(`${this.resourceUrl}-byprogramnotbudget/${programId}/${budgetId}/${partFilter}`, {observe: 'response'});
  }

}
