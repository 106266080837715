<div fxLayout="column" class="components-container-gt-xs">

  <div class="table-component-holder mat-elevation-z4">
  
    <mat-toolbar class="table-header">
      <h1 class="mat-headline-5 text-left" *ngIf="partnerName">
        {{ 'cityIdApp.cityIdcorePartnerArticles.detailsTitle' | translate : { name : partnerName } }}
      </h1>
    </mat-toolbar>

    <div fxLayout="column" class="input-container" *ngIf="partnerArticle">
      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
          <mat-label translate="cityIdApp.cityIdcorePartnerArticles.eanCode"></mat-label>
          <input matInput readonly [(ngModel)]="partnerArticle.article.eanCode">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label translate="cityIdApp.cityIdcorePartnerArticles.description"></mat-label>
          <input matInput readonly [(ngModel)]="partnerArticle.article.description">
        </mat-form-field>
      </div>
      <table id="values">
        <tr>
          <th translate="cityIdApp.cityIdcorePartnerArticles.unit"></th>
          <th translate="cityIdApp.cityIdcorePartnerArticles.partnerValue"></th>
        </tr>
        <tr *ngFor="let v of partnerArticle.values">
          <td>
            <select [(ngModel)]="v.unit" (change)="checkValid()">
              <option value=""></option>
              <option *ngFor="let u of units()" [value]="u">{{ 'cityIdApp.unit.' + u | translate }}</option>
            </select>
          </td>
          <td>
            <input type="text" [(ngModel)]="v.partnerValue" (input)="checkValid()">
          </td>
        </tr>
      </table>

      <mat-toolbar class="bottom-bar"> 
        <span class="rightalign-spacer"></span>
        <button mat-raised-button type="submit" style="margin-right: 10px" class="button-style" color="primary" translate="cityIdApp.general.save" (click)="save()"></button>
        <button mat-raised-button type="button" class="button-style" color="warn" (click)="close()"  translate="cityIdApp.general.close"></button>
      </mat-toolbar>

    </div>
  </div>
</div>
