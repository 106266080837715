import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { MenuitemComponent } from './menuitem/menuitem.component';
import {MaterialDesignModule} from '../../../material-design.module';
import {SharedModule} from '../../../shared.module';


@NgModule({
    declarations: [
        SidebarComponent,
        MenuitemComponent
    ],
    imports: [
       SharedModule,
        CommonModule,
        TranslateModule,
        MaterialDesignModule
    ],

    exports: [
       SharedModule,
        SidebarComponent,
        MenuitemComponent,
        TranslateModule
    ],

})
export class SidebarModule { }
