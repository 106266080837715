<h1 mat-dialog-title>Select Tokens</h1>

<mat-dialog-content>
  <mat-form-field class="example-full-width">
    <input matInput placeholder="Filter" [(ngModel)]="filter" (ngModelChange)="updateFilter()">
  </mat-form-field>
  <mat-selection-list #tokens [(ngModel)]="selectedTokens">
      <mat-list-option *ngFor="let token of tokenArray.slice(0, 6)" [value]="token">
        {{ token.token }}<ng-container *ngIf="token.participantId">&nbsp;(already linked to {{ token.programParticipantName }})</ng-container>
        
      </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar">
    <span class="rightalign-spacer"></span>
      <button mat-raised-button  class="button-style" color="primary" (click)="addtoken()">Add Accesstokens</button>
      <button mat-raised-button type="button" class="button-style" color="warn" (click)="closeDialog()">Cancel</button>
  </mat-toolbar>
</mat-dialog-actions>
