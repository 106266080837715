<mat-list-item class="navmenu" (click)="onItemClick($event)" *hasPermission="menuItem.permission" [routerLink]="menuItem.link ? [menuItem.link] : null" [queryParams]="menuItem.queryParams ? menuItem.queryParams : null"  routerLinkActive #rla="routerLinkActive" [ngClass]="rla.isActive ? 'active-link' : ''" >
        <mat-icon matListItemIcon >{{ menuItem.icon }}</mat-icon>
        <mat-expansion-panel *ngIf="menuItem.sub; else regularItem" (opened)="panelOpenState = true"  (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ menuItem.name }}</mat-panel-title>
              <!-- <mat-panel-description>(submenu)</mat-panel-description> -->
            </mat-expansion-panel-header>
            <mat-nav-list>
                <sidenav-menuitem *ngFor="let subMenuItem of menuItem?.sub" [menuItem]="subMenuItem" ></sidenav-menuitem>
            </mat-nav-list>
            <!-- <p>This is the primary content of the panel.</p>             -->
        </mat-expansion-panel>        

        <ng-template #regularItem>
            <span matListItemTitle>{{ menuItem.name }}</span>
        </ng-template>

</mat-list-item>
