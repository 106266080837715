<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
   <form [formGroup]="form">
      <mat-form-field>
        <input matInput placeholder="Type value" formControlName="inputField">
    </mat-form-field>
   </form>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar"> 
    <span class="rightalign-spacer"></span>
    <button [disabled]= "form.invalid" mat-raised-button color="primary" (click)="save()" translate="cityIdApp.general.save"></button>
    <button mat-raised-button color="warn" (click)="cancel()" translate="cityIdApp.general.cancel"></button>
  </mat-toolbar>
</mat-dialog-actions>
