import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

export class LinearConversion {
  public percentage : number;
  public fixed : number;
}
export class PersonalSavingCost extends LinearConversion {
  public maxPerTransaction: number;
  public maxPerPeriod : number;
  public periodCount : number;
  public period : string;
}
export class CostsAndSavingsSetting {
  public isInherited : boolean;
  public value : LinearConversion;
}
export class PersonalSavingCostSetting {
  public isInherited : boolean;
  public value : PersonalSavingCost;
}
export class CostsAndSavings {
  public partnerCost : CostsAndSavingsSetting;
  public ownerCost : CostsAndSavingsSetting;
  public personalSavings : CostsAndSavingsSetting;
  public goalSavings : CostsAndSavingsSetting;
}
export class CostsAndSavingsLine {
  id : number;
  walletType : string;
  option : string;
  name : string;
  costsAndSavings : CostsAndSavings;
}
export class CommissionLine {
  id : number;
  name : string;
  setting : CostsAndSavingsSetting;
}
export class PersonalSavingCostLine {
  id : number;
  option : string;
  cost : PersonalSavingCostSetting;
}
export class CostsAndSavingsSettings {
  defaultSettings : CostsAndSavingsLine;
  products : CostsAndSavingsLine[];
  budgets : CostsAndSavingsLine[];
  rechargeable : CostsAndSavingsLine[];
  commissions: CommissionLine[];
  personalSavingCosts : PersonalSavingCostLine[];
  constructor() {
    this.products = [];
    this.budgets = [];
    this.rechargeable = [];
  }
}
@Injectable({ providedIn: 'root' })

export class CostsAndSavingsService {
  public baseUrl = environment.SERVER_API_URL + "api/admin";

  constructor(private http: HttpClient) {}

  getUrl(program : number, partner : number) {
    var base = this.baseUrl + "/programs/" + program;
    if (partner) {
      base += "/partners/" + partner;
    }
    return base + "/costs-and-savings";
  }

  get(program : number, partner : number) : Observable<CostsAndSavingsSettings> {
    return this.http.get<CostsAndSavingsSettings>(this.getUrl(program, partner));
  }

  recalculate(program : number, partner : number, settings : CostsAndSavingsSettings) : Observable<CostsAndSavingsSettings> {
    return this.http.post<CostsAndSavingsSettings>(this.getUrl(program, partner), settings);
  }

  save(toSave : CostsAndSavingsSettings) : Observable<HttpResponse<any>> {
    return this.http.post(this.baseUrl + "/costs-and-savings", toSave, {observe: 'response'});
  }
}
