import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NeededPermission, PermissionService } from '../shared/service/permission.service';
import { LoginService } from '../shared/service/login.service';


@Injectable()
export class AuthGuard  {
    constructor(
        private router: Router,
        private permissionService: PermissionService,
        private loginService: LoginService
    ) {}
    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        if (localStorage.getItem('accessToken') != null) {
            const permissions = next.data.permissions as Array<NeededPermission>;
            if (permissions) {
                const result= await this.permissionService.hasSomePermissions(permissions);
                if (!result) {
                    console.log(permissions);
                    this.router.navigate(['/no-access'], {queryParams: {'neededPermissions': permissions.join(',')}});
                    //this.router.navigateByUrl('/no-access' );
                }
                return true;
            } else {
                  // no specific permissions so it is oke to continue
                  console.log('no perms');
                  return true;
             }

        }

    //    this.router.navigate(['/login']);
        console.log("guard forbidden");

        return false;
    }
}
