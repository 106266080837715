<div fxLayout="row" fxLayout.gt-sm="column" fxLayoutGap="20px" *ngIf="id >= 1">
    <div fxFlex.lg=" 100%" fxLayout.lt-lg="column">
        <div fxLayout="column">
            <div fxFlex class="table-component-holder mat-elevation-z4">
                <mat-toolbar class="table-header">
                    <h1 class="mat-headline-5 center-align">{{'cityIdApp.cityIdcoreParticipant.detail.userAccount' |
                        translate}}
                    </h1>
                    <button mat-raised-button [routerLink]="['/useraccount/new', id]" class="button-style">Add User
                        Account</button>
                </mat-toolbar>
                <table mat-table [dataSource]="dataSourceUser">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef> Lastname </th>
                        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="userName">
                        <th mat-header-cell *matHeaderCellDef> Username </th>
                        <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element"> <span
                                [routerLink]="['/useraccount', element.id,'edit']">
                                <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.edit' | translate }}"
                                    aria-hidden="false" aria-label="edit icon">edit</mat-icon>
                            </span></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsUser"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
