import {EventEmitter, Injectable} from '@angular/core';
import {IOrganisation} from '../model/cityIdcore/organisation.model';
import {IProgram} from '../model/cityIdcore/program.model';

@Injectable()
export class GlobalService {
  programChange: EventEmitter<boolean> = new EventEmitter();
  organisationChange: EventEmitter<boolean> = new EventEmitter();
  newProgram: EventEmitter<boolean> = new EventEmitter();
  newOrganisation: EventEmitter<boolean> = new EventEmitter();
  private _organisation: IOrganisation;
  private _program: IProgram;


  getOrganisation() {

    if (!this._organisation && (localStorage.getItem('organisation') && localStorage.getItem('organisation') !== 'undefined')) {
      this._organisation = JSON.parse(localStorage.getItem('organisation'));
    }
    return this._organisation;
  }

  getProgram() {
    if (!this._program && localStorage.getItem('program') && localStorage.getItem('program') !== 'undefined') {
      this._program = JSON.parse(localStorage.getItem('program'));
    }
    return this._program;
  }

  setOrganisation(value: IOrganisation) {
    if ( value) {
      localStorage.setItem('organisation', JSON.stringify(value));
      this._organisation = value;
      this.organisationChange.emit(true);
    }
  }

  setProgram(value: IProgram) {
    if ( value ) {
      localStorage.setItem('program', JSON.stringify(value));
      this._program = value;
      this.programChange.emit(true);  
    }
  }
}
