import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IOrganisation} from '../model/cityIdcore/organisation.model';
import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';

type EntityResponseType = HttpResponse<IOrganisation>;
type EntityArrayResponseType = HttpResponse<IOrganisation[]>;

@Injectable({providedIn: 'root'})
export class OrganisationService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/organisations';

  constructor(protected http: HttpClient) {
  }

  create(organisation: IOrganisation): Observable<EntityResponseType> {
    return this.http.post<IOrganisation>(this.resourceUrl, organisation, {observe: 'response'});
  }

  update(organisation: IOrganisation): Observable<EntityResponseType> {
    return this.http.put<IOrganisation>(this.resourceUrl, organisation, {observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IOrganisation>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  findAll(): Observable<EntityArrayResponseType> {
    return this.http.get<IOrganisation[]>(`${this.resourceUrl}`, {observe: 'response'});
  }

  findAllWithPrograms(activeOnly: boolean): Observable<HttpResponse<IOrganisation[]>> {
    return this.http.get<IOrganisation[]>(`${this.resourceUrl}?includeInactive=${activeOnly}`, {observe: 'response'});

  }

  findForProgram(programId: number): Observable<EntityResponseType> {
    return this.http.get<IOrganisation>(`${this.resourceUrl}/byprogram-id/${programId}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IOrganisation[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }
}
