<div fxLayout="column" fxFlex class="table-component-holder mat-elevation-z4"  *hasPermission="permission()">

<mat-toolbar class="table-header mat-elevation-z4" style="margin-bottom: 10px">
    <h1 class="mat-headline-5 text-left">
      {{ 'cityIdApp.cityIdcoreVouchers.title' | translate : { name : 'access token' } }}: {{accessToken?.token}}
    </h1>
    <button mat-raised-button class="button-style" (click)="addVoucherDialog()">{{'cityIdApp.cityIdcoreVouchers.add' | translate}}</button>
    <button mat-raised-button class="button-style" (click)="openExportDialog()">Export</button>
</mat-toolbar>

<mat-paginator [length]="dataSource.data.length" pageSize="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons>
</mat-paginator>

<table mat-table class="list-table" [dataSource]="dataSource" matSort matSortActive="eanCode" matSortDirection="asc">
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>

  <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreVouchers.code"></th>
    
      <td mat-cell *matCellDef="let row">
        <span class="link" [routerLink]="['/vouchers', 'edit', row.id]" >{{ row.id }}</span>
      </td>
  
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreVouchers.description"></th>
    <td mat-cell *matCellDef="let row">{{ row.description }}</td>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreVouchers.startDate"></th>
    <td mat-cell *matCellDef="let row">{{ row.startDate | date: 'shortDate' }}</td>
  </ng-container>

  <ng-container matColumnDef="endDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreVouchers.endDate"></th>
    <td mat-cell *matCellDef="let row">{{ row.endDate | date: 'shortDate' }}</td>
  </ng-container>

  <ng-container matColumnDef="restrictedTo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreProvisioning.restrictedTo"></th>
    <td mat-cell *matCellDef="let row">{{ row.restrictedTo  }}</td>
  </ng-container>

  <ng-container matColumnDef="maximum">
    <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreVouchers.maximum"></th>
    <td mat-cell *matCellDef="let row">{{ row.maximum }}</td>
  </ng-container>

  <ng-container matColumnDef="remainingUsage">
    <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.cityIdcoreVouchers.remaining"></th>
    <td mat-cell *matCellDef="let row">{{ row.remainingUse }}</td>
  </ng-container>

  <ng-container matColumnDef="add">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
        translate="cityIdApp.cityIdcoreVouchers.add"></th>
    <td mat-cell *matCellDef="let element">
        <span class="clickable" (click)="add(element)"  *hasPermission="hasAddPermission()">
            <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.extend' | translate }}" class="color-set"
                aria-hidden="false" aria-label="edit icon">add</mat-icon>
        </span>
    </td>
</ng-container>

</table>
</div>