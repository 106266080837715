<mat-sidenav-container fullscreen autosize>
  <mat-sidenav opened mode="side">
    <app-sidebar></app-sidebar>

    <div class="footer" #footer>
      <div fxLayout="row" fxLayout.lt-sm="column">
        <div class="uiinfo" fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
          {{'cityIdApp.status.title' | translate}}
        </div>
        <div *ngIf=isUp class="up" fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
          {{'cityIdApp.status.up' | translate}}
        </div>
        <div *ngIf=!isUp class="down" fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
          {{'cityIdApp.status.down' | translate}}
        </div>
    </div> 
    </div>

  </mat-sidenav>
  <section>
    <app-toolbar></app-toolbar>
    <alert></alert>
<router-outlet></router-outlet>
  </section>
</mat-sidenav-container>
