<h1 mat-dialog-title>  {{ titleName | translate }}</h1>
<div mat-dialog-content>
  <app-export [data]="exportData" [saveName]="saveTag"></app-export>
</div>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar"> 
    <span class="rightalign-spacer"></span>
    <button mat-raised-button mat-dialog-close type="button" class="button-style" color="warn">Close</button>
  </mat-toolbar>
</mat-dialog-actions>

