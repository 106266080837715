
import { Component, OnInit } from '@angular/core';
import { CoreStatusService } from '../shared/service/core-status.service';



@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {
  public isUp: boolean;

  authenticated = false;

  constructor(private coreSatusService: CoreStatusService ) { }
  ngOnInit() {
    this.isUp = false;
    this.checkStatus();
    this.coreSatusService.statusChange.subscribe(() => {
     this.isUp = this.coreSatusService.getStatus();
    });
    this.coreSatusService.start();

  }    

  checkStatus() {
    this.coreSatusService.get()
      .subscribe((res) => {
        this.isUp = true;
      });


  }

}
