import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { IOrganisation } from 'src/app/shared/model/cityIdcore/organisation.model';
import { IProgram } from 'src/app/shared/model/cityIdcore/program.model';
import { IProvisioning } from 'src/app/shared/model/cityIdcore/provisioning.model';
import { GlobalService } from 'src/app/shared/service/global.service';
import { OrganisationService } from 'src/app/shared/service/organisation.service';
import { ProgramService } from 'src/app/shared/service/program.service';
import { ProvisioningService } from 'src/app/shared/service/provisioning.service';

class Filter {
  organisationId: number;
}

class Budget {
  id: number;
  name: string;
  checked: boolean;
}

class Provisioning {
  name: string;
  budgets: Budget[];
}

class ProgramProvisioning {
  checked: boolean;
  program: IProgram;
  provisionings: Provisioning[];
}

class Settings {
  orgarnisationId: number;
  programsProvisionings: ProgramProvisioning[];
}

@Component({
  selector: 'app-budget-selector',
  templateUrl: './budget-selector.component.html',
  styleUrls: ['./budget-selector.component.scss']
})


export class BudgetSelectorComponent implements OnInit {
  filter: Filter = new Filter();
  displayedColumns: string[] = ['select', 'name'];
  organisations: IOrganisation[] = [];
  dataSource: MatTableDataSource<ProgramProvisioning>;
  saving: boolean = false;

  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  constructor(
    private programService: ProgramService,
    private provisioningService: ProvisioningService,
    private organisationService: OrganisationService,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) private data: any) { }


  ngOnInit() {
    this.dataSource = new MatTableDataSource<ProgramProvisioning>([]);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name': {
          return item.program.name;
        }
        default: {
          return item[property];
        }
      };
    }
    this.organisationService.findAll().subscribe((res) => {
      this.organisations = res.body
      this.organisations.sort((o1, o2) => o1.name.localeCompare(o2.name));

      let saved: Settings = this.loadBudgetSelection();
      if (saved) {
        this.filter.organisationId = saved.orgarnisationId;
      }
      else {
        this.filter.organisationId = this.globalService.getOrganisation().id;
      }
      this.reload();
    });
  }

  reload(): void {
    this.programService.findByOrganisation(this.filter.organisationId).subscribe((res) => {
      let programs: IProgram[] = res.body;
      let obs = programs.map(p => this.provisioningService.findByProgramId(p.id));
      let programProvisionings: ProgramProvisioning[] = [];
      programs.forEach(p => {

        let pp = new ProgramProvisioning();
        pp.program = p;
        pp.provisionings = [];
        programProvisionings.push(pp);

      });

      forkJoin(obs).subscribe(v => {
        let joined: IProvisioning[] = [];
        v.forEach(p => {
          p.body.forEach(pr => {
            joined.push(pr);
          });
        });
        joined.forEach(prov => {
          let programP = programProvisionings.find(p => prov.programId == p.program.id);
          let provisioning = new Provisioning();
          provisioning.name = prov.name;
          provisioning.budgets = [];
          prov.provisioningBudgets.forEach(b => {
            let budget = new Budget();
            budget.name = b.period;
            budget.id = b.id;
            provisioning.budgets.push(budget);
          });
          this.checkProvisioning(false, provisioning);
          programP.provisionings.push(provisioning);
        });

        // remove program without provisionings
        programProvisionings = programProvisionings.filter(pp => pp.provisionings.length > 0);
        programProvisionings = this.updateWithSavedValues(programProvisionings);

        // check is some settings for this orgarnisation is checked.
        this.dataSource.data = programProvisionings;
      });
    });
  }

  updateWithSavedValues(programProvisionings: ProgramProvisioning[]): ProgramProvisioning[] {
    let saved: Settings = this.loadBudgetSelection();
    if (!saved) {
      return programProvisionings;
    }

    if (this.filter.organisationId === saved.orgarnisationId) {
      // same organisation, update the current selection
      programProvisionings.forEach(p => {

        let savedOptions = saved.programsProvisionings.find(pp => pp.program.id === p.program.id);
        if (savedOptions) {
          p.checked = savedOptions.checked;
          // update the checkbox for the provisionings
          p.provisionings.forEach(prov => {
            let savedProv = savedOptions.provisionings.find(s => s.name === prov.name);
            prov.budgets.forEach(b => {
              if (savedProv) {
                let savedBudget = savedProv.budgets.find(bs => bs.id === b.id);
                if (savedBudget) {
                  b.checked = savedBudget.checked;
                }
              }

            });
          });
        }
      });
    }
    return programProvisionings;
  }

  checkProgram(checked: boolean, program: ProgramProvisioning) {
    program.checked = checked;
    program.provisionings.forEach(p => this.checkProvisioning(checked, p));
  }

  checkProvisioning(checked: boolean, provisioning: Provisioning) {
    provisioning.budgets.forEach(function (b) {
      b.checked = checked;
    });
  }

  provisioningChecked(provisioning: Provisioning): boolean {
    let count = provisioning.budgets.filter(b => b.checked == true).length;
    return count == provisioning.budgets.length;
  }

  provisioningUnchecked(provisioning: Provisioning): boolean {
    let count = provisioning.budgets.filter(b => b.checked == true).length;
    return count == 0;
  }

  checkBudget(checked: boolean, budget: Budget) {
    budget.checked = checked;
  }

  onNoClick(): void {
    this.data.cancel();
  }

  hasSelection(): boolean {
    return this.dataSource.data.findIndex(p => p.checked == true) >= 0;
  }

  isAllSelected() {
    let count = this.dataSource.data.filter(p => p.checked).length;
    return count == this.dataSource.data.length;
  }

  isIndetermined() {
    let count = this.dataSource.data.filter(p => p.checked).length;
    return count != 0 && count != this.dataSource.data.length;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.dataSource.data.forEach(p => this.checkProgram(false, p));
    }
    else {
      this.dataSource.data.forEach(p => this.checkProgram(true, p));
    }
  }

  loadBudgetSelection(): Settings {
    if (!sessionStorage) {
      return;
    }
    const loadedInfo = sessionStorage.getItem(`report-budgetselections`);
    if (loadedInfo) {
      return JSON.parse(loadedInfo);
    }
    return null;
  }


  saveBudgetSelection(settings: Settings): void {
    if (!sessionStorage) {
      return;
    }
    sessionStorage.setItem(`report-budgetselections`, JSON.stringify(settings));
  }


  save() {
    this.saving = true;
    let budgets: number[] = [];
    this.dataSource.data.forEach(p => {
      p.provisionings.forEach(pr => {
        pr.budgets.forEach(b => {
          if (b.checked) {
            budgets.push(b.id);
          }
        })
      });
    });
    this.data.save({ budgets: budgets });
    let settings: Settings;
    settings = new Settings();
    settings.orgarnisationId = this.filter.organisationId;
    settings.programsProvisionings = this.dataSource.data;
    this.saveBudgetSelection(settings);
  }


}
