export enum Unit {
    EURO = 'EURO',
    ENTRY = 'ENTRY',
    POINT = 'POINT',
    MINUTE = 'MINUTE',
    QUARTER = 'QUARTER',
    HOUR = 'HOUR',
    DAY = 'DAY',
    REGISTRATION = 'REGISTRATION',
    VOUCHER = 'VOUCHER'
  }
  