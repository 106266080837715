import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';
import { IArticle, IArticleValue, IPartnerArticle  } from '../model/cityIdcore/article.model';


@Injectable({ providedIn: 'root' })
export class PartnerArticleService {
  private basePrefix = environment.SERVER_API_URL + 'api/admin/';
  private prefix = this.basePrefix + 'partner-articles'

  constructor(protected http: HttpClient) {}

  // private getPrefix(partnerId: number) {
  //   return this.prefix + "/partners/" + partnerId + "/articles";
  // }


  findByPartnerIdAndArticleId(partnerId: number, articleId: number) : Observable<IPartnerArticle> {
    return this.http.get<IPartnerArticle>(this.prefix + '/partner/' + partnerId + '/article/' + articleId);
  }

  delete(partnerId: number, articleId: number) : Observable<any> {
    return this.http.delete<any>(this.prefix + '/partner/' + partnerId + '/article/' + articleId, {});
  }

  findByPartnerId(partnerId: number) : Observable<IPartnerArticle[]> {
    return this.http.get<IPartnerArticle[]>(this.prefix + '/partner/' + partnerId);
  }


  linkArticlesToPartner(partnerId: number, articles: number[]) : Observable<IPartnerArticle> {
    return this.http.put<IPartnerArticle>(this.prefix + '/partner/' + partnerId + '/articles', articles);
  }

  linkPartnersToArticle(articleId: number, partnerIds: number[]) : Observable<IPartnerArticle> {
    return this.http.put<IPartnerArticle>(this.prefix + '/article/' + articleId + '/partners', partnerIds);
  }

  allArticles() : Observable<IArticle[]> {
    return this.http.get<IArticle[]>(this.basePrefix + 'articles'); 
  }

  findPartnersForArticleWithValues(articleId: number): Observable<HttpResponse<IPartnerArticle[]>> {
    return this.http.get<IPartnerArticle[]>(this.prefix + '/article/' + articleId + '/partners', {observe: 'response'});
  }

  saveArticleValues(partnerId: number, articleId: number, values: IArticleValue[]) : Observable<HttpResponse<void>> {
    return this.http.post<any>(this.prefix + '/partner/' + partnerId + '/article/' + articleId + '/values', values, {observe: 'response'});
  }




}
