/* tslint:disable:no-string-literal */
import {Pagination} from '../util/util/pagination-util';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs/index';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class PaginationResolve  {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Pagination> {
    const pagination = new Pagination();
    const keys = {
      page: 'page',
      size: 'size'
    };
    pagination.page = route.queryParams[keys.page];
    pagination.size = route.queryParams[keys.size];
    // pagination.sort = route.queryParams['sort'];
    return of(pagination);
  }

}
