import {ITransactionRow} from './../../../shared/model/cityIdcore/transaction-row.model';
import {TransactionRowService} from './../../../shared/service/transaction-row.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  selector: 'app-transaction-row',
  templateUrl: './transaction-row.component.html',
  styleUrls: ['./transaction-row.component.scss']
})
export class TransactionRowComponent implements OnInit {

  queryFilter = {};
  displayedColumns: string[] = ['transactionRowNumber', 'walletName', 'amount', 'unit', 'monetaryAmount'];
  dataSource;

  constructor(
    public dialogRef: MatDialogRef<TransactionRowComponent>,
    @Inject(MAT_DIALOG_DATA) public trnasactionID: string,
    private transactionRowService: TransactionRowService) { }

  ngOnInit() {
    this.queryFilter['transactionId.equals'] = this.trnasactionID;
    this.getTransactionRow();
  }
  getTransactionRow() {
    this.transactionRowService.query(this.queryFilter).subscribe(data => {
      this.dataSource = new MatTableDataSource<ITransactionRow>(data.body);
    });
  }
}
