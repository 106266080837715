import {Routes} from '@angular/router';
import {AuthGuard} from '../../auth/auth.guard';
import { ReportGeneratorComponent } from './report-generator/report-generator.component';
import { ReportEditorComponent } from './report-editor/report-editor.component';
import { TransactionReportComponent } from './transaction-report/transaction-report.component';
import { NeededPermission } from 'src/app/shared/service/permission.service';

export const reportRoute: Routes = [
  {
    path: 'generate',
    component: ReportGeneratorComponent,
    data: {
      permissions: [NeededPermission.ReportRead],
      pageTitle: 'cityIdApp.cityIdcoreSavingsTransactionRules.home.title'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'new',
    component: ReportEditorComponent,
    data: {
      permissions: [NeededPermission.ReportCreate],
      pageTitle: 'cityIdApp.cityIdcoreSavingsTransactionRules.home.title'
    },
    canActivate: [AuthGuard]
  },
  {
    path: ':id/edit',
    component: ReportEditorComponent,
    data: {
      permissions: [NeededPermission.ReportUpdate],
      pageTitle: 'cityIdApp.cityIdcoreSavingsTransactionRules.home.title'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'transaction-report',
    component: TransactionReportComponent,
    data: {
      permissions: [NeededPermission.TransactionRead],
      // pageTitle: 'cityIdApp.cityIdcoreSavingsTransactionRules.home.title'
    },
    canActivate: [AuthGuard]
  },

];
