export default interface IMemberGroup {
    id: number;
    refId: number;
    programId: number;
    name?: string;

  }
  export class MemberGroup implements IMemberGroup {
    constructor(
        public id: number,
        public refId: number,
        public programId: number,
        public name?: string
    ) {

    }
  }