import { Directive, Input} from '@angular/core';
import { BasePermissionDirective } from './base-permission-directive';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective extends BasePermissionDirective {
  @Input()
  set hasPermission(p) {
    this.checkChanged(p);
  }
}
