<mat-toolbar class="table-header">
    <h1 class="mat-headline-5 center-align" translate="cityIdApp.report.transactionReport"></h1>
  </mat-toolbar>

<div fxLayout="column" class="components-container-gt-xs" [ngClass.xs]="'components-container-xs'">  

    <div fxLayout="row" fxLayoutGap="20px" >
        <app-program-selector-button tag="transactionreport" (ids)="transferProgramIds($event)"></app-program-selector-button>
    </div>

    <div fxLayout="row" fxLayoutGap="20px" >
        <app-transaction-overview *ngIf="programIdsReport?.length > 0; else elseBlock" [programIds]="programIdsReport" [storeFilters]="false" [showOrgAndProgCols]="true"></app-transaction-overview>
        <ng-template #elseBlock>
            <h2>No program(s) selected</h2>
        </ng-template>
    </div>
</div>


