/* tslint:disable:no-string-literal */
import {HttpResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ProgramParticipantService} from '../../../shared/service/program-participant.service';
//import {map} from 'rxjs/internal/operators';
import {AccessTokenStatus} from '../../../shared/model/enumerations/access-token-status.model';
import {AccessTokenService} from '../../../shared/service/access-token.service';
import {IAccessToken} from '../../../shared/model/cityIdcore/access-token.model';
import { AlertService } from 'src/app/shared/component/alert';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-accesstokendialog',
  templateUrl: './accesstokendialog.component.html',
  styleUrls: ['./accesstokendialog.component.scss']
})
export class AccesstokendialogComponent implements OnInit {
  // accesstokens = [];
  tokenArray = [];
  filter = '';
  queryFilter = {};
  selectedTokens = [];
  participantId: number;
  // participantName: string;
  programparticipantId: number;
  programId: number;

  constructor(
    protected accessTokenService: AccessTokenService,
    private alertService: AlertService,
    private programParticipantService: ProgramParticipantService,
    public dialogRef: MatDialogRef<AccesstokendialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.participantId = data.participantId;
      // this.participantName = data.participantName;
      this.programId = data.programId;
    }

  ngOnInit() {
    this.queryFilter['programId.equals'] = this.programId;
    this.programParticipantService.findByParticipantId(this.participantId).pipe(map(res => res.body)).subscribe( res => {
        this.programparticipantId = res.id;
      }
    );
  }

  updateFilter() {
    if (this.filter === '') {
      delete this.queryFilter['token.contains'];
    } else if (this.filter.length >= 4) {
      this.queryFilter['token.contains'] = this.filter; 
      this.initializeAccessToken();
    }
  }

  initializeAccessToken() {
    this.accessTokenService.query(this.queryFilter).subscribe(
      accesstokens => {
        // this.accesstokens = accesstokens.body;
        this.tokenArray = accesstokens.body;
      });
  }

  addtoken() {
    this.selectedTokens.forEach(token => {
      token.programParticipantId = this.programparticipantId;
      // token.programParticipantName = this.participantName;
      token.status = AccessTokenStatus.IN_USE;
      this.subscribeToSaveResponse(this.accessTokenService.update(token));
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<IAccessToken>>) {
    result.subscribe(() => this.onSaveSuccess(), (err) => this.onSaveError(err));
  }

  protected onSaveSuccess() {
    this.dialogRef.close(true);
  }

  protected onSaveError(err) {
    this.alertService.error(err.error.title,{autoClose:false, keepAfterRouteChange:true})
    this.dialogRef.close(false);
  }
}
