import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageFilterStorageService {

  static readonly prefix = 'searchfilter_';

  constructor() { 
  }

  readFieldValuesFromStorageForPage(pageName: string): Map<string, string> {
    // console.log('readFieldValuesFromSessionStorage :' + pageName);

    let searchValuesForPage = sessionStorage.getItem(PageFilterStorageService.prefix + pageName);
    if (!searchValuesForPage) {
      return new Map<string, string>();
    }
    // console.log(searchValuesForPage);
    let searchValueMap: Map<string, string> = new Map(JSON.parse(searchValuesForPage));
    return searchValueMap;
  }

  writeFieldValuesToStorageForPage(pageName: string, values: Map<string, string>) {
    // console.log('writeFieldValuesToStorageForPage, values = ', values);
    if (!values || values.size == 0 ) {
      sessionStorage.removeItem(PageFilterStorageService.prefix + pageName);
    } else {
      sessionStorage.setItem(PageFilterStorageService.prefix + pageName, JSON.stringify(Array.from(values)));
    }
  }

  clearAllFilters() {
    // console.log('clearAllFilters');
    let keys: string[] = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      keys.push(sessionStorage.key(i));
    }
    let pages: string[] = keys.filter(key => key.startsWith(PageFilterStorageService.prefix));
    pages.forEach(page => sessionStorage.removeItem(page));
  }
}
