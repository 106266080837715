import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IReport } from '../model/cityIdcore/report.model';
import { LocalDate } from '@js-joda/core';

class Parameter {
  name:String;
  values: any[];
}
@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public resourceUrlReport = environment.SERVER_API_URL + 'api/admin/v1/reports';
  

  constructor(protected http: HttpClient) { }

  generateReport(id: number, budgetIds: Number[],programIds: Number[], startDate: LocalDate, endDate: LocalDate): Observable<any> {
    let params = new HttpParams();
    if (budgetIds) {
       params = params.append("budgetIds", budgetIds.toString() )
    }
    if (programIds) {
      params = params.append("programIds", programIds.toString() )
   }
    if (startDate) {
      params = params.append("startDate", startDate.toString() )
    }
    if (endDate) {
      params = params.append("endDate", endDate.toString())
    }
    return this.http.get<any[]>(`${this.resourceUrlReport}/${id}/generate`, {params: params, observe : 'response'});
  }

  
  createReport(id:number, budgetIds: Number[]): Observable<any> {
    // let params = new HttpParams();
    // if ( budgetIds ) {
    //    params = params.append("budgetIds", budgetIds.toString() )
    // }
    let params: Parameter[];
    params = [];
   if ( budgetIds ) {
       let param:Parameter = new Parameter();
       param.name = "budgetIds";
       param.values = [];
       param.values.push(budgetIds.toString());
       params.push(param);
    }
    console.log(params);


    return this.http.post<any[]>(`${this.resourceUrlReport}/${id}`, params, { observe: 'response' });
  }

  getReportById(id: number): Observable<HttpResponse<IReport>> {
    return this.http.get<IReport>(`${this.resourceUrlReport}/${id}`, {observe: 'response'});
  }

  saveNewReport(report: IReport) {
    return this.http.post<IReport>(this.resourceUrlReport, report, {observe: 'response'});
  }

  updateReport(id: number, report: IReport) {
    return this.http.put<IReport>(`${this.resourceUrlReport}/${id}`, report, {observe: 'response'});
  }

  getAllReports(): Observable<any> {
    return this.http.get<IReport[]>(`${this.resourceUrlReport}`, {observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrlReport}/${id}`, { observe: 'response' });
  }

}
