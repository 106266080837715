import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { min } from 'rxjs/operators';
import { ExportDialogComponent } from 'src/app/shared/component/export/export-dialog/export-dialog.component';
import { NumberInputDialogComponent } from 'src/app/shared/component/number-input-dialog/number-input-dialog.component';
import { TextInputDialogComponent } from 'src/app/shared/component/text-input-dialog/text-input-dialog.component';
import { IVoucher } from 'src/app/shared/model/cityIdcore/voucher.model';
import { NeededPermission } from 'src/app/shared/service/permission.service';
import { VoucherService } from 'src/app/shared/service/voucher.service';
import { environment } from 'src/environments/environment';
import { AddVoucherDialogComponent } from './add-voucher-dialog/add-voucher-dialog.component';
// import { formatDate } from 'src/app/shared/util/util/formatting-utils';

@Component({
  selector: 'app-accesstoken-vouchers',
  templateUrl: './accesstoken-vouchers.component.html',
  styleUrls: ['./accesstoken-vouchers.component.scss']
})
export class AccesstokenVouchersComponent implements OnInit {
  @Input() accessTokenId;
  @Input() accessToken;
  columns = ['code', 'description', 'startDate', 'endDate','restrictedTo', 'maximum', 'remainingUsage', 'add'];
  dataSource: MatTableDataSource<IVoucher> = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  enableAddVoucher: boolean = false;

  constructor(private voucherService: VoucherService, private dialog: MatDialog,public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.voucherService.findByToken(this.accessToken.id).subscribe(vs => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = vs;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.enableAddVoucher = true;
    });
  }


  permission() {
    return [NeededPermission.VoucherRead];
  }

  addVoucherDialog()  {
    let voucherIds =  this.dataSource.data.map(v => v.id);
    console.log(voucherIds);
    const dialogRef = this.dialog.open(AddVoucherDialogComponent, {
      data:  {
        voucherIds: voucherIds,
        programId: this.accessToken.programId
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          this.voucherService.addVouchers(this.accessToken.id,data[i].id, data[i].count).subscribe(() => {
            this.enableAddVoucher = false;
            this.reload();
          });
        }
      }
    });
  }

  openExportDialog() {
    let prefix = environment.prefix;        
        let itemsFormatted = [];
        this.dataSource.filteredData.forEach((item) => {
                 itemsFormatted.push({
                                VoucherCode : item.id,
                                description: item.description,
                                ValidFrom: this.datepipe.transform(item.startDate, 'dd-MM-yyyy'),
                                ValidTo: this.datepipe.transform(item.endDate, 'dd-MM-yyyy'),
                                TargetAudience: item.restrictedTo,
                                maxTimes: item.maximum,                               
                                RemainingUse: item.remainingUse                                
                  });
               });

         this.dialog.open(ExportDialogComponent, {
            width: '80%', //sets width of dialog
            height:'5700px%', //sets width of dialog
            maxWidth: '100vw', //overrides default width of dialog
            maxHeight: '100vh', //overrides default height of dialog
            data: {data: itemsFormatted,
                  title: 'cityIdApp.cityIdcoreVouchers.title',
                  saveTag: prefix + "voucher_export"}
        });
 
  }

  hasAddPermission() {
    return [ NeededPermission.VoucherUpdate ];
  }

  add(element: any) {
    this.openDialog(element);
  }

  
  openDialog(element: any) {
  
    const dialogRef = this.dialog.open(NumberInputDialogComponent, {
      data:  {
        title: "Enter number of vouchers to be added",
        min: 1,
        max: 100,
        step: 1        
      }
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.voucherService.addVouchers(this.accessToken.id,element.id, data).subscribe(() => {
          this.reload();
        });
      }
    });
  }

  getCommentDialog(): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: "Enter comment",
    };
    return dialogConfig;
  }

}
