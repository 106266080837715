<div >
  <mat-toolbar class="table-header" *ngIf="title">
      <h1 class="mat-headline-5 text-left">{{ title }}</h1>
  </mat-toolbar>
<!-- <mat-toolbar class="table-header mat-elevation-z4" style="margin-bottom: 20px">
  <h1 class="mat-headline center-align">{{title}}</h1>
</mat-toolbar> -->

<ng-container action-bar-items *ngIf="isLoaded">
   <button  va-mat-table-column-sorter [(columns)]="displayedColumns" [columnNames]="displayedColumns"
    [saveName]="saveName">
    <mat-icon>view_column</mat-icon>
    {{'cityIdApp.export.columns' | translate}}
  </button>

  <button *ngIf="isLoaded" mat-raised-button class="button-style" (click)="export2xls()" style="margin: 5px">{{'cityIdApp.export.excel' |
    translate}}</button>
    
  <button *ngIf="isLoaded" mat-raised-button class="button-style" style="margin: 10px"
    (click)="export2csv()"> {{'cityIdApp.export.csv' | translate}}</button>
</ng-container>
<section class="export-container">
  <mat-paginator showFirstLastButtons [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]">
  </mat-paginator>
  <table mat-table class="list-table" [dataSource]="dataSource"  matSort >

    <ng-container [matColumnDef]="col" *ngFor="let col of  displayedColumns">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{col | uppercase}}</th>
      <td mat-cell *matCellDef="let element">{{element[col]}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</section>

