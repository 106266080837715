import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InvoicingVia } from "../../model/cityIdcore/partner.model";
@Component({
  selector: 'invoicing-via',
  templateUrl: './invoicing-via.component.html',
  styleUrls: ['./invoicing-via.component.scss']
})
export class InvoicingViaComponent implements OnInit {
  @Input() value;
  @Input() showLabel: boolean;
  @Input() appearance: string = "outline";
  @Input() isDisabled: boolean;
  @Output() valueChange = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  invoicingVias(): string[] {
    return [
      InvoicingVia.CityID,
      InvoicingVia.Intersolve,
      InvoicingVia.Other
    ];
  }

  valueChanged(ev) {
    this.value = ev.value;
    this.valueChange.emit(ev);
  }

}
