<h1 mat-dialog-title translate="cityIdApp.programSelector.selectButton"></h1>
<div mat-dialog-content class="mat-typography">
    <app-program-selector [tag]="tag" (onProgramIdsSelectionChange)="updateProgramIds($event)"></app-program-selector>

</div>
<div mat-dialog-actions>
    <mat-toolbar class="bottom-bar"> 
      <span class="rightalign-spacer"></span>
      <button mat-raised-button type="button" (click)="select()"  class="button-style" color="primary"  translate="cityIdApp.general.SELECT"></button>
    </mat-toolbar>
    
  </div>
