import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IWallet} from '../model/cityIdcore/wallet.model';

import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';
import { IBatchJobInformation } from '../model/cityIdcore/batch-job-information.model';

type EntityResponseType = HttpResponse<IWallet>;
type EntityArrayResponseType = HttpResponse<IWallet[]>;

@Injectable({providedIn: 'root'})
export class WalletService {
  public baseUrl = environment.SERVER_API_URL + 'api/admin';
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/wallets';

  constructor(protected http: HttpClient) {
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IWallet>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IWallet[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  findbyaccessToken(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IWallet[]>(`${this.resourceUrl}-accesstoken/${id}`, {observe: 'response'});
  }

  upload(programId: number, wallets : IWallet[])  {
    return this.http.post<IBatchJobInformation>(`${this.baseUrl}/programs/${programId}/wallets-upload`, wallets, {observe : 'response'});
  }

  updateWallets(programId: number, wallets : IWallet[])  {
    return this.http.put<IBatchJobInformation>(`${this.baseUrl}/programs/${programId}/wallets`, wallets, {observe : 'response'});
  }
  extend(id: number, days: number):  Observable<EntityResponseType> {
    return this.http.put<any>(`${this.resourceUrl}/${id}/extend/${days}`, { observe: 'response' });
  }
  
  extendWallet(id: number, days: number):  Observable<IWallet> {
    return this.http.put<any>(`${this.resourceUrl}/${id}/extend/${days}`, { observe: 'response' });
  }

  getSaldos(ids: number[]): Observable<any[]> {
    return this.http.post<any[]>(this.resourceUrl + "/saldos", ids);
  }
}
