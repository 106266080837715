import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from './auth/auth.guard';
import {CognitoServiceProvider} from './auth/cognito-service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MaterialDesignModule} from './material-design.module';
import {ToolbarModule} from './shared/component/toolbar/toolbar.module';
import {SidebarModule} from './shared/component/sidebar/sidebar.module';
import {GlobalService} from './shared/service/global.service';
import {AppLayoutComponent} from './layout/app-layout.component';
import {LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {PageRibbonComponent} from './shared/component/profiles/page-ribbon.component';
import {SharedModule} from './shared.module';
import { AboutComponent } from './component/about/about.component';
import { ReportModule } from './component/report/report.module';
import { AutoLogoutService } from './shared/service/auto-logout-service';
import localeNl from '@angular/common/locales/nl';
import { HasPermissionDirective } from './shared/util/util/has-permission.directive';
import { NoAccessComponent } from './component/no-access/no-access.component';
registerLocaleData(localeNl);



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?nocache=10052021');
}

export function initializeApp(autoLogoutService: AutoLogoutService) {
  return (): Promise<any> => { 
    return autoLogoutService.init();
  }
}




@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    PageRibbonComponent,
    AboutComponent,
    NoAccessComponent
    
  ],
  imports: [
    MaterialDesignModule,
    SharedModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SidebarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToolbarModule,
    ReportModule
  ],
  exports: [MaterialDesignModule],
  providers: [
    Location, {provide: LocationStrategy, useClass: PathLocationStrategy},
    GlobalService,
    AuthGuard,
  
    CognitoServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // { provide: LOCALE_ID, useValue: 'nl-NL' },
    // { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    // { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },  
    // {
    //   provide: MAT_DATE_FORMATS,
    //   useValue: AppDateFormats
    // },
    AutoLogoutService,
    { 
      provide: APP_INITIALIZER,
      useFactory: initializeApp, deps: [AutoLogoutService], 
      multi: true
    }    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


//KVG experimenteel van https://gist.github.com/wottpal/4211f4c01c41b16be181110273cff5a9


