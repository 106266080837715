import {ToolbarComponent} from './toolbar.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialDesignModule} from '../../../material-design.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SortPipe } from '../../pipes/sort.pipe';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    ToolbarComponent,
    SortPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    MaterialDesignModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    ToolbarComponent
  ],
  providers: [SortPipe]
})
export class ToolbarModule {
}
