import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import {Observable, of} from 'rxjs/index';
import {filter, map} from 'rxjs/internal/operators';
import {AuthGuard} from '../../auth/auth.guard';
import {TransactionComponent} from './transaction.component';
import {TransactionUpdateComponent} from './transaction-update.component';
import {ITransaction} from '../../shared/model/cityIdcore/transaction.model';
import {TransactionService} from '../../shared/service/transaction.service';
import {PaginationResolve} from '../../shared/service/pagination-resolve.service';
import { NeededPermission } from 'src/app/shared/service/permission.service';

@Injectable({ providedIn: 'root' })
export class TransactionResolve  {
  constructor(private service: TransactionService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITransaction> {
    const id = route.params['id'];
    if (id) {
      return this.service.find(id).pipe(
        filter((response: HttpResponse<ITransaction>) => response.ok),
        map((transaction: HttpResponse<ITransaction>) => transaction.body)
      );
    }
    return of({} as ITransaction);
  }
}

export const transactionRoute: Routes = [
  {
    path: 'transactions',
    component: TransactionComponent,
    resolve: {
     pagingParams: PaginationResolve
    },
    data: {
      permissions: [NeededPermission.TransactionRead],
      defaultSort: 'id,asc',
      pageTitle: 'cityIdApp.cityIdcoreTransaction.home.title'
    },
    canActivate: [AuthGuard]
  },

  {
    path: 'transactions/:id/edit',
    component: TransactionUpdateComponent,
    resolve: {
      transaction: TransactionResolve
    },
    data: {
      permissions: [NeededPermission.TransactionRead],
      pageTitle: 'cityIdApp.cityIdcoreTransaction.home.title'
    },
    canActivate: [AuthGuard]
  }

];
