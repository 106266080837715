import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {createRequestOption} from '../util/util/request-util';
import {ITransactionRow} from '../model/cityIdcore/transaction-row.model';
import {environment} from '../../../environments/environment';

type EntityArrayResponseType = HttpResponse<ITransactionRow[]>;

@Injectable({providedIn: 'root'})
export class TransactionRowService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/transaction-rows';

  constructor(protected http: HttpClient) {
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<ITransactionRow[]>(this.resourceUrl, {params: options, observe: 'response'});
  }
}
