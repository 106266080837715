import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import * as L from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";

const iconRetinaUrl = "assets/marker-icon-2x.png";
const iconUrl = "assets/marker-icon.png";
const shadowUrl = "assets/marker-shadow.png";
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = iconDefault;
const provider = new OpenStreetMapProvider();
const defaultZoom= 18;

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {
  private map;
  private marker;
  @Input() address: string;
  @Input()
  longitude: number;
  @Output()
  longitudeChange = new EventEmitter<number>();
  @Input()
  latitude: number;
  @Output()
  latitudeChange = new EventEmitter<number>();
  @Input()
  autoSetLocation: boolean;
  @Input()
  enableSetMarker: boolean = false;

  private centerLongitude: number = 4.7641695266157535;
  private centerLatitude: number = 52.26038821779572;

  private initMap(): void {
    this.map = L.map("map", {
      center: [this.centerLatitude, this.centerLongitude],
      zoom: defaultZoom,
    });

    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);
    if (this.latitude && this.longitude) {
      this.addMarker(this.latitude, this.longitude);
    }
  }

  addMarker(latitude, longitude): void {
    if (this.marker && this.map.hasLayer(this.marker))
      this.map.removeLayer(this.marker);
    this.marker = L.marker([latitude, longitude]);
    this.marker.addTo(this.map);
  }

  private selectLocation() {
    this.map.on("click", (e) => {
      var coord = e.latlng;
      var lat = coord.lat;
      var lng = coord.lng;
      //  console.log('You clicked the map at latitude: ' + lat + ' and longitude: ' + lng);
      this.addMarker(lat, lng);
      this.latitude = lat;
      this.longitude = lng;
      this.latitudeChange.emit(this.latitude);
      this.longitudeChange.emit(this.longitude);

      // alert(mp.getLatLng());
    });
  }

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address) {
      this.address = changes.address.currentValue;
        this.addressLookUp();

    }
    if ( changes.autoSetLocation) {
      this.autoSetLocation = changes.autoSetLocation.currentValue;
      this.addressLookUp();
    }
    // todo changed update location on address
  }
  ngOnInit(): void {
    if (this.latitude && this.longitude) {
      this.centerLatitude = this.latitude;
      this.centerLongitude = this.longitude;
    }
  }

  addressLookUp() {
    if (!this.address) {
      return;
    }

    provider.search({ query: this.address }).then((result) => {
      if (result.length >= 0) {
        this.centerLatitude = result[0].y;
        this.centerLongitude = result[0].x;   
        if (this.autoSetLocation) {
          this.map.setView([this.centerLatitude, this.centerLongitude], defaultZoom);
          if (this.marker && this.map.hasLayer(this.marker))
            this.map.removeLayer(this.marker);
          this.marker = new L.Marker([
            this.centerLatitude,
            this.centerLongitude,
          ]).addTo(this.map);
          this.latitude = this.centerLatitude;
          this.longitude = this.centerLongitude;
          this.latitudeChange.emit(this.latitude);
          this.longitudeChange.emit(this.longitude);
        }
       }
    });
  }

  ngAfterViewInit(): void {
    this.initMap();
    if (this.enableSetMarker) {
      this.selectLocation();
    }
    this.addressLookUp();
    // this.addMarker();
  }
}
