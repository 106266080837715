import { Directive, ElementRef, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { NeededPermission, PermissionService } from '../../service/permission.service';
import { NgControl } from '@angular/forms';

@Directive()
export class BasePermissionDirective {
  private permissions : string[];

  constructor(@Optional() private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected elementRef: ElementRef,
    @Optional() protected control: NgControl,
    protected permissionService: PermissionService) {
  }

  /**
   * Perform a change check on the permissions for this directive as returned in its value. This method
   * must be called when the directive value changes. It executes the doSetValue method if a change was found
   * 
   * @param p The new value
   */
  protected checkChanged(p) {
    // Check if the value is really changed.
    if (!this.permissions || this.permissions.length != p.length ||
      !this.permissions.every((val, index) => val === p[index])) {
      this.calculate(p);
    }
    this.permissions = p;
  }

  private calculate(hasPermission) : void {
    var perms: NeededPermission[] = [];
    var validPermissions: boolean = true;
    if (hasPermission) {
      perms = hasPermission.map(str => {
        const permission: NeededPermission =  NeededPermission[str.toString()];
        if (permission == undefined) {
          console.log("ERROR, unknown permission: " + str);
          validPermissions = false;
        }
        return permission;
      });
    }  
    if (!validPermissions) {
      console.log("Deny unknown permissions");
      this.doSetValue(false);
    }
    else {
      this.checkPermissions(perms).then(result => this.doSetValue(result), () => this.doSetValue(false));
    }
  }

  /**
   * Method that returns in a promise the indication whether the permission conditions are met. The default
   * implementation returns true if any of the passed permissions was found.
   * 
   * @param perms The permissions to check
   * @returns A promise to the condition value of the check
   */
  protected checkPermissions(perms: NeededPermission[]) : Promise<boolean> {
    return this.permissionService.hasSomePermissions(perms);
  }

  /**
   * Perform the actions needed for the directive to execute its work.
   * 
   * @param result The result of the condition check: true means that the permissions are present, false if not
   */
  protected doSetValue(result: boolean) : void {
    this.viewContainer.clear();
    if (result) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}