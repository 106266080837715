import { Injectable } from "@angular/core";
import { LoginService } from "./login.service";
import {environment} from '../../../environments/environment';
import { CognitoServiceProvider } from "src/app/auth/cognito-service";
import { CognitoUserSession } from "amazon-cognito-identity-js";
const MINUTES_UNTIL_AUTO_LOGOUT = environment.MINUTES_UNTIL_AUTO_LOGOUT; // in mins
const CHECK_INTERVAL = 1000*5; // in ms
const STORE_KEY =  'lastActionTimestamp';

@Injectable({providedIn: 'root'})
export class AutoLogoutService {

  private intervalId: any;
  private lastAction: number;
  private isEnabled: boolean=true;


  constructor(private loginService: LoginService, private cognitoService: CognitoServiceProvider) {
  }

  init() {
    return new Promise<void>((resolve, reject) => {
       console.log("AutoLogoutService.init() called");
      //first verify whether the timeout period is passed since the last application activity, if so then immediately logout
      this.checkTimeoutReached();
      this.initListeners();
      this.initInterval();
      localStorage.setItem(STORE_KEY, Date.now().toString());
      resolve();
    });
  }

  stop() {
    clearInterval(this.intervalId);
  }

  private initListeners() {
    // console.log("initListeners()");    
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
//    window.addEventListener("storage",() => this.storageEvt()); //KVG: wat doet deze eigenlijk?
  }

  // destroyListeners() {
  //   document.body.removeAllListeners();
  // }

  private getLastAction() {
    return !this.lastAction?parseInt(localStorage.getItem(STORE_KEY)):this.lastAction;
  }

  private setLastAction(lastAction: number) {
    this.lastAction = lastAction;
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  private reset() {
    this.setLastAction(Date.now());
    // console.log('store key', localStorage.getItem(STORE_KEY));
  }

  private initInterval() {
    if ( this.initInterval) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      this.checkTimeoutReached();
    }, CHECK_INTERVAL);
  }

  private checkTimeoutReached() {
    //console.log('checkTimeoutReached()');
    const logoutTimeMillis = this.getLastAction() + MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;

    const timeLeftMillis = logoutTimeMillis - Date.now();
    const isTimeout = timeLeftMillis <= 0;

    if (isTimeout && this.isEnabled)  {
    //  console.log('Timeout bereikt!');
      this.signOut();
      
    }
  }


  signOut() {
    // clearInterval(this.intervalId); //we cannot do that because the init function is called only once
    console.log("autolog out");
    this.cognitoService.getCurrentUser()
      .then(user => {
        if (user != null) {
          user.getSession((err: Error, session: CognitoUserSession) => {
            if (session != null) {
              this.loginService.logout();
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
        // do nothing is already signed out
        //console.log('No current user');        
      });

  }


  disable() {
    this.isEnabled = false;
  }

  enable() {
   this.isEnabled = true;
  }

}

