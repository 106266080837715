<h1 mat-dialog-title>{{ 'cityIdApp.pickPartner.title' | translate }}</h1>
<mat-dialog-content class="dialog-size">
  <div>
    <app-simple-program-selector [programId]="selectedProgramId" [readOnly]="selectedProgramId !== undefined && selectedProgramId !== null"  (onChange)="onProgramChange($event)"></app-simple-program-selector>
  </div>
  <div>
    <mat-form-field *ngIf="selectedProgramId">
      <mat-label>{{ 'cityIdApp.pickPartner.title' | translate }}</mat-label>

      <mat-select [formControl]="partnerMultiCtrl" placeholder="Partners" [multiple]="true" #multiSelect>
          <mat-option>
            <ngx-mat-select-search 
              [placeholderLabel]=" 'cityIdApp.searchSelect.search' | translate " 
              [noEntriesFoundLabel]=" 'cityIdApp.searchSelect.noPartnersFound' | translate "  
              [formControl]="partnerMultiFilterCtrl"
              [showToggleAllCheckbox]="true" 
              (toggleAll)="toggleSelectAll($event)"             
              [toggleAllCheckboxTooltipMessage]=" 'cityIdApp.searchSelect.selectAll' | translate "  
              [toggleAllCheckboxTooltipPosition]="'above'"  >
            </ngx-mat-select-search>
          </mat-option>      
  
        <mat-option *ngFor="let p of filteredPartnersMulti | async" [value]="p">{{ p.name }}</mat-option>
      </mat-select>


    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar"> 
    <span class="rightalign-spacer"></span>
    <button mat-raised-button type="button" (click)="close()" class="button-style" color="primary">OK</button>
  </mat-toolbar>
</mat-dialog-actions>
