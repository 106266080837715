import { Component, Input, SimpleChanges } from '@angular/core';
import { AccessTokenService } from 'src/app/shared/service/access-token.service';
import { DATE_TIME_FORMAT } from 'src/app/shared/util/constants/input.constants';

@Component({
  selector: 'app-operation-log',
  templateUrl: './operation-log.component.html',
  styleUrls: ['./operation-log.component.scss']
})
export class OperationLogComponent {

  @Input() accessTokenId;
  @Input() refresh: boolean = false;

  logs: OperationLog[] = [];
  columnsToDisplay = ['id', 'userName', 'action', 'comment', 'creationDate'];
  readonly dateFormat = DATE_TIME_FORMAT;

  constructor(private accessTokenService: AccessTokenService) { }

  ngOnInit(): void {
    this.fetchLogs();
  }

  private fetchLogs() {
    this.accessTokenService.getOperationLogs(this.accessTokenId).subscribe(res => {
      this.logs = res.body;
      // console.log(this.logs);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fetchLogs();
    this.refresh = false;
  }

}

export interface OperationLog {
}