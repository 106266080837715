import { Directive,Input,HostListener } from '@angular/core';

@Directive({
  selector: '[scrollTo]'
})
export class ScrollToDirective {

  @Input('scrollTo') elm:HTMLElement;

  @HostListener('click') onClick(){
    if(this.elm){
      this.elm.scrollIntoView({behavior:'smooth'});
    }
  }
}