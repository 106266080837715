import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IProvisioning} from '../model/cityIdcore/provisioning.model';

import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';

type EntityResponseType = HttpResponse<IProvisioning>;
type EntityArrayResponseType = HttpResponse<IProvisioning[]>;

@Injectable({providedIn: 'root'})
export class ProvisioningService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/provisionings';

  constructor(protected http: HttpClient) {
  }

  create(provisioning: IProvisioning): Observable<EntityResponseType> {
    return this.http.post<IProvisioning>(this.resourceUrl, provisioning, {observe: 'response'});
  }

  update(provisioning: IProvisioning): Observable<EntityResponseType> {
    return this.http.put<IProvisioning>(this.resourceUrl, provisioning, {observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IProvisioning>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  findByProgramId(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IProvisioning[]>(`${this.resourceUrl}byProgramId/${id}`, {observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }
}
