<mat-toolbar color="primary" class="mat-elevation-z4 mat-toolbar ng-tns-c1-0 mat-primary mat-toolbar-single-row">
  <img src="assets/img/logo.png" id="logo">
</mat-toolbar>

<div fxLayout="column">
  <mat-nav-list>
    <sidenav-menuitem *ngFor="let menuItem of menus" [menuItem]="menuItem" [style.maxHeight]="(menuItem.open)?'1200px':'56px'"></sidenav-menuitem>
  </mat-nav-list>
</div>

