export class IBaseRelation {
  name: string;
  proformaInvoiced: boolean;
  synchronizationTime?: string;
  inactive?: boolean;

}
export class IRelation extends IBaseRelation {
  id: number;
}

export enum InvoicingVia {
  CityID = "CityID",
  Intersolve = "Intersolve",
  Other = "Other"
}
export class IPartner extends IRelation {
  relationId: string;
  invoicingVia: InvoicingVia;
  eanCheckDisabled: boolean;
  legalNumber?: string;
  street: string;
  houseNumber: string;
  houseNumberSuffix: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
  billing: Billing;
}

export class Billing {
  id: number;
  name: string;
  email: string;
  ccMail: string;
  attentionTo: string;
  iban: string;
  vatId?: string;
  street: string;
  houseNumber: string;
  houseNumberSuffix: string;
  postalCode: string;
  city: string;
}
