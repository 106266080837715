import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IAccessToken } from 'src/app/shared/model/cityIdcore/access-token.model';
import { MatDialog } from '@angular/material/dialog';
import { AccessTokenGroupService } from 'src/app/shared/service/access-token-group.service';
import { Member } from 'src/app/shared/model/cityIdcore/member.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmdialogComponent } from 'src/app/shared/component/confirmdialog/confirmdialog.component';
import { AccessTokenService } from 'src/app/shared/service/access-token.service';
import { AlertService } from 'src/app/shared/component/alert';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MemberGroupDialogComponent } from './member-group-dialog/member-group-dialog.component';
import { NeededPermission } from '../../service/permission.service';

@Component({
  selector: 'app-member-group',
  templateUrl: './member-group.component.html',
  styleUrls: ['./member-group.component.scss']
})
export class MemberGroupComponent implements OnInit {
  @Input() accessToken: IAccessToken;
  members: Member[] = [];
  memberOnEdit: Member = new Member();
  membersDataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumnsMembers = ['token', 'name', 'manage', 'delete'];


  constructor(private dialog: MatDialog,
    private groupService: AccessTokenGroupService,
    private accessTokenService: AccessTokenService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.reload();
  }

  public reload() {

    if (this.accessToken && this.accessToken.memberGroup) {
      this.groupService.findAllByGroupId(this.accessToken.memberGroup.id).subscribe(res => {
        this.members = res.body;
        this.initDataSource();
      })
    }
    else {
      this.members = [];
      this.initDataSource();
    }
  }

  readPermission() {
    return [NeededPermission.AccesstokenRead];
  }

  editPermission() {
    return [NeededPermission.AccesstokenUpdate];
  }

  initDataSource() {
    this.membersDataSource = new MatTableDataSource<any>(this.members);
    this.membersDataSource.sort = this.sort;
    this.membersDataSource.paginator = this.paginator;
  }

  getGroupName(): string {
    if (this.accessToken && this.accessToken.memberGroup) {
      return this.accessToken.memberGroup.name;
    }
    return "<None>"
  }

  addOrCreateGroup() {
    const dialogRef = this.dialog.open(MemberGroupDialogComponent, {
      width: '1000px',
      data: {
        accessToken: this.accessToken
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.reload();
    });
  }

  removeFromGroup(accessTokenId) {
    this.accessTokenService.find(accessTokenId).subscribe(res => {
      let at = res.body;
      const dialogRef = this.dialog.open(ConfirmdialogComponent, {
        maxWidth: "400px",
        data: {
          title: "Are you sure?",
          message: "You are about to remove this access token " + at.token + " from the member group. "
        }
      });

      // listen to response
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult) {
          at.memberGroup = null;
          at.canManageGroup = false;
          this.accessTokenService.update(at).subscribe(res => {
            if (accessTokenId === this.accessToken.id) {
              // make sure the the calling accesstoken is inline
              this.accessToken.canManageGroup = false;
              this.accessToken.memberGroup = null;
            }
            this.alertService.success("Access Token removed succesfully from group", { autoClose: true, keepAfterRouteChange: false });
            this.reload();
          }, (err) => {
            this.alertService.error("Access Token could not be removed from group.", { autoClose: true, keepAfterRouteChange: false });
          });
        }
      });
    })

  }

  editMember(m: Member) {
    this.memberOnEdit = m;
  }

  onChangeCanManage(canManageGroup: boolean, accessTokenId: number) {
    
    this.accessTokenService.find(accessTokenId).subscribe(res => {
      let at = res.body;
      at.canManageGroup = canManageGroup;
      this.accessTokenService.update(at).subscribe(res => {
        if (accessTokenId === this.accessToken.id) {
          // make sure the the calling accesstoken is inline
          this.accessToken.canManageGroup = at.canManageGroup;
        }
        this.alertService.success("Access Token successfully updated", { autoClose: true, keepAfterRouteChange: false });
        this.reload();
      }, (err) => {
        this.alertService.error("Access Token failed to update.", { autoClose: true, keepAfterRouteChange: false });
      });
    });
  }

  isInGroup(): boolean {
    return !(this.accessToken.memberGroup === null);
  }

  saveMember(): void {
    this.onChangeCanManage(this.memberOnEdit.canManageGroup, this.memberOnEdit.tokenId);
    this.undoMember();

  }

  undoMember() {
    this.memberOnEdit = new Member();
    this.reload();
  }

}
