import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportGeneratorComponent } from './report-generator/report-generator.component';
import { reportRoute } from './report.route';
import { SharedModule } from 'src/app/shared.module';
import { RouterModule } from '@angular/router';
import { BudgetSelectorComponent } from './budget-selector/budget-selector.component';
import { BudgetButtonComponent } from './budget-selector/budget-button/budget-button.component';
import { ReportEditorComponent } from './report-editor/report-editor.component';
import { TransactionReportComponent } from './transaction-report/transaction-report.component';
import { CityIdcoreTransactionModule } from '../transaction/transaction.module';
import { PickListModule } from 'primeng/picklist';

const ENTITY_STATES = [...reportRoute];
@NgModule({
    imports: [CityIdcoreTransactionModule, SharedModule, PickListModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [ReportGeneratorComponent, BudgetSelectorComponent, BudgetButtonComponent, ReportEditorComponent, TransactionReportComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReportModule {}


