/* tslint:disable */
import {EventEmitter, Injectable, Output} from '@angular/core';
import {environment} from '../../environments/environment';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth'
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoServiceProvider {

  @Output() onMFASecretChange: EventEmitter<string> = new EventEmitter();
  @Output() onVerificationStatusChange: EventEmitter<string> = new EventEmitter();

  constructor() {
    Amplify.configure({
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',
    
        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',
    
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: environment.UserPoolId,
    
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: environment.ClientId,
    
    
      },
    });
   
    const currentConfig = Auth.configure();

  }

  getCurrentUser(): Promise<CognitoUser>  {
    // return this.getUserPool().getCurrentUser();
  // console.log('CognitoServiceProvider.getCurrentUser()');
    const user = Auth.currentAuthenticatedUser({
      bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
    
    .catch(e=>{ 
      console.log(e);
      return Promise.reject("No user");});
    return user;
  }


  signUp(username, password) {


    return  Auth.signUp({
          username,
          password,
          autoSignIn: { // optional - enables auto sign in after user is confirmed
            enabled: false,
          }
     });
  }

  confirmSignUp(userName, verificationCode) {
    return Auth.confirmSignUp(userName, verificationCode);
  }


  

  public signOut() {
    console.log("sign out");
  return this.getCurrentUser().then(user => {
      if (user) {
        return Auth.signOut();
      } else {
        return Promise.reject("User is already signed out");
      }
    })
    .catch( e => {
      return Promise.reject("Error in signing out:" + e);
      });
  }

  async delay(ms: number) {
     await timer(ms).pipe(take(1)).toPromise();
}

async refreshTokenAsynbc() {
  await this.refreshToken();
}

  refreshToken(): Promise<any> {
    this.delay(3000);
    return this.getCurrentUser()
      .then(user => {
        user.getSession((err: Error, session: CognitoUserSession) => {
          const refreshToken = session.getRefreshToken();
          user.refreshSession(refreshToken, (refError, refSession) => {
            if (refError) {
              return Promise.reject("Failed to get refreshtoken");
              // throw refError;
            } else {
              localStorage.setItem('accessToken', refSession.accessToken.jwtToken);
              localStorage.setItem('refreshToken', refSession.refreshToken.token);
              return Promise.resolve();
            }
          });
        });
      })
      .catch(error => {
        console.log("Error in refreshing token:" + error);
        return Promise.reject("Failed to get refreshtoken");
      });
  }

    authenticate(email: string, password: string): Promise<CognitoUser> {
       return Auth.signIn(email, password);//.catch(e => { console.log("hallo")})

    }    
  

  forgotPassword(username: string) {
    return Auth.forgotPassword(username);
  }

  confirmPassword(username: string, verificationCode: string, newPassword: string) {

    return Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
  }
  sendMFACode(challengeAnswer: string) {

    this.getCurrentUser()
      .then(user => {
        console.log(" sedn MFA" +user);;
        user.sendMFACode(challengeAnswer,
          { 
            onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
              console.log(session);

            },
            
            onFailure: (err: any) => {
              console.log(err);

            } 
          }, 
          'SOFTWARE_TOKEN_MFA')
      });
    }

  verifySoftwareToken(code: string) {
    this.getCurrentUser()
      .then(user => {
        user.verifySoftwareToken(code, "edge",
          {
            onSuccess: (session: CognitoUserSession) => {
              const smsPrefs = {
                'Enabled': false,
                'PreferredMfa': false
              }
              const tokenPrefs = {
                'Enabled': true,
                'PreferredMfa': true
              }

              user.setUserMfaPreference(smsPrefs, tokenPrefs, (
                e, a
              ) => {
                console.log(e);
                console.log(a);
                this.onVerificationStatusChange.emit(a);
              });
              console.log("sucess")
            },
            onFailure: (err: Error) => {
              console.log(err);
              this.onVerificationStatusChange.emit(err.message);
            }
          });
      });

  }

  getSecretForMFA() {
 
    this.getCurrentUser()
      .then(user => {
        console.log(user);
        user.associateSoftwareToken({
          associateSecretCode: (secretCode: string) => {
            console.log(secretCode);
            this.onMFASecretChange.emit(`otpauth://totp/${user.getUsername()}?secret=${secretCode}&issuer=CityId-` + environment.mode);
          },
          onFailure: (err) => {
            console.error(err);
          },
        });
      });

  }

}
