<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
  <p-inputNumber 
    [(ngModel)]="value" 
    [showButtons]="true" 
    inputId="minmax" 
    class="ng-invalid ng-dirty" 
    mode="decimal" 
    decrementButtonClass="button-style"
    incrementButtonClass="button-style" 
    [min]="min" 
    [max]="max" /> 
</mat-dialog-content>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar"> 
    <span class="rightalign-spacer"></span>
    <button [disabled]='value<min || value>max' mat-raised-button color="primary" (click)="save()" translate="cityIdApp.general.save"></button>
    <button mat-raised-button color="warn" (click)="cancel()" translate="cityIdApp.general.cancel"></button>
  </mat-toolbar>
</mat-dialog-actions>

