<mat-form-field appearance="outline" >
    <mat-label translate="cityIdApp.cityIdcoreVouchers.programSelect"></mat-label>
    <mat-select [formControl]="programCtrl" placeholder="Program" #singleSelect>

      <mat-option>
        <ngx-mat-select-search 
          [placeholderLabel]=" 'cityIdApp.searchSelect.search' | translate " 
          [noEntriesFoundLabel]=" 'cityIdApp.searchSelect.noProgramsFound' | translate "  
          [formControl]="programFilterCtrl"></ngx-mat-select-search>
      </mat-option>      
      <mat-option>{{ 'cityIdApp.global.all' | translate }}</mat-option>
      <mat-option *ngFor="let p of filteredPrograms | async" [value]="p">{{ p.organisationName }} - {{ p.name }}</mat-option>
    </mat-select>
</mat-form-field>
