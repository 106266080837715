import { Component, OnInit } from '@angular/core';
import { IProgram } from 'src/app/shared/model/cityIdcore/program.model';
import { ProgramService } from 'src/app/shared/service/program.service';
import { ToolbarService } from 'src/app/shared/service/toolbar.service';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent implements OnInit {

  programIdsReport: number[] = [];

  programs: IProgram[];
  filter: {}

  constructor(private programService: ProgramService, private tbs: ToolbarService) { }

  ngOnInit(): void {
    this.tbs.dontShowPickLists();
    this.programService.query(this.filter).subscribe(res => {
      this.programs = res.body;
    });
  }

  transferProgramIds($event) {
    console.log('Program selection changed, programIds = ', $event);
    this.programIdsReport = $event.slice();
    // this.buttonDisabled = $event?.length == 0;
  }

  ngOnDestroy(): void {
    this.tbs.reset();
  }

  // refresh() {
  //   this.programIdsReport = this.programIds.slice();
  //   this.buttonDisabled = true;    
  // }
}
