import {HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ITEMS_PER_PAGE} from '../constants/pagination.constants';
import { PageEvent } from '@angular/material/paginator';

/**
 * 
 */
export abstract class BackendPaginationBaseComponent<DataType> {

  items: DataType[];
  totalItems: number;
  itemsPerPage = ITEMS_PER_PAGE;
  page: number = 1;
  predicate: any;
  previousPage: number;


  next(event: PageEvent) {
console.log('PAginationComponent.next');    
    this.itemsPerPage = event.pageSize;
    if ( event.pageIndex !== this.previousPage) {
      this.previousPage = event.pageIndex;
      this.page = event.pageIndex + 1;
      //this.predicate = data.pagingParams.sort; hebben we dit nodig?

    }
    this.loadAll();
  }

  protected paginate(data: DataType[], headers: HttpHeaders) {
    this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
    //console.log(this.totalItems);
    this.items = data;
  }

  public reload() {
    this.page = 1;
    this.previousPage = 0;
    this.loadAll();
  }

  abstract loadAll(): void;
}
