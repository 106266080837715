<mat-form-field appearance="outline" >
    <mat-label>{{ labelKey | translate }}</mat-label>
    <input (dateChange)="dateChanged($event)" matInput [matDatepicker]="picker" 
           [(value)]="localDate" [readonly]="readOnly" 
           placeholder="{{ placeholder }}"  [required]="required"
          >
           <mat-datepicker-toggle [disabled]="readOnly" matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="year"></mat-datepicker>
</mat-form-field>

