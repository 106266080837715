import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from "xlsx";
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnChanges {
  @Input() title: string;
  @Input() saveName: string;
  @Input() data: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort : MatSort;
  dataSource: MatTableDataSource<any>;
  // displayedColumnsNames: string[];
  displayedColumns: string[];
  isLoaded: boolean = false; 

  constructor(private datePipe: DatePipe) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let change = changes["data"];
    if (change && this.data && this.data.length > 0) {
      this.updateData();
    }
  }

  updateData() {
    this.dataSource = new MatTableDataSource<any>([]);
    Promise.resolve(null).then(() => { 
      this.dataSource.data = this.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;  
      this.displayedColumns = Object.keys(this.data[0]);
      // this.displayedColumnsNames = Object.keys(this.data[0])
      this.isLoaded = true;
    });
  }

  getFileName(name: string) {
    let sheetName = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    let fileName = name + '-' + sheetName;
    return {
      sheetName,
      fileName
    };
  };

  getDataSet() {
    console.log('ExportComponent.getDataSet(), dataSource = ', this.dataSource);
    let out = [];
    let contents = this.dataSource.sortData(this.dataSource.filteredData, this.sort);
    out.push(this.displayedColumns);
    contents.forEach(e => {
      let obj = [];
      this.displayedColumns.forEach(c => {
        obj.push(e[c]);
      });
      out.push(obj);
    });
    return out;
  }

  export2(type : string) {
    let { sheetName, fileName } = this.getFileName(this.saveName);
    let dataSet = this.getDataSet();
    let sheet = XLSX.utils.aoa_to_sheet(dataSet);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, sheetName);
    XLSX.writeFile(wb, `${fileName}.${type}`);
  }

  export2xls() {
    this.export2("xlsx");
  }

  export2csv() {
    this.export2("csv");
  }

}
