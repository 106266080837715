import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {IProvider} from '../model/cityIdcore/provider.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/index';
import {environment} from '../../../environments/environment';
import { IPartner } from '../model/cityIdcore/partner.model';

type EntityResponseType = HttpResponse<IProvider>;
type EntityArrayResponseType = HttpResponse<IProvider[]>;

@Injectable({providedIn: 'root'})
export class ProviderService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/providers';
  public ssp_url = 'api/service/v2/partners';

  constructor(protected http: HttpClient) {
  }

  create(provider: IProvider): Observable<EntityResponseType> {
    return this.http.post<IProvider>(this.resourceUrl, provider, {observe: 'response'});
  }

  update(provider: IProvider): Observable<EntityResponseType> {
    return this.http.put<IProvider>(this.resourceUrl, provider, {observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IProvider>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  findByProgramId(programId?: number, req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<IProvider[]>(`${this.resourceUrl}-byprogramid/${programId}`, {observe: 'response'});
  }

  findByProgramIds(programIds: number[], req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<IProvider[]>(`${this.resourceUrl}-byprogramids/${programIds}`, {observe: 'response'});
  }

  findByOrganisationId(organisationId?: number): Observable<EntityArrayResponseType> {
    return this.http.get<IProvider[]>(`${this.resourceUrl}-byorganisationid/${organisationId}`, {observe: 'response'});
  }

  findByPartnerId(partnerId?: number): Observable<EntityArrayResponseType> {
    return this.http.get<IProvider[]>(`${this.resourceUrl}-bypartnerid/${partnerId}`, {observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  deleteByIds(ids: number[]): Observable<HttpResponse<any>> {
    return this.http.put(`${this.resourceUrl}/delete`, ids,{observe: 'response'});
  }

  getBillingByPartnerId(id?: number): Observable<any> {
    let url = environment.SERVER_API_URL + this.ssp_url + `/${id}`
    return this.http.get<any>(url, { observe: 'response' });
  }

  saveBilling(partner: IPartner): Observable<any> {
    let url = environment.SERVER_API_URL + this.ssp_url
    if (partner.legalNumber != null && partner.legalNumber.length === 0) {
      partner.legalNumber = null;
    }
    if (partner.id) {
      return this.http.put<IPartner>(url + `/${partner.id}`, partner, { observe: 'response' });
    } else {
      return this.http.post<IPartner>(url, partner, { observe: 'response' });
    }
  }

  importPartners(partners: IPartner[]): Observable<any> {
    let url = environment.SERVER_API_URL + this.ssp_url + '/import';
    return this.http.post<IPartner>(url, partners, { observe: 'response' });
  }

  query(): Observable<any> {
    let url = environment.SERVER_API_URL + this.ssp_url;
    return this.http.get<IPartner>(url, { observe: 'response' });
  }

}
