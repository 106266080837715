import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import { Build } from '../model/cityIdcore/build.model';

type EntityResponseType = HttpResponse<Build>;


@Injectable({providedIn: 'root'})
export class CoreStatusService {
  statusChange: EventEmitter<boolean> = new EventEmitter();
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/build-info';
  public isUp: boolean = false;
  public coreVersion: string = "-----";
  public coreBuildTime: string = "";
  public showVersion: boolean;
  public isStopped: boolean= true;

  interval;

  constructor(protected http: HttpClient) {
  }

  ngOnInit() {
    this.start();
  }

  start() {
    this.checkStatus();
    if ( !this.interval) {
       this.startTimer(30000);
       this.isStopped = false;

    }
  }

  stop() {
    this.isStopped = true;
    if ( this.interval) {

       clearInterval(this.interval);
       this.interval = undefined;
    }
  }

  startTimer(timeout:number) {
    this.interval = setInterval(() => {
      this.checkStatus();
    },timeout)
  }

  getStatus() {
    return this.isUp;
  }


  checkStatus() {
    if ( this.isStopped) return; // do nothing, an hack to stop the request
    this.get()
      .subscribe((res) => 
      {
        // console.log(res.body);
        this.isUp = true;
        this.coreBuildTime = res.body.time;
        this.coreVersion = res.body.version;
        
      
        this.statusChange.emit(true);
      },
       (res: HttpErrorResponse) => {
        // if ( this.isUp) {
        //   clearInterval(this.interval);
        //   this.startTimer(5000);
        // }
        this.isUp = false;
        this.statusChange.emit(true);
       });
  

  }

get(): Observable<EntityResponseType> {
  return this.http.get<Build>(`${this.resourceUrl}`, {observe: 'response'});
}

}