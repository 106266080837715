import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-import-feedback',
  templateUrl: './import-feedback.component.html',
  styleUrls: ['./import-feedback.component.scss']
})
export class ImportFeedbackComponent {
  @Input() successCount: number;
  @Input() errorCount: number;
  @Input() progress: number;  
}
