<!-- <stbui-loading *ngIf="showLoading"></stbui-loading> -->
<div id="div_1" class="eaton mat-typography" style="overflow-x:hidden;" fxFlexFill cdkScrollable></div>
<mat-toolbar class=" mat-elevation-z4" [ngStyle]="{'background-color': (mode === 'Production') ? 'whitesmoke' : 'green'}">

  <div id="panel_main" class="" fxLayout="column">
  <div id="search_panel_1" class="">

    <div class="">
    <mat-form-field class="" appearance="fill" *ngIf="toolbarService.isShowOrganisations()">
        <mat-label>Organisation</mat-label>
        <input #inputOrganisation
               type="text"
               placeholder="Select an organisation"
               matInput
               [formControl]="organisationControl"
               [matAutocomplete]="auto1"
               (input)="filterOrg()" >
        <!-- <mat-icon matSuffix class="dropdown-icon">keyboard_arrow_down</mat-icon>                -->
        <mat-autocomplete requireSelection #auto1="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onOrgChange($event.option.value)" >
            <mat-option *ngFor="let option of filteredOrganisationOptions | sort: 'name'" [value]="option">{{ option.name }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="">

      <app-simple-program-selector  [readOnly]="disableProgram" [appearance]="appearance" [items]="programs" [programId] ="program?.id" [enableNone]="false" appearance="fill" *ngIf="toolbarService.isShowPrograms()" (onChangeProgram)="onProgChange($event)" ></app-simple-program-selector>

    </div>

    <div class="align_right">
      <mat-form-field class="token_search_field" appearance="fill" hintLabel="Min 6 characters">
        <mat-label translate="cityIdApp.accessTokenSearch.searchLabel"></mat-label>
        <input matInput #search type="search" maxlength="26" [(ngModel)]="tokenSearch" (keydown.enter)="searchAccessTokens()" />
        <button mat-icon-button matSuffix (click)="searchAccessTokens()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div id="optional_panel_toggle" class="mar-r-xs">
      <mat-checkbox color="primary" (click)="toggleOptionalSearchPanel()" [(ngModel)]="optionalPanelChecked" class="">
        <span class="">{{'cityIdApp.accessTokenSearch.enableAdvancedSearch' | translate}}</span>
      </mat-checkbox>
    </div>

  <div class="toolbar-user-container">
    <button mat-button (click)="changeStatus()" class="toolbar-user-btn" [class.open]="isOpen">
                  <span fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="profile_pic">account_circle</mat-icon>
                    <span class="name" >{{ currentUserName }}</span>
                    <mat-icon class="icon arrow-user" fxHide fxShow.gt-xs>keyboard_arrow_down</mat-icon>
                  </span>
    </button>
    <div class="dropdown mat-elevation-z1" [class.open]="isOpen">
      <div class="content">
        <mat-nav-list  >
          <mat-list-item (click)="myProfile()">
            <a matLine>My Profile</a>
            <button mat-icon-button>
              <mat-icon>account_circle</mat-icon>
            </button>
          </mat-list-item>

          <mat-list-item (click)="signOut()">
            <a matLine>Logout</a>
            <button mat-icon-button>
              <mat-icon>exit_to_app</mat-icon>
            </button>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
  </div>

</div>

<div [hidden]="!showOptionalSearchPanel" class="">
  <div id="search_panel_2" class="mar-r-xs">
    <mat-form-field class="search-input" appearance="fill" hintLabel="Min 3 characters">
      <mat-label translate="cityIdApp.accessTokenSearch.searchLabelLastName"></mat-label>
      <input matInput #lastNameSearchId type="search" [(ngModel)]="lastNameSearch" />
    </mat-form-field>
    <mat-form-field class="search-input" appearance="fill" hintLabel="Min 4 characters">
      <mat-label translate="cityIdApp.accessTokenSearch.searchLabelPostCode"></mat-label>
      <input matInput #postalCodeSearchId type="search" [(ngModel)]="postalCodeSearch" />
    </mat-form-field>
    <mat-form-field class="search-input" appearance="fill" hintLabel="Min 1 characters">
      <mat-label translate="cityIdApp.accessTokenSearch.searchLabelHouseNumber"></mat-label>
      <input matInput #houseNumberSearchId aria-label="Clear" type="search" [(ngModel)]="houseNumberSearch" (keydown.enter)="searchAccessTokens()" />
    </mat-form-field>
  </div>
</div>

</div>

</mat-toolbar>
