import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/component/alert';
import { IAccessToken } from 'src/app/shared/model/cityIdcore/access-token.model';
import { AccessTokenGroupService } from 'src/app/shared/service/access-token-group.service';
import { AccessTokenService } from 'src/app/shared/service/access-token.service';
import IMemberGroup, { MemberGroup } from 'src/app/shared/model/cityIdcore/member-group.model';

@Component({
  selector: 'app-member-group-dialog',
  templateUrl: './member-group-dialog.component.html',
  styleUrls: ['./member-group-dialog.component.scss']
})
export class MemberGroupDialogComponent implements OnInit {
  selectedGroup : IMemberGroup;
  groups : IMemberGroup[] =  [];
  queryFilter: any = {};
  accessToken: IAccessToken
  refId: number;
  name: string;
  canManage: boolean = false;

  constructor( public dialogRef: MatDialogRef<MemberGroupDialogComponent>, 
    private groupService: AccessTokenGroupService,
    private accessTokenService: AccessTokenService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.accessToken = data.accessToken;
     }

  ngOnInit(): void {
    let newGroup: IMemberGroup = new MemberGroup(null,-1,this.accessToken.programId,"<new>");
    this.selectedGroup = newGroup;
    this.groups.push(newGroup); 
    console.log(this.groups);
    this.getAllGroups();
  }

  onGroupChange() {
    console.log(this.selectedGroup);
    
  }

  onChangeCanManage(event) {
    this.canManage = event.checked;
  }

  addToGroup() {
    if ( this.selectedGroup.id === null) {
      this.selectedGroup.name = this.name;
      this.selectedGroup.refId = this.refId;
      this.groupService.create(this.selectedGroup).subscribe( res => {
        this.accessToken.memberGroup = res.body;
        this.accessToken.canManageGroup = this.canManage;
        this.selectedGroup = res.body;
        this.getAllGroups();
        this.updateAccessToken(this.accessToken);
      })
    }
    else {
      this.accessToken.memberGroup = this.selectedGroup;
      this.updateAccessToken(this.accessToken);
    }

  }

  updateAccessToken(accessToken: IAccessToken) {
    this.accessTokenService.update(accessToken).subscribe( (res) => this.onSaveSuccess(res), (err) => this.onSaveError(err));

  }

  onSaveSuccess(res) {  
    this.alertService.success("Save successfull",{autoClose:true, keepAfterRouteChange:true});
    this.closeDialog();
  }

  onSaveError(err :any) {
    this.alertService.error(err.error.title,{autoClose:true, keepAfterRouteChange:true})
    this.closeDialog();
  }

  createNew(): boolean {
    return this.selectedGroup === undefined || this.selectedGroup.id === null ;
  }

  getAllGroups() {
    this.queryFilter['programId'] = this.accessToken.programId;
    this.groupService.findByProgramId(this.queryFilter).subscribe( res => {
    
      this.groups =this.groups.concat(res.body);
      this.selectedGroup = this.groups.find( grp => grp.id===this.accessToken.memberGroup.id);

    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }


}
