import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IWallet} from '../model/cityIdcore/wallet.model';

import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';

type EntityResponseType = HttpResponse<IWallet>;
type EntityArrayResponseType = HttpResponse<IWallet[]>;

@Injectable({providedIn: 'root'})
export class WalletService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/wallets';

  constructor(protected http: HttpClient) {
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IWallet>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IWallet[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  findbyaccessToken(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IWallet[]>(`${this.resourceUrl}-accesstoken/${id}`, {observe: 'response'});
  }

  //No direct deleting of wallet is possible by its id
  // delete(id: number): Observable<HttpResponse<any>> { 
  //   return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  // }

  upload(wallets : IWallet[]) : Observable<EntityArrayResponseType> {
    return this.http.post<IWallet[]>(`${this.resourceUrl}-upload`, wallets, {observe : 'response'});
  }

  extend(id: number, days: number):  Observable<EntityResponseType> {
    return this.http.put<any>(`${this.resourceUrl}/${id}/extend/${days}`, { observe: 'response' });
  }
  
  extendWallet(id: number, days: number):  Observable<IWallet> {
    return this.http.put<any>(`${this.resourceUrl}/${id}/extend/${days}`, { observe: 'response' });
  }

  getSaldos(ids: number[]): Observable<any[]> {
    return this.http.post<any[]>(this.resourceUrl + "/saldos", ids);
  }
}
