<mat-spinner *ngIf="uploading"></mat-spinner>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <section id="dropzone" *ngIf="!uploading">
        {{title || 'cityIdApp.settings.selectedFile' | translate}}
<!-- <h1>readOnly: {{ readOnly }}</h1>         -->
        <div ng2FileDrop [class.processing]="uploading" (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)"
            class="well upload-drop-zone">
            <label class="custom-file-upload" [ngClass]="readOnly ? 'disabled' : 'enabled'" >
                <input [disabled]="readOnly" (change)="onChange($event)" type="file" [accept]="acceptExt" />
                {{'cityIdApp.blob.selectFile' | translate}}
            </label>
        </div>
        <div *ngIf="!readOnly" fxLayout="row" fxLayoutGap="20px">
            <button mat-raised-button class="button-style distance" color="primary" (click)="clear()" *ngIf="keyPresent"
                translate="cityIdApp.settings.clear"></button>
            <button mat-raised-button class="button-style distance" color="primary" (click)="onDownload()" *ngIf="keyPresent && !imgURL"
                translate="cityIdApp.settings.download"></button>
        </div>
    </section>
    <img [src]="imgURL" *ngIf="imgURL" (click)="onDownload()">
</div>