import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {IUserAccount} from '../model/cityIdcore/user-account.model';
import {createRequestOption} from '../util/util/request-util';
import {IUserAccountCredentials} from '../model/cityIdcore/user-account-credentials.model';
import {environment} from '../../../environments/environment';
import { IUserGroup, UserGroupAction } from '../model/cityIdcore/user-group.model';
import { Observable } from 'rxjs';

type EntityResponseType = HttpResponse<IUserAccount>;
type EntityArrayResponseType = HttpResponse<IUserAccount[]>;


@Injectable({providedIn: 'root'})
export class UserAccountService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/user-accounts';

  constructor(protected http: HttpClient) {
  }

  create(userAccount: IUserAccount): Observable<EntityResponseType> {
    return this.http.post<IUserAccount>(this.resourceUrl, userAccount, {observe: 'response'});
  }

  update(userAccount: IUserAccount): Observable<EntityResponseType> {
    return this.http.put<IUserAccount>(this.resourceUrl, userAccount, {observe: 'response'});
  }

  isCognitoUser(userName: string) : Observable<boolean> {
    return this.http.get<boolean>(this.resourceUrl + "/" + userName + "/cognito");
  }

  changePassword(userAccountCredentials: IUserAccountCredentials) {
    return this.http.post<any>(this.resourceUrl + '/change-password', userAccountCredentials, {observe: 'response'});
  }

  changeStatus(userName: string, status: boolean): Observable<any> {
    return this.http.put<IUserAccount>(`${this.resourceUrl}/${userName}/status`, status, {observe: 'response'});
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IUserAccount>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getUsertypes(): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(`${this.resourceUrl}/usertypes`, {observe: 'response'});
  }

  findByUserName(userName: string): Observable<EntityResponseType> {
    return this.http.get<IUserAccount>(`${this.resourceUrl}/by-username/${userName}`, {observe: 'response'});
  }

  getMFAStatus(username: string): Observable<any> {
    return this.http.get<any>(this.resourceUrl +'/' + username +'/mfa-status', { observe: 'response' });
  }

  getStatus(username: string): Observable<any> {
    return this.http.get<any>(this.resourceUrl +'/' + username +'/status', { observe: 'response' });
  }

  resetMFA(username: string): Observable<any> {
    return this.http.put<any>(this.resourceUrl +'/' + username +'/mfa-reset', { observe: 'response' });
  }

  getUserGroups(): Observable<HttpResponse<IUserGroup[]>> {
    return this.http.get<IUserGroup[]>(this.resourceUrl + '/groups', { observe: 'response' });
  }

  getUserGroupsCount(groupId: number): Observable<HttpResponse<number>> {
    return this.http.get<number>(this.resourceUrl + '/groups/' + groupId + "/count", { observe: 'response' });
  }

  getAllowedUserGroups(action:UserGroupAction): Observable<IUserGroup[]> {
    return this.http.get<IUserGroup[]>(this.resourceUrl + '/groups/allowed/' + action);
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IUserAccount[]>(this.resourceUrl, {params: options, observe: 'response'});
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getAuthEvents(userName: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(`${this.resourceUrl}/${userName}/events`, { observe: 'response' });
  }

}
