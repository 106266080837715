<div fxLayout="column" class="components-container-gt-xs" [ngClass.xs]="'components-container-xs'" fxLayoutGap="20px">

    <div fxFlex  class="white-bg">
  
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" class="p20 filter pb0 filter-section">
  
        <div fxFlex.xs="15" fxFlex.lg="15%" fxFlex.md="15%" fxFlex.sm="15%">
          <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.status"></mat-label>
            <mat-select multiple (selectionChange)="reload()" [(value)]="filter.status">
              <mat-option  *ngFor="let item of transactionStatus | keyvalue" value={{item.value}}>{{item.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div  *ngIf="!hideTypeSelector" fxFlex.xs="100" fxFlex.lg="15%" fxFlex.md="15%" fxFlex.sm="15%">
          <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.type"></mat-label>
            <mat-select multiple (selectionChange)="reload()" [(value)]="filter.type">
              <mat-option *ngFor="let item of transactionType | keyvalue" value={{item.value}}>{{item.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>  

        <!-- <input matInput [matDatepicker]="dp" [formControl]="date">         -->
        <div fxFlex.xs="100" fxFlex.lg="16.66%" fxFlex.md="16.66%" fxFlex.sm="16.66%" fxLayoutGap="5px">
          <!-- <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.choosedate"></mat-label>
            <input (dateChange)="reload()" matInput [matDatepicker]="picker" #fromDateFilter [formControl]="dateFrom"  placeholder="{{ 'cityIdApp.cityIdcoreTransaction.choosedate' | translate }}" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field> -->
          <app-local-date-selector [(localDate)]="dateFrom" [labelKey]="'cityIdApp.cityIdcoreTransaction.choosedate'" (localDateChange)="dateChanged()" 
              placeholder="'cityIdApp.cityIdcoreTransaction.choosedate' | translate"></app-local-date-selector>
        </div>
  
        <div fxFlex.xs="100" fxFlex.lg="16.66%" fxFlex.md="16.66%" fxFlex.sm="16.66%" fxLayoutGap="5px">
          <!-- <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.chooseenddate"></mat-label>
            <input (dateChange)="reload()" matInput [matDatepicker]="picker2" #toDateFilter [formControl]="dateTo"  placeholder="{{ 'cityIdApp.cityIdcoreTransaction.chooseenddate' | translate }}" >
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field> -->
          <app-local-date-selector [(localDate)]="dateTo" [labelKey]="'cityIdApp.cityIdcoreTransaction.chooseenddate'" (localDateChange)="dateChanged()" 
              placeholder="'cityIdApp.cityIdcoreTransaction.chooseenddate | translate'"></app-local-date-selector>
        </div>
  
        <div *ngIf="!hidePartnerSelector" fxFlex.xs="100" fxFlex.lg="15%" fxFlex.md="15%" fxFlex.sm="15%">
          <mat-form-field appearance="outline" class="fieldwidthLarge" *hasPermission="partnerPermission">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.provider"></mat-label>
            <select matNativeControl (change)="reload()" [(ngModel)]="filter.partnerId">
              <option [ngValue]="null">None</option>
              <option [ngValue]="partner.id" *ngFor="let partner of partners | async">
                {{partner.name}}
              </option>
            </select>
          </mat-form-field>
        </div>
  
        <div fxFlex.xs="100" fxFlex.lg="15%" fxFlex.md="15%" fxFlex.sm="15%" *hasPermission="accessTokenPermission">
          <mat-form-field class="example-full-width"  appearance="outline">
            <input matInput (change)="reload()" placeholder="{{ 'cityIdApp.cityIdcoreTransaction.accessToken' | translate }}" [(ngModel)]="filter.accesstoken">
          </mat-form-field>
        </div>



        <div fxFlex.xs="100" fxFlex.lg="15%" fxFlex.md="15%" fxFlex.sm="15%">
            <button mat-raised-button type="button" class="button-style" color="primary" (click)="openExportDialog()">Export</button>
        </div>

      </div>
      <div  fxLayout="row"  fxLayout.lt-sm="column">  
          <mat-button-toggle-group  #group="matButtonToggleGroup"  name="fontStyle" aria-label="Font Style"   [value]="filter.testToken" (change)="ontestTokenFilterChange(group.value)" >
            <mat-button-toggle value="include" >{{ 'cityIdApp.cityIdcoreTransaction.include' | translate }}</mat-button-toggle>
            <mat-button-toggle value="exclude">{{ 'cityIdApp.cityIdcoreTransaction.exclude' | translate }}</mat-button-toggle>
            <mat-button-toggle value="only">{{ 'cityIdApp.cityIdcoreTransaction.only' | translate }}</mat-button-toggle>
          </mat-button-toggle-group>
     
      </div> 
      <div style="display: flex; justify-content: center; align-items: center;" *ngIf="loading">
        <mat-spinner diameter="100"></mat-spinner>
      </div>
      <div class="table-container table-spc responsive-table">
        <app-responsivetable *ngIf="items" [totalItems]="totalItems" [tabledata]="items" [tableHeader]="tableHeader" (editItem)="edit($event)"
          (deleteItem)="delete($event)" (openDialog)="openDialog($event)"  (sortParameter)="sort($event)" (nextItem)="next($event)"></app-responsivetable>
      </div>
    </div>
  </div>
  