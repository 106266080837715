import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/shared/component/alert';
import { ReportService } from 'src/app/shared/service/report.service';
import { ToolbarService } from 'src/app/shared/service/toolbar.service';
import { IReport } from 'src/app/shared/model/cityIdcore/report.model';
import { LocalDate } from '@js-joda/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/shared/component/confirmdialog/confirmdialog.component';
import { NeededPermission, PermissionService } from 'src/app/shared/service/permission.service';

interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}
@Component({
  selector: 'app-report-generator',
  templateUrl: './report-generator.component.html',
  styleUrls: ['./report-generator.component.scss']
})
export class ReportGeneratorComponent implements OnInit, OnDestroy {
  myControl = new FormControl('');
  displayedColumns: string[];
  displayedColumnsNames: string[];
  isGenerated: boolean = false;
  selectedReport: IReport;
  selectedId: number;
  reportName: string = "";
  reports: IReport[];
  selectedBudgetIds: number[];
  selectedProgramIds: number[];
  tag: string;
  startDateKey: string;
  endDateKey: string;
  favorites: number[];
  now : Date = new Date();

  reportData;
  isRunning: Boolean = false;
  selectBudgets: boolean = false;
  selectPrograms: boolean = false;
  selectStartDate: boolean = false;
  selectEndDate: boolean = false;
  disableGenerate: boolean = true;
  reportsLoaded: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public columnShowHideList: CustomColumn[] = [];
  startDate: LocalDate = null;
  endDate: LocalDate = null;
  noResults: boolean;
  
  hasCreatePermission: boolean;
  hasReadPermission: boolean;
  hasReadOrUpdatePermission: boolean;
  hasUpdatePermission: boolean;
  hasDeletePermission: boolean;
  
  filteredReports: Observable<String[]>;

  constructor(
    private tbs: ToolbarService, 
    private reportService: ReportService,  
    private alertService: AlertService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    ) { }

  private getLocalDateOrNull(dateString: string): LocalDate {
    try {
      return dateString ? LocalDate.parse(dateString) : null;
    } catch(DateTimeParseException) {
      console.log('Invalid date from sessionstorage: ', dateString);
      return null;
    }
  };

  async ngOnInit() {
    this.hasCreatePermission = await this.permissionService.hasAllPermissions([NeededPermission.ReportCreate]);
    this.hasReadPermission = await this.permissionService.hasAllPermissions([NeededPermission.ReportRead]);
    this.hasUpdatePermission = await this.permissionService.hasAllPermissions([NeededPermission.ReportUpdate]);
    this.hasDeletePermission = await this.permissionService.hasAllPermissions([NeededPermission.ReportDelete]);
    this.hasReadOrUpdatePermission = this.hasReadPermission || this.hasUpdatePermission;
    this.loadAll()
  }

  loadAll() {
    this.tag = "report";
    this.startDateKey = this.tag + "_startdate"
    this.endDateKey = this.tag + "_enddate"

    let startDateFromSS = sessionStorage.getItem(this.startDateKey);
    let endDateFromSS = sessionStorage.getItem(this.endDateKey);

    this.startDate = this.getLocalDateOrNull(startDateFromSS)
    this.endDate = this.getLocalDateOrNull(endDateFromSS);

    this.tbs.dontShowPickLists();
    this.favorites = this.loadFavorites();
    this.reportService.getAllReports().subscribe(res => {
      this.selectedId = 0;
      this.selectedReport = null;
      this.createSortedReportList(res.body)
      this.reportsLoaded = true;
    });
  }

  ngOnDestroy(): void {
    this.tbs.reset();
    sessionStorage.setItem("report_startdate", this.startDate?.toString())
    sessionStorage.setItem("report_enddate", this.endDate?.toString())
  }

  generate() {
    this.isRunning = true;
    this.disableGenerate = true;
    this.reportData = null;
    this.noResults = false;
    this.reportService.generateReport(this.selectedId, this.selectedBudgetIds, this.selectedProgramIds, this.startDate, this.endDate).subscribe(res => {    
      if ( res.body.length > 0 ) {
        this.displayedColumns = Object.keys(res.body[0]);
        this.displayedColumnsNames = Object.keys(res.body[0]);
      } else {
        this.noResults=true;
      }
      this.reportName = this.reports.find(x => x.id === this.selectedId).name;
      this.isGenerated = true;
      this.reportData = res.body;  
      this.isRunning = false;
    },
      error => {
        this.alertService.error(error.error.detail, { autoClose: true, keepAfterRouteChange: false })
        this.isRunning = false;
      })
  }

  onDateChange(){
    this.disableGenerate = false;
  }
 
  reset() {
    this.selectBudgets = false;
    this.selectPrograms = false;
    this.selectStartDate = false;
    this.selectEndDate = false;
    this.noResults = false;
    this.reportData = null;
    this.isGenerated = false;
  }

  onReportChange(selection) {
    this.disableGenerate = false;  
    this.selectedReport = this.reports.find(x => x.id === this.selectedId)    
    this.reset();
    if ( this.selectedReport.parameters) {
      this.selectBudgets = this.selectedReport.parameters.includes("budgetIds");
      this.selectPrograms = this.selectedReport.parameters.includes("programIds");
      this.selectStartDate = this.selectedReport.parameters.includes("startDate");
      this.selectEndDate = this.selectedReport.parameters.includes("endDate");
    }
    if ( !this.selectStartDate) {
      this.startDate = null;
    }
    if ( !this.selectEndDate) {
      this.endDate = null;
    }
    if ( !this.selectBudgets) {
      this.selectedBudgetIds = [];
    }
    if ( !this.selectPrograms) {
      this.selectedProgramIds = [];
    }   
  }

  isFavorite(hide:boolean): boolean {
    if ( !this.selectedId) {
      return !hide;
    }
    return this.favorites.includes(this.selectedId);
  }

   favorite(favorite:boolean) {
    // console.log(favorite);
    if ( !this.favorites.includes(this.selectedId)) {
      if ( favorite ) {
         this.favorites.push(this.selectedId);
      }
    }
    else {
      if ( !favorite) {
        this.favorites = this.favorites.filter(item => item !== this.selectedId);
      }
    }
    this.updateFavorites();
   }

  hideGenerate(): boolean {
    if (!this.selectedReport) return true;
    if (this.isRunning) return true;
    if (this.disableGenerate) return true;
    if (this.selectBudgets && !this.selectedBudgetIds) {
      return true;
    }
    if (this.selectPrograms && !this.selectedProgramIds) {
      return true;
    }
    return false;
  }

  onBudgetIdsChange(ids: number[]){
    this.selectedBudgetIds = ids;
    this.disableGenerate = false;
  }

  onProgramIdsChange(ids: number[]){
    this.selectedProgramIds = ids;
    this.disableGenerate = false;
  }

  createSortedReportList(reports: IReport[]) {
    // first show the favorites
    var favReports: IReport[];
    favReports=reports.filter(r => this.favorites.includes(r.id)) .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    var otherReports: IReport[];
    otherReports= reports.filter(r => !this.favorites.includes(r.id)) .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    this.reports = [ ...favReports, ...otherReports];
  }

  loadFavorites(): number[] {
    if (!localStorage) {
      return [];
    }
    const loadedInfo = localStorage.getItem(`report-favorites`);
    if (loadedInfo) {
      return JSON.parse(loadedInfo);
    }
    return [];
  }

  updateFavorites() {
    if (!localStorage) {
      return;
    }
    localStorage.setItem(`report-favorites`, JSON.stringify(this.favorites));
    this.createSortedReportList(this.reports);
  }
  
  delete(id: number) {
    const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Are you sure?",
        message: "You are about to remove this Report."
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.reportService.delete(id).subscribe(res => {
          this.alertService.success("Report deleted succesfully", { autoClose: true, keepAfterRouteChange: false });
          this.loadAll();
        }, (error) => {
          this.alertService.error(error.error.title, { autoClose: true, keepAfterRouteChange: false });
        });
      }
    });
  }
  
  getActionLabel(): string {
    if (this.selectedId) {
      if (this.hasUpdatePermission) {
        return 'Edit';
      }
      return 'View';
    }
  }

}


