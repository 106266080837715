import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CognitoServiceProvider} from './auth/cognito-service';
import {environment} from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  title = 'ui-admin';
  authenticated =  false;

  constructor(translate: TranslateService,  private cognitoService: CognitoServiceProvider, private router: Router) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
  }

  ngOnInit() {

  this.cognitoService.getCurrentUser().then(user => {
    if (user) {
      this.authenticated = true;
    } else {
      this.router.navigate(['login'])
    }
  })
  .catch( e => {
    this.router.navigate(['login'])
    });      
  }
}
