import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocalDate } from '@js-joda/core';


//In de aanroepende component kunnen we de localdate selector gebruiken door in de template op te nemen:
// <app-local-date-selector [(localDate)]="localTestDate"></app-local-date-selector>
//Hierbij wordt 2way gebind met de locale variable localTestDate van type LocalDate.
// Om ook actie te kunnen uitvoeren op een change moeten we deze variable ale s property definieren:
// private _localTestDate: LocalDate;
// public get localTestDate(): LocalDate {
//     return this._localTestDate;
// }

// public set localTestDate(value: LocalDate) {
//     const changed = this._localTestDate !== value;
//     this._localTestDate = value;
//     if (changed) {
//        this.onLocalTestDateChanged();
//     }
// }  


@Component({
  selector: 'app-local-date-selector',
  templateUrl: './local-date-selector.component.html',
  styleUrls: ['./local-date-selector.component.scss'],
  providers: [
    { //We need to register the custom form control as a known value accessor in the dependency injection system
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LocalDateSelectorComponent),
        multi: true,    
    }
  ]
})
export class LocalDateSelectorComponent implements OnInit, ControlValueAccessor {

  @Input() labelKey: string;
  @Input() placeholder: string;
  @Input() localDate: LocalDate;
  @Input() readOnly: boolean;
  @Input() required: boolean;
  @Output() localDateChange = new EventEmitter<LocalDate>();

  onChange = (localDate) => {};
  onTouched = () => {};


  touched = false;

  ngOnInit(): void {
    if (this.readOnly == null) {
      this.readOnly = false;
    }
    if (this.required == null) {
      this.required = false;
    }
  }


  writeValue(localDate: LocalDate): void {
    this.localDate = localDate;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  //currently not called, input has apparently not an onTouch event
  markAsTouched() {
    // console.log('markAsTouched');
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) { //is called from directive
    this.readOnly = disabled;
  }

  dateChanged(event: MatDatepickerInputEvent<LocalDate>) {
    this.onChange(event.value);
    this.localDateChange.emit(event.value);

  }  
}
