
<h3>{{'cityIdApp.programSelector.organisations' | translate}}</h3>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" >
   
    <mat-checkbox class="select-all-checkbox" (change)="selectProgramsForAllOrganizations($event.checked)">{{'cityIdApp.programSelector.all' | translate}}</mat-checkbox>
    <mat-slide-toggle [(ngModel)]="activeOnOff" (change)="toggleMode()">Show Inactive Programs</mat-slide-toggle>
</div>
    
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" >
    <ul>
    <li *ngFor="let organization of organizations; let orgArrayIndex = index">
        
        <mat-checkbox class="select-all-checkbox"  id="{{ organization.id }}" 
            [(ngModel)]="selectionModelArray[orgArrayIndex].selectAllProgramsSelected"
            (ngModelChange)="selectAllPrograms(orgArrayIndex, $event)">
        </mat-checkbox>
        <span class="organisation-name">{{ organization.name }}</span>
        <ul class="programlist">
            <li *ngFor="let program of organization.programs; let progArrayIndex = index">
                <mat-checkbox id="{{program.id}}" 
                    [(ngModel)]="selectionModelArray[orgArrayIndex].programSelectionModelArray[progArrayIndex].selected"
                    (ngModelChange)="programSelectionChanged(orgArrayIndex, $event)">{{program.name}}
                </mat-checkbox>            
            </li>
        </ul>
        <hr>
    </li>
    </ul>
</div>

