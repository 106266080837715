import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlobService } from 'src/app/shared/service/blob.service';
import { CoreStatusService } from 'src/app/shared/service/core-status.service';
import packageInfo from '../../../../package.json';
import { CognitoServiceProvider } from 'src/app/auth/cognito-service';
import { CognitoUserSession } from "amazon-cognito-identity-js";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  public version: string = packageInfo.version;
  public vdate: string = packageInfo.vdate;
  public isUp: boolean = false;
  public coreVersion: string = "-----";
  public coreBuildTime: string = "";
  public showVersion: boolean;

  interval;

  constructor(private coreStatusService: CoreStatusService, private blobService: BlobService, private cognitoService: CognitoServiceProvider) { }
  ngOnDestroy(): void {
    console.log("destroy");
    clearInterval(this.interval);

  }

  ngOnInit(): void {
    console.log("start");
    this.showVersion = true;
    this.checkStatus();
    // this.checkwhenLoggedIn()
    // this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.checkwhenLoggedIn();
    },30000)
  }


  checkwhenLoggedIn() {
    this.cognitoService.getCurrentUser()
    .then( user => {
      console.log(user);
      console.log("auto logout:" + user.getUsername());
      user.getSession( (err: Error, session: CognitoUserSession) => {
        if ( session != null) {
          this.checkStatus();
        }

      });
      
    })
    .catch( error => {
      console.log(error);
      // do nothing is already signed out
      //console.log('No current user');        
    });
   
  }

  checkStatus() {
    this.coreStatusService.get()
      .subscribe((res) => 
      {
      
        this.isUp = true;
        this.coreBuildTime = res.body.time;
        this.coreVersion = res.body.version;
      },
       (res: HttpErrorResponse) => this.isUp = false);
  

  }
  pauseTimer() {
    clearInterval(this.interval);
  }

  toggle() {

    this.showVersion = !this.showVersion;
    console.log(this.showVersion);

  }

  onDownload() {
    this.blobService.download(0, "releasenotes").subscribe(
      response => {
        var newBlob = new Blob([response], { type: response.type});
        var url = window.URL.createObjectURL(newBlob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.setAttribute('target', 'blank');
        a.href = url;
        a.download = "ReleaseNotes.pdf";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

    }, error => {

        console.log(error);
    });
  }

}
