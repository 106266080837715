<div *hasPermission="readPermission()">
    <mat-toolbar class="table-header">
        <h1 class="mat-headline-5 text-left">{{ 'cityIdApp.cityIdcoreAccessToken.group.titleShort' | translate }}</h1>
        <!-- <h1 class="mat-headline-5 text-left"> 
            {{ 'cityIdApp.cityIdcoreAccessToken.group.title' | translate : { token:
            accessToken.token }
            }}
        </h1> -->
        <h1 class="mat-headline-5 text-left" *ngIf="isInGroup()"> {{'cityIdApp.cityIdcoreAccessToken.group.groupInfo' |
            translate: { name: accessToken.memberGroup.name, refId:accessToken.memberGroup.refId} }}</h1>
        <h1 class="mat-headline-5 text-left" *ngIf="!isInGroup()">{{ 'cityIdApp.cityIdcoreAccessToken.group.notInGroup'|
            translate }}</h1>
        <button *hasPermission="editPermission()" mat-raised-button class="button-style" (click)="addOrCreateGroup()">{{
            (isInGroup() ?
            'cityIdApp.cityIdcoreAccessToken.group.changeGroup' : 'cityIdApp.cityIdcoreAccessToken.group.addToGroup') |
            translate }}</button>
    </mat-toolbar>

    <mat-paginator #memberPaginator [length]="members.length" [pageSizeOptions]="[5,10,20,50,100]"
        showFirstLastButtons></mat-paginator>
    <table mat-table class="list-table" #table [dataSource]="membersDataSource" matSort matSortActive="name"
        matSortDirection="asc" style="margin-top: 20px;">

        <ng-container matColumnDef="token">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'cityIdApp.global.field.id' | translate}} </th>
            <td mat-cell *matCellDef="let row" class="link" [routerLink]="['/accesstoken', row.tokenId,'edit']">
                {{row.token}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'cityIdApp.global.field.name' | translate}} </th>
            <td mat-cell *matCellDef="let row" class="link" [routerLink]="['/participant', row.participantId,'edit']">
                {{row.participantName}} </td>
        </ng-container>

        <ng-container matColumnDef="manage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'cityIdApp.cityIdcoreAccessToken.group.master' |
                translate}} </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox [(ngModel)]="memberOnEdit.canManageGroup"
                    *ngIf="memberOnEdit.tokenId == row.tokenId"></mat-checkbox>
                <mat-checkbox [ngModel]="row.canManageGroup" *ngIf="memberOnEdit.tokenId != row.tokenId"
                    disabled></mat-checkbox>
                <!-- <mat-checkbox [checked]="row.canManageGroup"
                (change)="onChangeCanManage($event, row.tokenId)"></mat-checkbox> -->
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="color-set">
                <div *hasPermission="editPermission()">
                    <span (click)="editMember(row)" *ngIf="row.tokenId != memberOnEdit.tokenId">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.edit' | translate }}" aria-hidden="false"
                            aria-label="edit icon">edit</mat-icon>
                    </span>
                    <span (click)="removeFromGroup(row.tokenId)" *ngIf="row.tokenId != memberOnEdit.tokenId">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.delete' | translate }}"
                            aria-hidden="false" aria-label="delete icon">delete</mat-icon>
                    </span>
                    <span (click)="saveMember()" *ngIf="row.tokenId == memberOnEdit.tokenId">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.save' | translate }}" aria-hidden="false"
                            aria-label="save icon">save</mat-icon>
                    </span>
                    <span (click)="undoMember()" *ngIf="row.tokenId == memberOnEdit.tokenId">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.cancel' | translate }}"
                            aria-hidden="false" aria-label="cancel icon">cancel</mat-icon>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMembers"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMembers;"></tr>
    </table>
</div>