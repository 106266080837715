import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit, HostListener, Output, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BlobService } from 'src/app/shared/service/blob.service';


@Component({
  selector: 'app-blob-download',
  templateUrl: './blob-download.component.html',
  styleUrls: ['./blob-download.component.scss']
})
export class BlobDownloadComponent implements OnInit {
  @Input() refId: number;
  @Input() key: string;
  @Input() filename: string;
  @Input("as-icon") iconized : boolean;
  error: string;

  constructor(private http: HttpClient, private fb: UntypedFormBuilder, private blobService: BlobService
  ) { }

  ngOnInit() {
    this.iconized = this.iconized != undefined;
  }


  onDownload() {
    this.blobService.download(this.refId, this.key).subscribe(
      response => {
        var newBlob = new Blob([response], { type: response.type});
        var url = window.URL.createObjectURL(newBlob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.setAttribute('target', 'blank');
        a.href = url;
        a.download = this.filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

    }, error => {

        console.log(error);
    });
  
  }
}
