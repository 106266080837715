import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-program-selector-dialog',
  templateUrl: './program-selector-dialog.component.html',
  styleUrls: ['./program-selector-dialog.component.scss']
})
export class ProgramSelectorDialogComponent implements OnInit {
  ids: any;
  tag: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit(): void {
    this.tag = this.data.tag;
  }

  updateProgramIds(event) {
    // console.log('updateProgramIds: event = ', event);
    this.ids = event;
  }

  select() {
    let ids: number[] = [];
    this.data.save({ ids: this.ids });
  }

}
