import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import {IProgram} from '../model/cityIdcore/program.model';
import {map} from 'rxjs/internal/operators';
import {createRequestOption} from '../util/util/request-util';
import {environment} from '../../../environments/environment';

type EntityResponseType = HttpResponse<IProgram>;
type EntityArrayResponseType = HttpResponse<IProgram[]>;

@Injectable({providedIn: 'root'})
export class ProgramService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/programs';

  constructor(protected http: HttpClient) {
  }



  createWithOrganisation(program: IProgram, organisationId: number): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(program);
    return this.http
      .post<IProgram>(`${this.resourceUrl}-withorganisation/${organisationId}`, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(program: IProgram): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(program);
    return this.http
      .put<IProgram>(this.resourceUrl, copy, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IProgram>(`${this.resourceUrl}/${id}`, {observe: 'response'})
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByOrganisation(id: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IProgram[]>(`${this.resourceUrl}byOrganisationId/${id}`, {observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IProgram[]>(this.resourceUrl, {params: options, observe: 'response'})
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
  }

  getPayingPartyIds(id : number) : Observable<number[]> {
    return this.http.get<number[]>(this.resourceUrl + "/" + id + "/partyids");
  }

  setPayingPartyIds(id : number, parties : number[]) {
    if (!parties) {
      parties = [];
    }
    return this.http.put(this.resourceUrl + "/" + id + "/partyids", parties);
  }

  protected convertDateFromClient(program: IProgram): IProgram {
    const copy: IProgram = Object.assign({}, program, {
      //  start: program.start != null && program.start.isValid() ? program.start.format(DATE_FORMAT) : null,
      //  end: program.end != null && program.end.isValid() ? program.end.format(DATE_FORMAT) : null
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
    }
    return res;
  }

  //KVG this method and method above are probably obsolete
  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((program: IProgram) => {
      });
    }
    return res;
  }
}
