import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/component/alert/alert.service';
import { WalletService } from 'src/app/shared/service/wallet.service';
import { IWallet } from 'src/app/shared/model/cityIdcore/wallet.model';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-extend-wallet',
  templateUrl: './extend-wallet.component.html',
  styleUrls: ['./extend-wallet.component.scss']
})
export class ExtendWalletComponent implements OnInit {

  days: number;
  wallet: IWallet;
  endDate: LocalDate = null;
  orgEndDate: LocalDate = null;
  isDateOk:boolean= false;
  minDate: LocalDate = null;

  constructor(
    private walletService: WalletService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ExtendWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.wallet = data.wallet;
  }

  ngOnInit(): void {
    this.setDates();
  }

  setDates() {
    this.orgEndDate=LocalDate.parse(this.wallet.validityEnd);
    if ( this.orgEndDate.isAfter(LocalDate.now())) {
      this.minDate = this.orgEndDate;
    }
    else {
      this.minDate = LocalDate.now();
    }
    this.endDate =this.minDate;

  }
  onDateChange(){
    if (this.endDate.isAfter(this.minDate)) {
      this.isDateOk = true;
      this.days=this.endDate.toEpochDay() -this.minDate.toEpochDay();
    }
    else {
      this.isDateOk = false;
     
    } 
  }

  getOrgDate(){
    return this.minDate.format(DateTimeFormatter.ofPattern('dd-MM-yyyy'))
    
  }

  extend() {
    this.walletService.extendWallet(this.wallet.id, this.days).subscribe(res => {
      console.log(res.validityEnd + "-" + this.wallet.validityEnd);

      if ( res.validityEnd === this.wallet.validityEnd) {
        this.alertService.error("Wallet validity end date is unchanged", { autoClose: true, keepAfterRouteChange: true });
      } else {
        this.alertService.success("Wallet is extended", { autoClose: true, keepAfterRouteChange: true });
      }
      this.closeDialog();
    },
      (error) => {
        this.alertService.error(error.error.errorCode, { autoClose: true, keepAfterRouteChange: true });      
      });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
