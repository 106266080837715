import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'sidenav-menuitem',
  templateUrl: './menuitem.component.html',
  styleUrls: ['./menuitem.component.scss']
})
export class MenuitemComponent implements OnInit {
  @Input() menuItem;
  @Input() secondaryMenu = false;
  mode: String;
  panelOpenState: boolean;
  
  constructor() {}

  ngOnInit() {
    this.mode = environment.mode;
  }


  onItemClick(evt: Event) {  
    this.menuItem.open = !this.menuItem.open; 
  }

}
