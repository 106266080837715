<h1 mat-dialog-title>{{'cityIdApp.cityIdcoreAccessToken.group.addToGroup' | translate}}</h1>

<form #selectForm="ngForm">

<mat-dialog-content>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
            <mat-label>{{'cityIdApp.cityIdcoreAccessToken.group.addToGroup' | translate}}
            </mat-label>
            <mat-select [(ngModel)]="selectedGroup" (selectionChange)="onGroupChange()" name=group>
                <mat-option *ngFor="let option of groups" [value]="option">{{option.refId}}-{{ option.name}}</mat-option>
            </mat-select>
        </mat-form-field> 
        <mat-form-field appearance="outline" *ngIf="createNew()">
            <mat-label>{{'cityIdApp.cityIdcoreAccessToken.group.refid' | translate}}
            </mat-label>
            <input matInput [(ngModel)]="refId" name="refId" required type="number" />
        </mat-form-field>    
        <mat-form-field appearance="outline"  *ngIf="createNew()">
            <mat-label>{{'cityIdApp.cityIdcoreAccessToken.group.name' | translate}}
            </mat-label>
            <input matInput [(ngModel)]="name" name="name" required />
        </mat-form-field>
        <mat-checkbox [checked]="canManage" *ngIf="createNew()"
        (change)="onChangeCanManage($event)">{{'cityIdApp.cityIdcoreAccessToken.group.master' | translate}}</mat-checkbox>

    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <mat-toolbar class="bottom-bar">
        <span class="rightalign-spacer"></span>
        <button mat-raised-button class="button-style" color="primary" [disabled]="!selectForm.form.valid"  (click)="addToGroup()">{{'cityIdApp.cityIdcoreAccessToken.group.addToGroup' | translate}}</button>
        <button mat-raised-button type="button" class="button-style" color="warn" (click)="closeDialog()">Cancel</button>
    </mat-toolbar>
</mat-dialog-actions>    

</form>
