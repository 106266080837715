export class OrganizationSelectionModel {
  organizationId: number;
  selectAllProgramsSelected: boolean;
  programSelectionModelArray: ProgramSelectionModel[];
}

export class ProgramSelectionModel {
  programId: number;
  selected: boolean;
  }
  
  