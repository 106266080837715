

<h1 mat-dialog-title translate="cityIdApp.report.budgets"></h1>
<div mat-dialog-content class="mat-typography">

<div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex="40">
      <mat-label translate="cityIdApp.cityIdcoreOrganisation.name"></mat-label>
      <mat-select (selectionChange)="reload()" [(ngModel)]="filter.organisationId">
        <!-- <mat-option *ngFor="let organisation of organisations" [value]="organisation.id"
          [disabled]="filter.organisationId != organisation.id && hasSelection()">{{organisation.name}} -->
          <mat-option *ngFor="let organisation of organisations" [value]="organisation.id"
          >{{organisation.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
    class="mat-elevation-z8">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="isAllSelected()"
          [indeterminate]="isIndetermined()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="checkProgram($event.checked, row)"
          [checked]="row.checked">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'cityIdApp.cityIdcoreAllProviders.program' | translate
        }} </th>
      <td mat-cell *matCellDef="let elem">
        <div class="program-selected" *ngIf="elem.checked">{{elem.program.name}}</div>
        <div *ngIf="!elem.checked">{{elem.program.name}}</div>
        <div>
          <div *ngFor="let provisioning of elem.provisionings">
            <mat-checkbox [checked]="provisioningChecked(provisioning)" 
              [indeterminate]="!provisioningChecked(provisioning) && !provisioningUnchecked(provisioning)"
              (change)="checkProvisioning($event.checked, provisioning)">{{provisioning.name}}
            </mat-checkbox>
            <div *ngIf="!provisioningUnchecked(provisioning)">
              <div *ngFor="let budget of provisioning.budgets" style="margin-left: 20px">
                <mat-checkbox [checked]="budget.checked" (change)="checkBudget($event.checked, budget)">
                  {{budget.name}}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div mat-dialog-actions>
    <mat-toolbar class="bottom-bar"> 
      <span class="rightalign-spacer"></span>
      <button *ngIf="!saving" mat-raised-button type="button" (click)="save()"  class="button-style" color="primary" translate="cityIdApp.general.SELECT"></button>
      <div style="display: flex; justify-content: flex-end" *ngIf="saving">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
    </mat-toolbar>
    
  </div>
