import { EventEmitter, Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class ToolbarService {
    private showOrganisations : boolean;
    private showPrograms : boolean;
    disableChange : EventEmitter<boolean> = new EventEmitter();
    clearSearchField: EventEmitter<void> = new EventEmitter();

    constructor() {
        this.reset();
    }

    disablePickLists() {
        this.setDisabled(true);
    }

    dontShowPickLists() {
        this.showPrograms = this.showOrganisations = false;
    }

    dontShowPrograms() {
        this.showPrograms = false;
    }

    reset() {
        this.showOrganisations = true;
        this.showPrograms = true;
        this.setDisabled(false);
    }

    isShowPrograms() : boolean {
        return this.showPrograms;
    }

    isShowOrganisations() : boolean {
        return this.showOrganisations;
    }

    private setDisabled(v : boolean) {
        this.disableChange.emit(v);
    }
}
