<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
  <div class="input-container">
    <div fxLayout="column" fxLayoutGap="20px" style="align-items: baseline">
      <div>
        <p-inputNumber
          [(ngModel)]="value"
          [showButtons]="true"
          inputId="minmax"
          class="ng-invalid ng-dirty"
          mode="decimal"
          decrementButtonClass="button-style"
          incrementButtonClass="button-style"
          [min]="min"
          [max]="max"
     
        />
      </div>

      <div>
        <p-multiSelect
          [options]="availableVouchers"
          [(ngModel)]="selectedVouchers"
          optionLabel="description"
          placeholder="Select Vouchers"
          [virtualScroll]="true"
          [virtualScrollItemSize]="10"
    
        />
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <mat-toolbar class="bottom-bar">
    <span class="rightalign-spacer"></span>
    <button
      [disabled]="value < min || value > max"
      mat-raised-button
      color="primary"
      (click)="save()"
      translate="cityIdApp.general.save"
    ></button>
    <button
      mat-raised-button
      color="warn"
      (click)="cancel()"
      translate="cityIdApp.general.cancel"
    ></button>
  </mat-toolbar>
</mat-dialog-actions>
