import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/shared/component/alert';
import { IReport } from 'src/app/shared/model/cityIdcore/report.model';
import { NeededPermission, PermissionService } from 'src/app/shared/service/permission.service';
import { ReportService } from 'src/app/shared/service/report.service';
import { ToolbarService } from 'src/app/shared/service/toolbar.service';
import { Permission } from 'src/app/shared/model/cityIdcore/permission.model';

@Component({
  selector: 'app-report-editor',
  templateUrl: './report-editor.component.html',
  styleUrls: ['./report-editor.component.scss']
})
export class ReportEditorComponent implements OnInit, OnDestroy {

  reportId: number;
  editForm: FormGroup;
  allPermissions: Permission[] = [];
  selectedPermissions: Permission[] = [];
  sourcePermissions: Permission[] = [];
  targetPermissions: Permission[] = [];

  hasCreatePermission: boolean;
  hasUpdatePermission: boolean;

  constructor(private tbs: ToolbarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private permissionService: PermissionService,
    private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    this.tbs.dontShowPickLists();
    this.loadPermissions();
    this.hasCreatePermission = await this.permissionService.hasAllPermissions([NeededPermission.ReportCreate]);
    this.hasUpdatePermission = await this.permissionService.hasAllPermissions([NeededPermission.ReportUpdate]);
    this.reportId = this.activatedRoute.snapshot.params['id'];
    this.editForm = this.fb.nonNullable.group({
      id: [ null],    
      name: ['', [Validators.required]],
      description: [],
      query: ['', [Validators.required]],
      parameters: [''],
      permissions: []
    });
    this.editForm.controls.id.disable();
    if (this.reportId) {
      this.reportService.getReportById(this.reportId).subscribe(response => {
        let report: IReport = response.body;
        this.selectedPermissions = report.permissions;
        this.populateForm(report);
      },
      err => {
        console.log("Fout tijdens ophalen report: ", err);
      });
    } else {
      //new report
      this.editForm.reset();
    }
  }

  ngOnDestroy(): void {
    this.tbs.reset();
  }

  private populateForm(report: IReport) {
    this.editForm.patchValue({
      id: report.id,
      name: report.name,
      description: report.description,
      query: report.query,
      parameters: report.parameters.join(', '),
      permissions: report.permissions
      });
  }

  save() {
    // console.log('save report... editForm = ', this.editForm);
    let report: IReport = {};
    let formControls = this.editForm.controls;
    report.name = formControls.name.value;
    report.description = formControls.description.value;
    report.query = formControls.query.value;
    report.parameters = formControls.parameters.value.replace(/\s/g, '').split(',');
    report.permissions = this.selectedPermissions;
    if (this.reportId) {
      this.subscribeToSaveResponse(this.reportService.updateReport(this.reportId, report));
    } else {
      this.subscribeToSaveResponse(this.reportService.saveNewReport(report));
    }
  }

  private subscribeToSaveResponse(result: Observable<HttpResponse<IReport>>) {
    result.subscribe((res) => {
      this.populateForm(res.body);
      this.alertService.success("Report saved successfully", {autoClose:true, keepAfterRouteChange:true});
    }, (err) => {
      this.alertService.error(err.error.title, {autoClose:true, keepAfterRouteChange:true})
    });
  }

  navigateToOverview() {
    this.router.navigate(['/report/generate']);
  }  

  loadPermissions() {
    this.permissionService.getAllPermissions().subscribe(p => {
      this.allPermissions = p.sort((a, b) => a.name.localeCompare(b.name));
      this.cdr.markForCheck();
    });
  }

  compareItems(i1: any, i2: any) {
    return i1 && i2 && i1.id === i2.id;
  }

  editPermission() {
    if (this.reportId) {
      return [NeededPermission.ReportUpdate];
    }
    return [NeededPermission.ReportCreate];
  }

  isEditable(): boolean {
    if (this.reportId) {
      return this.hasUpdatePermission;
    }
    return this.hasCreatePermission;
  }

  getPageActionLabel(): string {
    if (this.reportId) {
      if (this.hasUpdatePermission) {
        return 'Edit';
      }
      return 'View';
    }
    return 'Add';
  }

}
