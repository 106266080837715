<div fxLayout="column" fxFlex class="table-component-holder mat-elevation-z4"  *hasPermission="permission()">
<mat-toolbar class="table-header" style="margin-bottom: 10px">
    <h1 class="mat-headline text-left">
        {{ 'cityIdApp.cityIdcoreAccessToken.walletTitle' | translate : { token: accessToken?.token } }}
    </h1>
</mat-toolbar>

<table mat-table class="list-table" [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc">
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate="cityIdApp.global.field.id"></th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.name"></th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.amount"></th>
        <td mat-cell *matCellDef="let row">{{ row.amount }}</td>
    </ng-container>

    <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.unit"></th>
        <td mat-cell *matCellDef="let row">{{ row.unit }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.type"></th>
        <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>

    <ng-container matColumnDef="validityStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.validityStart"></th>
        <td mat-cell *matCellDef="let row">{{ row.validityStart | date: 'shortDate' }}</td>
    </ng-container>

    <ng-container matColumnDef="validityEnd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.validityEnd"></th>
        <td mat-cell *matCellDef="let row">{{ row.validityEnd | date: 'shortDate' }}</td>
    </ng-container>

    <ng-container matColumnDef="add">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            translate="cityIdApp.cityIdcoreWallet.extendValidityEnd"></th>
        <td mat-cell *matCellDef="let element">
            <span class="clickable" (click)="add(element)"  *hasPermission="hasExtendPermission()">
                <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.extend' | translate }}" class="color-set"
                    aria-hidden="false" aria-label="edit icon">add</mat-icon>
            </span>
        </td>
    </ng-container>

</table>
</div>