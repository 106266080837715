import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AlertService } from "src/app/shared/component/alert";
import { IArticleValue, IPartnerArticle } from "src/app/shared/model/cityIdcore/article.model";
import { Unit } from "src/app/shared/model/enumerations/unit.model";
import { PartnerArticleService } from "src/app/shared/service/partner-article.service";
import { PartnerService } from "src/app/shared/service/partner.service";
import { ToolbarService } from "src/app/shared/service/toolbar.service";

@Component({
  selector: 'partner-article-values',
  templateUrl: './partner-article-values.component.html',
  styleUrls: ['./partner-article-values.component.scss']
})
export class PartnerArticleValuesComponent implements OnInit, OnDestroy {
  
  // private partnerId: number;
  partnerName: string;
  partnerArticle: IPartnerArticle;
  private partnerId: number;
  private articleId: number;


  constructor(private toolbarService : ToolbarService, private route : ActivatedRoute,
              private partnerArticleService: PartnerArticleService, 
              private alertService: AlertService) {}

  ngOnInit(): void {
    this.toolbarService.dontShowPickLists();
    this.route.params.subscribe(params => {
      this.partnerId = params.partnerId;
      this.articleId = params.articleId;

      if (!this.partnerId || !this.articleId) {
        throw Error('PartnerArticleValuesComponent needs route params partnerId and articleId');
      }

      this.partnerArticleService.findByPartnerIdAndArticleId(this.partnerId, this.articleId).subscribe(r => {
        this.partnerArticle = r;
        this.partnerName = r.partner.name;

        if (!this.partnerArticle.values) {
          this.partnerArticle.values = [];
        }
        this.checkValid();
      });
    });
  }

  ngOnDestroy(): void {
    this.toolbarService.reset();    
  }

  valid(a : IArticleValue) {
    return a.partnerValue && a.unit;
  }

  checkValid() {
    let values : IArticleValue[] = [];
    let invalidCount = 0;
    for (let cnt = 0; cnt < this.partnerArticle.values.length; cnt++) {
      let v = this.partnerArticle.values[cnt];
      if (this.valid(v)) {
        values.push(v);
      }
      else if (invalidCount == 0) {
        values.push(v);
        invalidCount++;
      }
    }
    if (invalidCount == 0) {
      values.push( {} as IArticleValue);
    }
    this.partnerArticle.values = values;
  }

  units() {
    let out = Object.keys(Unit);
    const index = out.indexOf("EURO");
    out.splice(index, 1);
    return out;
  }

  close() {
    history.back();
    
  }

  save() {
    this.partnerArticleService.saveArticleValues(this.partnerId, this.articleId, this.partnerArticle.values.filter(a => this.valid(a))).subscribe(r => {
      this.alertService.success('cityIdApp.cityIdcorePartnerArticles.valuesSaved');
    }, () => this.alertService.warn("cityIdApp.cityIdcorePartnerArticles.valuesError"));
  }
}
