import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, delay, retry, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {CognitoServiceProvider} from './cognito-service';
import { LoginService } from '../shared/service/login.service';
import { error } from 'protractor';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router, private cognitoService: CognitoServiceProvider, private loginService : LoginService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.indexOf('assets/i18n/') > 1) {
            return next.handle(req.clone());
        }
        if (req.headers.get('No-Auth') === 'True') {
            return next.handle(req.clone());
        }
        if (localStorage.getItem('accessToken') != null) {
            const clonedreq =this.addAuthHeader(req);
            return next.handle(clonedreq).pipe(catchError(error => {
                
                if (error && error.status === 401) {
                    const observable = from(this.cognitoService.refreshToken());
                    return observable.pipe(
                        take(1),
                        delay(1000),
                        switchMap(() => { return next.handle(this.addAuthHeader(req)); }),
                        retry(1)
                    );
                }
                else if (error && error.status === 403) {
                    this.router.navigateByUrl('/no-access');
                }
                throw error;
               // return next.handle(this.addAuthHeader(req));;// as Observable<HttpErrorResponse<any>>; 
            }),
            );
        } else {
            console.log("no accesstoken");
            this.loginService.logout();
        }
    }


    private addAuthHeader(req: HttpRequest<any>){
       // console.log("token: " + localStorage.getItem('accessToken').substring(localStorage.getItem('accessToken').length-10,localStorage.getItem('accessToken').length) );
        return req.clone({
            headers: req.headers.set(
                'Authorization',
                'Bearer ' + localStorage.getItem('accessToken')
            )
        });

    }


}
