import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BudgetSelectorComponent } from '../budget-selector.component';

@Component({
  selector: 'app-budget-button',
  templateUrl: './budget-button.component.html',
  styleUrls: ['./budget-button.component.scss']
})
export class BudgetButtonComponent implements OnInit {
  // selectedBudgetIds: number[];
  budgetsCount: number = 0;
  @Output() budgetIds = new EventEmitter<number[]>();
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.budgetsCount = 0;
  }

  selectBudgetIds() {
    let dialogRef = null;
    dialogRef = this.dialog.open(BudgetSelectorComponent, {
      minWidth: '500px',
      panelClass: 'custom-mat-dialog-panel',
      data: {
        save: (result) => {
          this.budgetsCount = result.budgets.length;
          this.budgetIds.emit(result.budgets) 
          dialogRef.close();
        },
        cancel: () => {     
          dialogRef.close();
        },
      }
    });
  }

}
