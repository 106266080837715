<div fxLayout="column" class="components-container-gt-xs" [ngClass.xs]="'components-container-xs'" fxLayoutGap="20px">

  <div fxFlex class="table-component-holder mat-elevation-z4">
    <mat-toolbar class="table-header">
      <h1 class="mat-headline-5 text-left">{{getPageActionLabel()}} Report</h1>
    </mat-toolbar>

    <form role="form" (ngSubmit)="save()" [formGroup]="editForm">
      <div class="input-container">

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline">
            <mat-label>ID</mat-label>
            <input matInput formControlName="id" >
          </mat-form-field>
        </div>      
      
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input [hasEditPermission]="editPermission()" matInput formControlName="name">
            </mat-form-field>
        </div>      

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input [hasEditPermission]="editPermission()" matInput formControlName="description">
          </mat-form-field>
        </div>      

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline" class="query">
            <mat-label>Query</mat-label>
            <textarea [hasEditPermission]="editPermission()" matInput formControlName="query" cdkTextareaAutosize
              cdkAutosizeMinRows="5" cdkAutosizeMaxRows="25"></textarea>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline">
            <mat-label>Parameters</mat-label>
            <input [hasEditPermission]="editPermission()" matInput formControlName="parameters">
          </mat-form-field>
        </div>

        <div class="card">
          <p-pickList [disabled]="!isEditable()" [source]="allPermissions" [target]="selectedPermissions" sourceHeader="Available Permisssions"
            targetHeader="Assigned Permisssions" [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '30rem' }"
            [targetStyle]="{ height: '30rem' }" sourceFilterPlaceholder="Search by name"
            targetFilterPlaceholder="Search by name" filterBy="name" breakpoint="1400px">
            <ng-template let-permission pTemplate="item">
              <div class="flex flex-wrap p-2 align-items-center gap-3">
                <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{permission.name}}</span>
                </div>
              </div>
            </ng-template>
          </p-pickList>
        </div>
  
        <mat-toolbar class="bottom-bar"> 
          <span class="rightalign-spacer"></span>
          <button *hasPermission="editPermission()" [disabled]= "editForm.invalid" mat-raised-button type="submit" class="button-style" color="primary">Save</button>
          <button mat-raised-button type="button" class="button-style" color="warn" (click)="navigateToOverview()">Close</button>
        </mat-toolbar>
    
      </div>
        
    </form>    
  
  </div>
</div>

