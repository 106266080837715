
<div fxLayout="column" class="components-container-gt-xs" [ngClass.xs]="'components-container-xs'" fxLayoutGap="20px">


    <div fxFlex class="table-component-holder mat-elevation-z4">
        <mat-toolbar class="table-header">
            <h1 class="mat-headline-5 text-left">{{'cityIdApp.about.title' | translate}}
              </h1>
        </mat-toolbar>
        <div class="input-container">
        <div fxLayout="row" fxLayout.lt-sm="column">
          
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{'cityIdApp.about.adminVersion' | translate}}
            </div>
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
        
                v{{version}}
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{'cityIdApp.about.adminDate' | translate}}
            </div>
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{vdate| date }}
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{'cityIdApp.about.coreVersion' | translate}}
            </div>
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{coreVersion}}
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{'cityIdApp.about.coreDate' | translate}}
            </div>
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{coreBuildTime| date }}
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column">
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                {{'cityIdApp.about.releasenotes' | translate}}
            </div>
            <div  fxFlex.xs="100" fxFlex.gt-lg="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50">
                <a (click)="onDownload()"><mat-icon>download</mat-icon></a>
            </div>
        </div>
        </div>
        
    </div>

</div>