<div *ngIf="logs.length; else elseBlock">

    <mat-toolbar class="table-header">
        <h1 class="mat-headline-5 text-left">Operation logs</h1>
    </mat-toolbar>

    <table mat-table [dataSource]="logs">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>ID</th>
            <td mat-cell *matCellDef="let log">
                {{ log.id }}
            </td>
        </ng-container>
        <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let log">
                {{ log.userName }}
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let log">
                {{ log.operation }}
            </td>
        </ng-container>
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>Comment</th>
            <td mat-cell *matCellDef="let log">
                {{ log.comment }}
            </td>
        </ng-container>
        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let log">
                {{ (log.creationDate | date: dateFormat) || '\(Empty\)' }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let log; columns: columnsToDisplay"></tr>

    </table>
</div>

<ng-template #elseBlock>
    <mat-toolbar class="table-header">
        <h1 class="mat-headline-5 text-left">No Logs</h1>
    </mat-toolbar>
</ng-template>