import { HttpResponse, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { createRequestOption } from "../util/util/request-util";
import IMemberGroup from "../model/cityIdcore/member-group.model";
import { Member } from "../model/cityIdcore/member.model";

type EntityResponseType = HttpResponse<IMemberGroup>;
type EntityArrayResponseType = HttpResponse<IMemberGroup[]>;

@Injectable({providedIn: 'root'})
export class AccessTokenGroupService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/member-groups';

  constructor(protected http: HttpClient) {
  }

  create(accessTokenGroup: IMemberGroup): Observable<EntityResponseType> {
    return this.http.post<IMemberGroup>(this.resourceUrl, accessTokenGroup, {observe: 'response'});
  }

  update(accessTokenGroup: IMemberGroup): Observable<EntityResponseType> {
    return this.http.put<IMemberGroup>(this.resourceUrl, accessTokenGroup, {observe: 'response'});
  }

  findByProgramId(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http.get<IMemberGroup[]>(this.resourceUrl, {params: options, observe: 'response'});
    
  }

  findAllByGroupId(id: number): Observable<HttpResponse<Member[]>> {
       return this.http.get<Member[]>(this.resourceUrl + "/" + id + "/access-tokens" , { observe: 'response'});
    
  }

}