import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class BlobService {
  public resourceUrl = environment.SERVER_API_URL + 'api/admin/v1/storage/blob';

  constructor(protected http: HttpClient) {
  }

  upload(refId: number, name: string, file: File): Observable<HttpEvent<{}>> {
    const data: FormData = new FormData();
    data.append('file', file);
    return this.http.put(`${this.resourceUrl}/${refId}/${name}`, data, {observe: 'response'});
  }

  download(refId: number, key: string): Observable<Blob>{
    return this.http.get(`${this.resourceUrl}/${refId}/${key}`, { responseType: 'blob' });
  }

  findBlobMeta(refId: number) : Observable<HttpResponse<any>> {
    return this.http.get(this.resourceUrl + "/meta/" + refId, {observe : 'response'});
  }
  
  findOneBlobMeta(refId: number, key: string) : Observable<HttpResponse<any>> {
    return this.http.get(this.resourceUrl + "/meta/" + refId + "/" + key, {observe : 'response'});
  }


  clear(refId: number, key: string) : Observable<HttpResponse<any>> {
    return this.http.delete(this.resourceUrl + "/" + refId + "/" + key, {observe: 'response'});
  }
}
