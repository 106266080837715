<div fxLayout="column" class="components-container-gt-xs" [ngClass.xs]="'components-container-xs'" fxLayoutGap="20px">

  <div fxFlex class="table-component-holder mat-elevation-z4">
    <mat-toolbar class="table-header">
      <h1 class="mat-headline-5 text-left">{{'cityIdApp.cityIdcoreTransaction.detail.title' | translate}}</h1>
    </mat-toolbar>

    <form name="editForm" role="form" novalidate  [formGroup]="editForm">
      <div class="input-container" fxLayout="column">
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" >
              <mat-label translate="cityIdApp.global.field.id"></mat-label>
              <input matInput placeholder="{{'global.field.id' | translate}}" formControlName="id" name="id">
              <!--  <mat-error *ngIf="editForm.get('token').invalid">Token is invalid</mat-error>-->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" >
              <mat-label translate="cityIdApp.cityIdcoreTransaction.status"></mat-label>
              <input matInput placeholder="{{'cityIdApp.cityIdcoreTransaction.status' | translate}}" formControlName="status" name="status">
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.accessToken"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.accessToken' | translate }}" name="accessToken" formControlName="accessToken" required>
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
          <mat-form-field appearance="outline" class="fieldwidthSmall">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.amount"></mat-label>
            <span  style="font-size:20px;" >
          
            <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.amount' | translate }}" name="amount" formControlName="amount">
            <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </span>
          </mat-form-field>
          <mat-form-field appearance="outline">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.unit"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.unit' | translate }}" name="unit" formControlName="unit">
          </mat-form-field>
          <mat-form-field *ngIf="transaction.type!='AddVoucher'" appearance="outline" class="fieldwidthSmall">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.amountInEuros"></mat-label>
            <span style="font-size:20px;" class="input-symbol-euro">
            <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.amountInEuros' | translate }}" name="monetaryAmount" formControlName="monetaryAmount">
            <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </span>
          </mat-form-field>
          <div *ngIf="voucherTransaction" fxLayout="row" fxLayoutGap="20px">
            <mat-form-field appearance="outline" *ngIf="voucherTransaction.customerValue" class="fieldwidthMedium">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.customerValue"></mat-label>
              <span style="font-size:20px;" class="input-symbol-euro">
                <input readonly matInput [value]="voucherTransaction.customerValue">
              </span>
            </mat-form-field>
            <mat-form-field appearance="outline" class="fieldwidthMedium" *ngIf="voucherTransaction.transactionValue">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.transactionValue"></mat-label>
              <span style="font-size:20px;" class="input-symbol-euro">
                <input readonly matInput [value]="voucherTransaction.transactionValue">
              </span>
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.time"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.time' | translate }}" name="time" formControlName="time" required>
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthMedium">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.controller"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.controller' | translate }}" name="controller" formControlName="controller">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.type"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.type' | translate }}" name="type" formControlName="type">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="100">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.createdBy"></mat-label>
            <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.createdBy' | translate }}" name="createdBy " formControlName="createdBy">
            <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="100">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.lastModifiedBy"></mat-label>
            <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.lastModifiedBy' | translate }}" name="lastModifiedBy" formControlName="lastModifiedBy">
            <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="100">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
            <mat-label translate="cityIdApp.cityIdcoreTransaction.lastModifiedDate"></mat-label>
            <input readonly  matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.lastModifiedDate' | translate }}"
                   name="lastModifiedDate" formControlName="lastModifiedDate">
            <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px" style="margin-bottom: 0px">

          <div>
            <div *ngIf="transaction.orgTransactionId">
             <a [routerLink]="['/transaction',orgTransactionId, 'edit']" class="link">
                <mat-icon>link </mat-icon>{{'cityIdApp.cityIdcoreTransaction.orgTransactionId' | translate}}: {{orgTransactionId}}
              </a>
            </div>
            <div  *ngIf="transaction.voucherId">
              <a [routerLink]="['/vouchers', 'edit', transaction.voucherId]" class="link">
                 <mat-icon>link </mat-icon> {{'cityIdApp.cityIdcoreTransaction.voucherId' | translate}}: {{transaction.voucherId}}
               </a>
             </div>
          </div>

        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.canceltime"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.canceltime' | translate }}" name="cancelTime" formControlName="cancelTime" >
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="100">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.cancelReason"></mat-label>
              <textarea readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.cancelReason' | translate }}" name="cancelReason" formControlName="cancelReason">
              </textarea><!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="100">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.description"></mat-label>
              <textarea readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.description' | translate }}" name="description" formControlName="description">
              </textarea><!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
        </div>
        <br>
        <hr>
        <br>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" >
              <mat-label translate="cityIdApp.cityIdcoreTransaction.providerId"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.providerId' | translate }}" name="providerId" formControlName="providerId">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.provider"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.provider' | translate }}" name="providerName" formControlName="providerName">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthMedium">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.externalTag"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.externalTag' | translate }}" name="externalTag" formControlName="externalTag">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" >
              <mat-label translate="cityIdApp.cityIdcoreTransaction.invoiceId"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.invoiceId' | translate }}" name="invoiceId" formControlName="invoiceId">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>



          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthLarge">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.invoiceAfter"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.invoiceAfter' | translate }}" name="invoiceAfter" formControlName="invoiceAfter">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>

          <div fxFlex.xs="100" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.gt-lg="50">
            <mat-form-field appearance="outline" class="fieldwidthMedium">
              <mat-label translate="cityIdApp.cityIdcoreTransaction.eanCode"></mat-label>
              <input readonly matInput placeholder="{{ 'cityIdApp.cityIdcoreTransaction.eanCode' | translate }}" name="eanCode" formControlName="eanCode">
              <!--   <mat-error *ngIf="label.invalid">Label is invalid</mat-error> -->
            </mat-form-field>
          </div>
        </div>

        <mat-toolbar class="bottom-bar"> 
          <span class="rightalign-spacer"></span>
          <button mat-raised-button type="button" class="button-style" color="warn" (click)="previousState()">Close</button>
        </mat-toolbar>
        
      </div>
    </form>
  </div>
</div>
