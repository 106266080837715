import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {transactionRoute} from './transaction.route';
import {SharedModule} from '../../shared.module';
import {TransactionUpdateComponent} from './transaction-update.component';
import {TransactionComponent} from './transaction.component';
import {TransactionRowComponent} from './transaction-row/transaction-row.component';
import { DatePipe } from '@angular/common';
import { TransactionOverviewComponent } from './transaction-overview/transaction-overview.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';


const ENTITY_STATES = [...transactionRoute];
@NgModule({
    imports: [SharedModule,MatButtonToggleModule, RouterModule.forChild(ENTITY_STATES)],
    declarations: [TransactionComponent, TransactionUpdateComponent, TransactionRowComponent, TransactionOverviewComponent, CancelDialogComponent],
    exports: [TransactionOverviewComponent],
    providers: [DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CityIdcoreTransactionModule {}
