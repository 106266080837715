import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTimeFormatter, Instant, LocalDate, LocalDateTime, LocalTime, ZoneId, ZoneOffset } from '@js-joda/core';
import { TranslateService } from '@ngx-translate/core';
import { ExportDialogComponent } from 'src/app/shared/component/export/export-dialog/export-dialog.component';
import { TransactionStatus } from 'src/app/shared/model/enumerations/transaction-status.model';
import { TransactionType } from 'src/app/shared/model/enumerations/transaction-type.model';
import { PageFilterStorageService } from 'src/app/shared/service/page-filter-storage.service';
import { ProviderService } from 'src/app/shared/service/provider.service';
import { TransactionService } from 'src/app/shared/service/transaction.service';
import { ResponsiveTableHeader } from 'src/app/shared/util/util/helpers';
import { BackendPaginationBaseComponent } from 'src/app/shared/util/util/pagination.component';
import { TransactionRowComponent } from '../transaction-row/transaction-row.component';
import { ITransaction } from 'src/app/shared/model/cityIdcore/transaction.model';
import { IPartner, IRelation } from 'src/app/shared/model/cityIdcore/partner.model';
import { DATE_TIME_SEC_FORMAT } from 'src/app/shared/util/constants/input.constants';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { NeededPermission } from 'src/app/shared/service/permission.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

export enum testToken {
  All = 'all',
  ONLY = 'only',
  EXCLUDE = 'exclude'
}
const DEFAULT_FILTER = {
  status: [TransactionStatus.PROCESSED.toString()],
  wallet: '',
  partnerId: null,
  accesstoken: '',
  vouchers: null,
  type: ['Payment'],
  fromDate: null,
  toDate: null,
  testToken: testToken.All.toString(),
}


@Component({
  selector: 'app-transaction-overview',
  templateUrl: './transaction-overview.component.html',
  styleUrls: ['./transaction-overview.component.scss']
})
export class TransactionOverviewComponent extends BackendPaginationBaseComponent<ITransaction> implements OnInit, OnDestroy, OnChanges {
  transactionType = TransactionType;
  transactionStatus = TransactionStatus;
  partners: Observable<IPartner[]>;
  returns: Object = {};
  superseded: string = "";
  retour: string = "";
  loading: boolean = false;
  filter = { ...DEFAULT_FILTER };
  sortOrder: string[] = ['time,desc'];
   selectedVal: string;

  // onChanges = new Subject<SimpleChanges>();

  private localStorageKey: string = 'transactions';
  private ngUnsubscribe = new Subject<void>();

  @Input() programIds: number[] = [];
  @Input() showOrgAndProgCols: boolean = true;
  @Input() storeFilters: boolean = true;
  @Input() typeFilter: TransactionType[];
  @Input() voucherIdFilter: number[];
  @Input() hideTypeSelector: boolean = false;
  @Input() hidePartnerSelector: boolean = false;
  @Input("partnerId") partnerIdPreselected: number;

  dateFrom: LocalDate = null;
  dateTo: LocalDate = null;

  constructor(private transactionService: TransactionService,
    private dialog: MatDialog,
    private pageFilterStorageService: PageFilterStorageService,
    private providerService: ProviderService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private transLateService: TranslateService,
  ) {
    super();
  }
  partnerPermission = [NeededPermission.PartnerRead];
  accessTokenPermission = [NeededPermission.AccesstokenRead];

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChanges, changes = ', changes);
    if (!changes.programIds.firstChange) {
      this.refresh();
    }
  }

  ngOnInit(): void {
    if (this.showOrgAndProgCols) {
      this.tableHeader.unshift({
        name: 'PROGRAM',
        key: 'programName',
        order: 'asc'
      });
    }
    this.transLateService.get("cityIdApp.cityIdcoreTransaction.superseded").subscribe(s => this.superseded = s);
    this.transLateService.get("cityIdApp.cityIdcoreTransaction.return").subscribe(s => this.retour = s);
    this.refresh();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  refresh() {
    this.loadPartners();
    this.updateFilters();
    // Load must be done last.
    this.reload();
  }

  public ontestTokenFilterChange(val: string) {
    this.filter.testToken = val;  
    this.reload();
  }
  dateChanged() {
    this.reload();
  }

  loadPartners() {
    this.partners = this.providerService.findByProgramIds(this.programIds).pipe(map(res => {
      let out: IPartner[] = [];
      let used = {};
      res.body.forEach(provider => {
        if (!used[provider.partner.id]) {
          out.push(provider.partner);
          used[provider.partner.id] = provider.partner;
        }
      });
      return out;
    }));
  }

  private getQueryFilter() {
    let queryFilter = {};
    if (this.programIds) {
      queryFilter['programId.in'] = this.programIds;
    }
    if (this.filter.status && this.filter.status.length > 0) {
      queryFilter['status.in'] = this.filter.status;
    }
    if (this.filter.vouchers && this.filter.vouchers.length > 0) {
      queryFilter['voucher.equals'] = this.filter.vouchers;
    }
    if (this.filter.wallet) {
      queryFilter['walletId.equals'] = this.filter.wallet;
    }
    if (this.filter.partnerId) {
      queryFilter['partnerId.equals'] = this.filter.partnerId;
    }
    if (this.filter.accesstoken) {
      queryFilter['accessToken.contains'] = this.filter.accesstoken;
    }
    if (this.filter.type && this.filter.type.length > 0) {
      queryFilter['type.in'] = this.filter.type;
    }
    if (this.dateFrom) {
      // let instantFrom = LocalDateTime.parse(this.fromDateFilter.nativeElement + 'T00:00:00').toInstant(ZoneOffset.UTC);
      queryFilter['time.greaterThanOrEqual'] = LocalDateTime.of(this.dateFrom, LocalTime.of(0, 0, 0)).toInstant(ZoneOffset.UTC);
    }
    if (this.dateTo) {
      let instantTo = LocalDateTime.of(this.dateTo, LocalTime.of(23, 59, 59)).toInstant(ZoneOffset.UTC);
      queryFilter['time.lessThanOrEqual'] = instantTo.toString();
    }
    if ( this.filter.testToken ) {
      if ( this.filter.testToken === "exclude") {
       queryFilter['testToken.equals'] = false;
      }
      else if (this.filter.testToken === "only") {
        queryFilter['testToken.equals'] = true;
      } 
    }
    queryFilter['page'] = this.page - 1;
    queryFilter['size'] = this.itemsPerPage;
    queryFilter['sort'] = this.sortOrder;
    return queryFilter;
  }

  reload() {
    this.page = 1;
    this.previousPage = 0;
    this.loadAll();
  }

  loadAll() {
    if (this.storeFilters) {
      this.updateFilterLS();
    }
    this.loading = true;
    this.transactionService.query(this.getQueryFilter()).subscribe(res => {
      this.returns = res.body.returns;
      this.paginate(res.body.transactions, res.headers);
      // this.totalItems = parseInt(res.headers.get('X-Total-Count'), 10);
      this.loading = false;
    });
  }

  delete(id) {
    alert('DELETE NOT ALLOWED');
  }

  edit(id) {
    this.router.navigate(['/accesstoken', id, 'edit']);
  }

  openDialog(id): void {
    const dialogRef = this.dialog.open(TransactionRowComponent, {
      width: '700px',
      data: id
    });
  }

  openExportDialog() {
    let prefix = environment.prefix;
    this.loading = true;
    let exportFilter = this.getQueryFilter();
    exportFilter['page'] = 0;
    exportFilter['size'] = 25000;
    this.transactionService.query(exportFilter).subscribe(res => {
      // max number of items
      let itemsFormatted = [];
      res.body.transactions.forEach((item) => {
        itemsFormatted.push({
          id: item.id,
          time: Instant.parse(item.time).atZone(ZoneId.SYSTEM).toLocalDateTime().format(DateTimeFormatter.ofPattern(DATE_TIME_SEC_FORMAT)),
          type: item.type,
          amount: item.amount,
          unit: item.unit,
          status: item.status,
          providerId: item.providerId ?? '',
          providerName: item.providerName ?? '',
          accessTokenId: item.accessTokenId ?? '',
          accessToken: item.accessToken ?? '',
          controller: item.controller ?? '',
          description: item.description ?? '',
          eanCode: item.eanCode ?? '',
          externalTag: item.externalTag ?? '',
          invoiceAfter: item.invoiceAfter ? Instant.parse(item.invoiceAfter).atZone(ZoneId.SYSTEM).toLocalDateTime().format(DateTimeFormatter.ofPattern(DATE_TIME_SEC_FORMAT)) : '',
          cancelTime: item.cancelTime ? Instant.parse(item.cancelTime).atZone(ZoneId.SYSTEM).toLocalDateTime().format(DateTimeFormatter.ofPattern(DATE_TIME_SEC_FORMAT)) : '',
          orgTransactionId: item.orgTransactionId ?? ''
        });
      });
      this.dialog.open(ExportDialogComponent, {
        width: '80%', //sets width of dialog
        height: '5700px%', //sets width of dialog
        maxWidth: '100vw', //overrides default width of dialog
        maxHeight: '100vh', //overrides default height of dialog
        data: { data: itemsFormatted, title: 'cityIdApp.cityIdcoreTransaction.home.title', saveTag: prefix + "Transaction Export" }
      });
      this.loading = false;
    });
  }

  private updateFilterLS() {
    let valuesToStore = new Map<string, string>([
      ["programIds", this.arrayToString(this.programIds.map(p => p + ""))],
      ["status", this.arrayToString(this.filter.status)],
      ["provider", this.filter.partnerId ? this.filter.partnerId.toString() : null],
      ["type", this.arrayToString(this.filter.type)],
      ["accesstoken", this.filter.accesstoken],
      ["fromDate", this.dateFrom?.toString() || ''],
      ["toDate", this.dateTo?.toString() || ''],
      ["testToken", this.filter.testToken ? this.filter.testToken : "all"]
    ]);
    this.pageFilterStorageService.writeFieldValuesToStorageForPage(this.localStorageKey, valuesToStore);
  }

  private updateFilters() {
    if (this.storeFilters) {
      this.updateFilterFromLS(this.pageFilterStorageService.readFieldValuesFromStorageForPage(this.localStorageKey));
    }
    //KVG: Als specifieke filters aan het component worden meegegeven overriden deze evt. wfilter waarden it de session storage
    if (this.typeFilter && this.typeFilter.length > 0) {
      this.filter.type = this.typeFilter;
    }
    if (this.voucherIdFilter && this.voucherIdFilter.length > 0) {
      this.filter.vouchers = this.voucherIdFilter;
    }
    if (this.partnerIdPreselected) {
      this.filter.partnerId = this.partnerIdPreselected;
    }
  }

  private updateFilterFromLS(valuesFromLS: Map<string, string>) {
    this.filter.status = valuesFromLS.get('status') ? this.stringToArray(valuesFromLS.get('status')) : DEFAULT_FILTER.status;
    this.filter.type = valuesFromLS.get('type') ? this.stringToArray(valuesFromLS.get('type')) : DEFAULT_FILTER.type;
    let fromDate = valuesFromLS.get('fromDate');
    let toDate = valuesFromLS.get('toDate');
    this.dateFrom = (fromDate ? LocalDate.parse(fromDate) : DEFAULT_FILTER.fromDate);
    this.dateTo = (toDate ? LocalDate.parse(toDate) : DEFAULT_FILTER.toDate);
    this.filter.accesstoken = valuesFromLS.get('accesstoken') ? valuesFromLS.get('accesstoken') : DEFAULT_FILTER.accesstoken;
    this.filter.partnerId = undefined;
    let programIds = this.stringToArray(valuesFromLS.get('programIds'));
    if (!programIds || !this.programIds) return;
    for (let cnt = 0; cnt < programIds.length; cnt++) {
      let thisId = programIds[cnt];
      let found = this.programIds.find(p => p == Number(thisId));
      if (!found) return;
    }
    this.filter.partnerId = Number(valuesFromLS.get('provider'));
    this.filter.testToken = valuesFromLS.get('testToken') ?(valuesFromLS.get('testToken')) : DEFAULT_FILTER.testToken;
  }

  protected onError(errorMessage: string) {
    console.log(errorMessage);
  }

  sort(event) {
    let converted: string[] = [];
    // Replace the sort key for the providerName to the actual path of the partner name.
    for (const s of event) {
      let changed = s
        .replace("providerName", "provider.partner.name")
        .replace("unit", "wallet.unit");
      converted.push(changed);
    }
    this.sortOrder = converted;
    this.loadAll();
  }

  private arrayToString(arr: string[]) {
    let str = arr && arr.join(',');
    return str;
  }

  private stringToArray(str: string) {
    return str && str.split(',');
  }

  tableHeader: Array<ResponsiveTableHeader> = [
    {
      name: 'ID',
      key: 'id',
      linkText: 'id',
      link: '/transaction/transactions',
      linkId: 'id',
      order: 'desc'
    },
    {
      name: 'Time',
      key: 'time',
      order: 'desc'
    },
    {
      name: 'Type',
      key: 'type',
      order: 'asc'
    },
    {
      name: 'Status',
      key: 'status',
      order: 'asc',
      sortingDisabled: true,
      render: (e) => {
        let related = this.returns[e.id];
        if (related !== undefined) {
          let title = "";
          if (related != null) {
            title = "title='" + this.superseded + " " + related + "'";
          }
          return "<span " + title + ">" + e.status + this.retour + "</span>";
        }
        return e.status;
      }
    },
    {
      name: 'Amount',
      key: 'amount',
      order: 'asc'
    },
    {
      name: 'Unit',
      key: 'unit',
      order: 'asc'
    },
    {
      name: 'Partner',
      key: 'providerName',
      linkText: 'providerName',
      link: '/provider',
      linkId: 'providerId',
      order: 'asc'
    },
    {
      name: 'Token',
      key: 'accessToken',
      linkText: 'accessToken',
      link: '/accesstoken',
      linkId: 'accessTokenId',
      order: 'asc',
      permission: [NeededPermission.AccesstokenRead]
    },
    {
      name: 'Controller',
      key: 'controller',
      order: 'asc'
    },
    {
      name: 'Invoiced',
      key: 'invoiceId',
      order: 'asc'
    },
    {
      name: '',
      key: 'wallet',
      link: '/wallet/accesstoken/',
      linkId: 'accessToken',
      order: 'asc',
      permission: [NeededPermission.AccesstokenRead]
    },
    {
      name: '',
      key: 'transactionrow',
      order: 'asc'
    },
    {
      name: '',
      key: 'view',
      link: '/transaction/transactions',
      order: 'asc'
    },
  ];

}
