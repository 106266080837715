import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOrganisation } from '../../model/cityIdcore/organisation.model';
import { OrganizationSelectionModel, ProgramSelectionModel } from '../../model/print-cards.model';
import { OrganisationService } from '../../service/organisation.service';

export class StoredSelection {
  orgarnisations: OrganizationSelectionModel[];
}
@Component({
  selector: 'app-program-selector',
  templateUrl: './program-selector.component.html',
  styleUrls: ['./program-selector.component.scss']
})
export class ProgramSelectorComponent implements OnInit {
  @Input() tag : String;
  @Output() onProgramIdsSelectionChange: EventEmitter<number[]> = new EventEmitter();
  activeOnOff: boolean;

  //organizations: IOrganizationShortWithPrograms[];
  organizations: IOrganisation[];
  selectionModelArray: OrganizationSelectionModel[];
      
  constructor(private organisationService: OrganisationService) {}

  ngOnInit(): void {
       this.refresh(false);
  }

  refresh(active: boolean) {
    this.organisationService.findAllWithPrograms(active).subscribe(res => {     
          this.organizations = res.body;
          this.initSelectionModel();    
          this.emitChangedEvent();
        });

  }

  toggleMode() {
    this.refresh(this.activeOnOff);
  }

  programSelectionChanged(orgArrayIndex, selected) {
   // console.log('programSelectionChanged, orgIndex: ', orgArrayIndex, ', selected = ', selected);
    if (!selected) {
      this.selectionModelArray[orgArrayIndex].selectAllProgramsSelected = false;
    }
    this.emitChangedEvent();
    // this.organizationSelectionModelMap.get(orgId).programSelectionModelMap.set(progId, evt);
  }

  selectProgramsForAllOrganizations(checked: boolean) {
   // console.log('selectAllOrganizations, evt = ', checked);
    for (let selectionModel of this.selectionModelArray) {
      selectionModel.selectAllProgramsSelected = checked;
      for (let programModel of selectionModel.programSelectionModelArray) {
        programModel.selected = checked;
      }
    }
    this.emitChangedEvent();
  }

  selectAllPrograms(orgArrayIndex, checked: boolean) {
   // console.log('selectAllPrograms, checked = ', checked);
    let organisationSelectionModel = this.selectionModelArray[orgArrayIndex];
    for (let programSelectionModel of organisationSelectionModel.programSelectionModelArray) {
      programSelectionModel.selected = checked;
    }
    this.emitChangedEvent();
  }

  private emitChangedEvent() {
    this.onProgramIdsSelectionChange.emit(this.selectedProgramIds());
  }

  private initSelectionModel() {
    this.selectionModelArray = [];
    let storedSelection = this.loadStoredSelection();
  
    for (let organization of this.organizations ) {
      let organizationSelectionModel = new OrganizationSelectionModel();
      organizationSelectionModel.organizationId = organization.id;
      let orgStored = null;
      orgStored = storedSelection.orgarnisations.find(o => o.organizationId === organization.id);
      if ( orgStored ) {
        organizationSelectionModel.selectAllProgramsSelected = orgStored.selectAllProgramsSelected;
      }
      else {
        organizationSelectionModel.selectAllProgramsSelected = false;
      }
      organizationSelectionModel.programSelectionModelArray = []; 
      for (let program of organization.programs) {
        let programSelections = new ProgramSelectionModel();
        programSelections.programId = program.id;
        if ( orgStored) {
        if ( orgStored.selectAllProgramsSelected) {
          programSelections.selected = true;
        }
        else  {
          let progStored=orgStored.programSelectionModelArray.find(p => p.programId === program.id);
          if ( progStored &&  progStored.selected ) {
            programSelections.selected = true;
          }
          else {
            programSelections.selected = false;
          }
        }
      }
      else {
        programSelections.selected = false;
      }
        organizationSelectionModel.programSelectionModelArray.push(programSelections);
      }
      this.selectionModelArray.push(organizationSelectionModel);
    }
  }


  private selectedProgramIds(): number[] {
    //KVG: TODO dit kan wss beter/mooier met lambda functions
    let programIds = [];
    for (let orgSelectionModel of this.selectionModelArray) {
      for (let progSelectionModel of orgSelectionModel.programSelectionModelArray) {
        if (progSelectionModel.selected) {
          programIds.push(progSelectionModel.programId);
        }
      }
    }
    this.saveStoredSelection();
    return programIds;  
  }


  loadStoredSelection(): StoredSelection {
    if (!localStorage) {
      return;
    }
    const loadedInfo = localStorage.getItem(this.tag+`-program-selections`);
    if (loadedInfo) {
      return JSON.parse(loadedInfo);
    }
    let empty : StoredSelection;
    empty = new StoredSelection();
    empty.orgarnisations = [];
    return empty;
  }

  saveStoredSelection(): void {
    if (!localStorage) {
      return;
    }
    let storedSeletion : StoredSelection;
    storedSeletion = new  StoredSelection();
    storedSeletion.orgarnisations = this.selectionModelArray;
    localStorage.setItem(this.tag+`-program-selections`, JSON.stringify(storedSeletion));
  }

}
