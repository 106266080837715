import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { BlobService } from 'src/app/shared/service/blob.service';
import { AlertService } from '../alert/alert.service';


@Component({
  selector: 'app-blob-upload',
  templateUrl: './blob-upload.component.html',
  styleUrls: ['./blob-upload.component.scss']
})
export class BlobUploadComponent implements OnInit {
  @Input() refId: number;
  @Input() key: string;
  @Input() acceptExt: string[];
  @Input() title: string;
  @Input() showPreview: boolean;
  @Input() fileName: string;
  @Input() readOnly: boolean = false;
  @Output() fileChange = new EventEmitter<string>();
  error: string;
  dragAreaClass: string;
  draggedFiles: any;
  keyPresent: boolean;
 
  file: File;
  isFileSelected: boolean;
  uploading: boolean;
  imgURL: any;

  constructor(
    private blobService: BlobService,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    this.isFileSelected = false;
    this.getFile();
  }


  onDragOver(event) {
    event.preventDefault();
  }

  onDropSuccess(event) {
    event.preventDefault();

    this.onFileChange(event.dataTransfer.files);
  }

  onChange(event) {
    this.onFileChange(event.target.files);
  }

  private onFileChange(files: FileList) {
    if (files.length && this.acceptExt.includes('.' + files[0].name.split('.').pop())) {
      this.fileName = files[0].name;
      this.fileChange.emit(this.fileName);
      this.file = files[0];
      this.isFileSelected = true;
      this.upload();

    }
  }

  preview(blob: Blob) {

    if (this.showPreview) {
      var mimeType = blob.type;
      if (mimeType.match(/image\/*/) == null) {
        console.log("Only images are supported.");
        return;
      }

      var reader = new FileReader();
      //this.imagePath = files;
      reader.readAsDataURL(blob);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
      }
    }
  }

  upload() {
    this.uploading = true;
    this.preview(this.file);
    this.blobService.upload(this.refId, this.key, this.file).subscribe(
      (res) => this.onSaveSuccess(res), (err) => this.onSaveError(err));
  }

  onUploadTemplate() {
    this.uploading = true;
    this.blobService.upload(this.refId, this.key, this.file).subscribe(
      (res) => this.onSaveSuccess(res), (err) => this.onSaveError(err));
  }

  getFile() {
    this.blobService.download(this.refId, this.key).subscribe(
      response => {
        var newBlob = new Blob([response], { type: response.type });
        this.preview(newBlob);
        this.keyPresent = true;
      }, error => {
        this.keyPresent = false;
        console.log(error);
      });

  }

  onDownload() {
    this.blobService.download(this.refId, this.key).subscribe(
      response => {
        var newBlob = new Blob([response], { type: response.type });
        var url = window.URL.createObjectURL(newBlob);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.setAttribute('target', 'blank');
        a.href = url;
        a.download = this.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

      }, error => {

        console.log(error);
      });

  }

  clear() {
    this.blobService.clear(this.refId, this.key).subscribe(response => {
      this.keyPresent = false;
      this.imgURL = undefined;
    });
  }

  protected onSaveSuccess(res) {
    this.uploading = false;
    this.isFileSelected = false;
    this.alertService.success("File " + this.file.name + " successfully uploaded", { autoClose: true, keepAfterRouteChange: true });
    this.keyPresent = true;
  }

  protected onSaveError(err) {
    this.uploading = false;
    this.alertService.error("File " + this.file.name + " failed to upload", { autoClose: true, keepAfterRouteChange: true });

  }

}
