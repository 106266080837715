import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Permission } from '../model/cityIdcore/permission.model';
import { IUserGroup } from 'src/app/shared/model/cityIdcore/user-group.model';

@Injectable({ providedIn: 'root' })
export class PermissionService {

  public resourceUrl = environment.SERVER_API_URL + 'api/admin/authorizations';
  public resourceAuthorizationsUrl = environment.SERVER_API_URL + 'api/authorizations';
  private permissions: NeededPermission[]=[];

  constructor(protected http: HttpClient) {
  }

  async hasSomePermissions(permissionsNeeded: NeededPermission[]): Promise<boolean> {
    if (this.permissions == undefined || this.permissions.length <= 0) {
      const rdy = await this.retrievePermissions();
    }
    if (permissionsNeeded == undefined || permissionsNeeded.length <= 0 || permissionsNeeded[0] == undefined) {
      return true;
    }
    return permissionsNeeded.some(r => this.permissions.includes(r));
  }

  async hasAllPermissions(permissionsNeeded: NeededPermission[]): Promise<boolean> {
    if (this.permissions == undefined || this.permissions.length <= 0) {
      const rdy = await this.retrievePermissions();
    }
    if (permissionsNeeded == undefined || permissionsNeeded.length <= 0 || permissionsNeeded[0] == undefined) {
      return true;
    }
    return permissionsNeeded.every(r => this.permissions.includes(r));
  }

  private async retrievePermissions(): Promise<boolean> {
    if (this.permissions == undefined || this.permissions.length <= 0) {
      const promise = this.getCurrentUserPermissions().toPromise();
      promise.catch((e) => {
        console.error(e.message); // "oh, no!"
      })
      this.permissions = await promise;
    }
    return new Promise((resolve, reject) => resolve(true));
  }

  
  getUsertypesByUser(userName: string): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(`${this.resourceAuthorizationsUrl}/${userName}/usertypes`, {observe: 'response'});
  }

  getCurrentUserPermissions(): Observable<NeededPermission[]> {
    return this.http.get<NeededPermission[]>(this.resourceUrl + "/users/permissions") 
  }

  getAllowedPermissions(usertype: string): Observable<Permission[]> {
    return this.http.get<Permission[]>(this.resourceUrl + "/allowed-permissions/" + usertype);
  }
  getAllPermissions(): Observable<Permission[]> {
    return this.http.get<Permission[]>(this.resourceUrl + "/permissions");
  }

  updateUsergroupPermission(id:number,permissions:Permission[]): Observable<Permission[]> {
    return this.http.put<Permission[]>(this.resourceUrl + "/usergroups/" + id + "/permissions" , permissions );
  }
 
  getUsergroupPermissions(id:number): Observable<Permission[]> {
    return this.http.get<Permission[]>(this.resourceUrl + "/usergroups/" + id + "/permissions" );
  }

  updateUsergroup(usergroup:IUserGroup): Observable<any> {
    return this.http.put<any>(this.resourceUrl + "/usergroups", usergroup );
  }

  addUsergroup(usergroup:IUserGroup): Observable<any> {
    return this.http.post<any>(this.resourceUrl + "/usergroups" , usergroup );
  }

  deleteUsergroup(id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrl + "/usergroups/" +  id );
  }

   async getSynchrousPermissions() : Promise<NeededPermission[]> {
    return  this.getCurrentUserPermissions().toPromise();
  }

  async getIt() {
    const perm = await this.getSynchrousPermissions();
    return perm;
  }

  clearCache() {
    this.permissions = [];
  }

}

export enum NeededPermission {
  AccesstokenCreate = "AccesstokenCreate",
  AccesstokenDelete = "AccesstokenDelete",
  AccesstokenRead = "AccesstokenRead",
  AccesstokenUpdate = "AccesstokenUpdate",
  AppClientCreate = "AppClientCreate",
  AppClientDelete = "AppClientDelete",
  AppClientRead = "AppClientRead",
  AppClientUpdate = "AppClientUpdate",
  ArticleCreate = "ArticleCreate",
  ArticleDelete = "ArticleDelete",
  ArticleExport = "ArticleExport",
  ArticleRead = "ArticleRead",
  ArticleUpdate = "ArticleUpdate",
  BudgetCreate = "BudgetCreate",
  BudgetDelete = "BudgetDelete",
  BudgetRead = "BudgetRead",
  BudgetUpdate = "BudgetUpdate",
  CharityCreate = "CharityCreate",
  CharityDelete = "CharityDelete",
  CharityRead = "CharityRead",
  CharityUpdate = "CharityUpdate",
  DBLClientCreate = "DBLClientCreate",
  DBLClientDelete = "DBLClientDelete",
  DBLClientUpdate = "DBLClientUpdate",
  InvoicingCreate = "InvoicingCreate",
  InvoicingDelete = "InvoicingDelete",
  InvoicingRead = "InvoicingRead",
  InvoicingRelationMail = "InvoicingRelationMail",
  InvoicingSEPARead = "InvoicingSEPARead",
  InvoicingUpdate = "InvoicingUpdate",
  ManualsCreate = "ManualsCreate",
  ManualsDelete = "ManualsDelete",
  ManualsUpdate = "ManualsUpdate",
  NewsletterCreate = "NewsletterCreate",
  NewsletterDelete = "NewsletterDelete",
  NewsletterRead = "NewsletterRead",
  NewsletterUpdate = "NewsletterUpdate",
  OrganisationCreate = "OrganisationCreate",
  OrganisationDelete = "OrganisationDelete",
  OrganisationRead = "OrganisationRead",
  OrganisationUpdate = "OrganisationUpdate",
  ParticipantCreate = "ParticipantCreate",
  ParticipantDelete = "ParticipantDelete",
  ParticipantRead = "ParticipantRead",
  ParticipantUpdate = "ParticipantUpdate",
  PartnerCreate = "PartnerCreate",
  PartnerDelete = "PartnerDelete",
  PartnerRead = "PartnerRead",
  PartnerTransactionRulesRead = "PartnerTransactionRulesRead",
  PartnerTransactionRulesUpdate = "PartnerTransactionRulesUpdate",
  PartnerUpdate = "PartnerUpdate",
  PrintCards = "PrintCards",
  ProductCreate = "ProductCreate",
  ProductDelete = "ProductDelete",
  ProductRead = "ProductRead",
  ProductUpdate = "ProductUpdate",
  ProductUpgrade = "ProductUpgrade",
  ProgramCreate = "ProgramCreate",
  ProgramDelete = "ProgramDelete",
  ProgramRead = "ProgramRead",
  ProgramUpdate = "ProgramUpdate",
  ProvisioningCreate = "ProvisioningCreate",
  ProvisioningDelete = "ProvisioningDelete",
  ProvisioningParticipantCreate = "ProvisioningParticipantCreate",
  ProvisioningParticipantDelete = "ProvisioningParticipantDelete",
  ProvisioningParticipantUpdate = "ProvisioningParticipantUpdate",
  ProvisioningPartnerCreate = "ProvisioningPartnerCreate",
  ProvisioningPartnerDelete = "ProvisioningPartnerDelete",
  ProvisioningPartnerRead = "ProvisioningPartnerRead",
  ProvisioningRead = "ProvisioningRead",
  ProvisioningUpdate = "ProvisioningUpdate",
  ReleaseNotesUpdate = "ReleaseNotesUpdate",
  ReportCreate = "ReportCreate",
  ReportRead = "ReportRead",
  ReportUpdate = "ReportUpdate",
  ReportDelete = "ReportDelete",
  SettingsRead = "SettingsRead",
  TargetAudienceRead = "TargetAudienceRead",
  TargetAudienceCreate = "TargetAudienceCreate",
  TargetAudienceUpdate = "TargetAudienceUpdate",
  TokenPoolCreate = "TokenPoolCreate",
  TokenPoolDelete = "TokenPoolDelete",
  TokenPoolRead = "TokenPoolRead",
  TokenPoolUpdate = "TokenPoolUpdate",
  TransactionRead = "TransactionRead",
  TransactionRulesRead = "TransactionRulesRead",
  TransactionRulesUpdate = "TransactionRulesUpdate",
  TransactionUpdate = "TransactionUpdate",
  UserAccountCreate = "UserAccountCreate",
  UserCityIdCreate = "UserCityIdCreate",
  UserCityIdDelete = "UserCityIdDelete",
  UserCityIdRead = "UserCityIdRead",
  UserCityIdUpdate = "UserCityIdUpdate",
  UserParticipantCreate = "UserParticipantCreate",
  UserParticipantDelete = "UserParticipantDelete",
  UserParticipantRead = "UserParticipantRead",
  UserParticipantUpdate = "UserParticipantUpdate",
  UserPartnerCreate = "UserPartnerCreate",
  UserPartnerDelete = "UserPartnerDelete",
  UserPartnerRead = "UserPartnerRead",
  UserPartnerUpdate = "UserPartnerUpdate",
  UserPortalCreate = "UserPortalCreate",
  UserPortalDelete = "UserPortalDelete",
  UserPortalRead = "UserPortalRead",
  UserPortalUpdate = "UserPortalUpdate",
  UserRead = "UserRead",
  UserRoleRead = "UserRoleRead",
  UserRoleCreate = "UserRoleCreate",
  UserRoleUpdate = "UserRoleUpdate",
  VoucherCreate = "VoucherCreate",
  VoucherDelete = "VoucherDelete",
  VoucherRead = "VoucherRead",
  VoucherUpdate = "VoucherUpdate",
  WalletCreate = "WalletCreate",
  WalletRead = "WalletRead",
  WalletUpdate = "WalletUpdate",

  // Not defined, not assigned to any user.
  NOTALLOWED = "NOTALLOWED"
}
