import { EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CognitoServiceProvider } from "src/app/auth/cognito-service";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AutoLogoutService } from "./auto-logout-service";
import { CoreStatusService } from "./core-status.service";
import { PermissionService } from "./permission.service";
import { UserAccountService } from "./user-account.service";



@Injectable({ providedIn: 'root' })
export class LoginService {
  @Output() onError: EventEmitter<string> = new EventEmitter();


  constructor(private cognitoService: CognitoServiceProvider, private router: Router,
    private coreStatusService: CoreStatusService, private permissionService: PermissionService,
    private userAccountSerivce: UserAccountService) { }


  public authenticate(email: string, password: string) {
    const authUserPromise = this.cognitoService.authenticate(email, password)
      .then(user => {
        if (!user.challengeName) {
          this.onError.emit("MFADisabled");
        }
        else {
          // retry with verification code required
          this.onError.emit("MFAEnabled");
        }
      })
      .catch(e => {
        this.onError.emit("WrongPWD");
        console.log(e);
      })
    return authUserPromise;

  }

  public login(email: string, password: string) {

    const authUserPromise = this.cognitoService.authenticate(email, password)
      .then(user => {
        if (!user.challengeName) {
          let userLoginSession: CognitoUserSession = user.getSignInUserSession();
          this.doLogin(user);          
        }
        else {
          // retry with verification code required
          this.onError.emit("MFAEnabled");
        }
      })
      .catch(e => {
        this.onError.emit("WrongPWD");
      })
    return authUserPromise;
  }

  // do the actual login en verfication on admin user
  private doLogin( user ) {//userLoginSession: CognitoUserSession) {      
    let userLoginSession: CognitoUserSession = user.getSignInUserSession();
      if (!userLoginSession) {
        return Promise.reject("Geen user session gevonden");
      }
      const cognitoAccessToken = userLoginSession.getAccessToken();
      const groups = cognitoAccessToken.payload['cognito:groups'];
      localStorage.setItem('accessToken', cognitoAccessToken.getJwtToken());
      this.permissionService.getUsertypesByUser(user.getUsername()).subscribe({
        next: res => {
          if (res.body.includes("CityIdAdmin")) {
            this.getPerms().then((perms) => {
              if (groups.indexOf('USER') === -1 || perms.length <= 0) {
                this.logout();
                return;
              }
              if (userLoginSession.isValid) {
                localStorage.setItem('accessToken', cognitoAccessToken.getJwtToken());
                localStorage.setItem('groups', groups);
                localStorage.setItem("email", userLoginSession.getIdToken().payload["email"]);                              
                if (!user.challengeName) {
                  sessionStorage.setItem('mfaEnabled', "false");
                  this.router.navigate(['login/enable-mfa']);
                }
                else {
                  sessionStorage.setItem('mfaEnabled', "true");
                  this.coreStatusService.start();
                  this.router.navigate(['manual/manuals/list']);
                }
              } else {
                this.logout();
              }
            })
              .catch(e => {
                this.onError.emit("WrongPWD");
              })
          } else {  
            // no ui-admin user
            this.logout(); 
            this.onError.emit("WrongPWD");
          }
        }
        , error: (e) => {
          this.onError.emit("WrongPWD");
          return Promise.reject("Geen user session gevonden");
       //   this.logout();
        }
      });


  }

  public loginWithMFA(email: string, password: string, verificationCode) {

    const authUserPromise = this.cognitoService.authenticate(email, password)
      .then(user => {

        user.sendMFACode(verificationCode,
          {
            onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
              let userLoginSession: CognitoUserSession = user.getSignInUserSession();
              this.doLogin(user);   
            },
            onFailure: (err: any) => {
              this.onError.emit("WrongCode");
              //this.router.navigate(['login', { requireMFA: true }]);

            }
          },
          'SOFTWARE_TOKEN_MFA')
      })
      .catch(e => {
        this.onError.emit("WrongPWD");
      //  console.log(e);
      })
  }

  public logout() {
    console.log('LoginService.logout() called');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('groups');
    localStorage.removeItem('email');
    localStorage.removeItem('mfaEnabled');
    this.coreStatusService.stop();
    this.permissionService.clearCache();
    this.cognitoService.signOut().finally(() => {
      this.router.navigate(['login']);
    })
      .catch(err => {
        console.log(err);
        this.router.navigate(['login']);
      })
  }


  private async getPerms() {
    return await this.permissionService.getCurrentUserPermissions().toPromise();
  }



}
