<mat-paginator [length]="totalItems"  [pageSize]="pageSize" [pageSizeOptions]="[5,10,20,50,100]" (page)="next($event)"  showFirstLastButtons>
</mat-paginator>
<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

    <ng-container *ngFor="let th of tableHeader">

        <ng-container matColumnDef="{{th.key}}">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="th.sortingDisabled"> {{th.name}}</th>
            <td mat-cell *matCellDef="let element">
              <span *hasPermission="permission(th.key)">
                <span *ngIf="hasRender(th)" [innerHtml]="th.render(element)"></span>
                <span *ngIf="th.key != 'edit' && th.key != 'delete' && th.key != 'wallet' && th.key != 'transactionrow' && !hasRender(th)">
                    <span  *ngIf="element[th.key] &&  (th.key.indexOf('.') === -1)  &&  !(th.link && th.linkId)
                                  && th.key!='transactionId'  && th.key!='originalAmount' && 
                                  th.key!='monetaryAmount' && th.key!='amount' &&
                                   !(th.type == 'date'|| th.type == 'datetime') && 
                                   th.key != 'time' && th.key != 'provtoken' && th.key != 'invoiceId' ">
                        {{element[th.key]}}
                    </span>

                    <span  *ngIf="th.key && th.key.indexOf('.') > 0 &&  !(th.link && th.linkId)">
                        {{getValueFromElement(element, th.key) }}
                    </span>

                    <span *ngIf="th.type == 'date'">
                        {{element[th.key] | date: 'shortDate'}}
                    </span>

                    <span *ngIf="th.key == 'time'">
                        {{element[th.key] | date: 'dd/MM/yyyy HH:mm:ss'}}
                    </span>

                    <span *ngIf="th.key === 'originalAmount' || th.key === 'amount'">
                        <!-- {{element[th.key]?(element[th.key] | currency: 'EUR').replace(',','').replace('.',','):''}} -->
                        {{ element[th.key] ? (element[th.key] | number : '1.2-2') : '0,00'}}
                    </span>
                    <span *ngIf="th.key === 'monetaryAmount'">
                            {{element[th.key]?(element[th.key] | currency: 'EUR').replace(',','').replace('.',','):''}} 
                        <!-- {{element[th.key]?(element[th.key] | number : '1.2-2').replace(',','').replace('.',','):'0,00'}} -->
                    </span>

                    <span  *ngIf="th.key == 'invoiceId'">
                        <input  disabled [checked]="element.invoiceId" type='checkbox'>
                    </span>

                    <span *ngIf="th.link && th.linkId">
                        <span class="link" [routerLink]="[th.link, element[th.linkId], 'edit']">{{element[th.linkText]}}
                        </span>
                    </span>
                    <span  *ngIf="th.linkId && th.linkId.indexOf('.') > 0 &&  (th.link && th.linkId)">
                        <span class="link" [routerLink]="[th.link, getValueFromElement(element,th.linkId), 'edit']">{{getValueFromElement(element,th.linkText)}}                      
                        </span>
                    </span>
                    <span *ngIf="th.key === 'wallet.accessTokenToken'">
                        <span class="link" [routerLink]="[th.link, element[th.linkId], 'edit']">{{element[th.linkText]}}
                        </span>
                    </span>
                </span>

                <span *ngIf="th.key=='wallet'" class="color-set">
                    <span class="clickable" [routerLink]="[th.link, element[th.linkId]]">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.walletBalance' | translate }}"  aria-hidden="false" aria-label="edit icon">account_balance_wallet</mat-icon>
                    </span>
                </span>
                <span *ngIf="th.key=='transactionrow'" class="color-set">
                    <span class="clickable" (click)="openDialogEmit(element.id)">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.transactionRow' | translate }}"  aria-hidden="false" aria-label="edit icon">description</mat-icon>
                    </span>
                </span>

                <span *ngIf="th.key=='view'" class="color-set">
                    <span class="clickable" [routerLink]="[th.link, element.id,'edit']">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.viewMore' | translate }}"   aria-hidden="false" aria-label="edit icon">visibility</mat-icon>
                    </span>
                </span>
                <span *ngIf="th.key=='delete'" class="color-set">
                    <span class="clickable" (click)="delete(element.id)">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.delete' | translate }}"  aria-hidden="false" aria-label="delete icon">delete</mat-icon>
                    </span>
                </span>
              <span *ngIf="th.key=='edit'" class="color-set">
                    <span class="clickable" [routerLink]="[th.link, element.id,'edit']">
                        <mat-icon matTooltip="{{ 'cityIdApp.cityIdcoreToolTip.edit' | translate }}" aria-hidden="false" aria-label="edit icon">edit</mat-icon>
                    </span>
                </span>
            </span>
          </td>
        </ng-container>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<!-- <mat-paginator [length]="totalItems" hidePageSize [pageSize]="pageSize" (page)="next($event)"  showFirstLastButtons>
</mat-paginator> -->

