<h1 mat-dialog-title>{{'cityIdApp.cityIdcoreWallet.extendDialog.title' | translate}}</h1>

<mat-dialog-content>
  <!-- <mat-form-field appearance="outline">
    <mat-label translate="cityIdApp.cityIdcoreWallet.extendDialog.amount"></mat-label>
    <input matInput type="number" [(ngModel)]="days" />
  </mat-form-field> -->
  <app-local-date-selector  [labelKey]="'cityIdApp.cityIdcoreWallet.extendDialog.select'" formControlName="expirationDate" 
  placeholder="{{ 'cityIdApp.global.choosedate' | translate }}" [(localDate)]="endDate" (localDateChange)="onDateChange()" ></app-local-date-selector>
  <div>
    <mat-label *ngIf="!isDateOk">{{'cityIdApp.cityIdcoreWallet.extendDialog.errorMsg' | translate}} {{getOrgDate()}}</mat-label>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <span class="rightalign-spacer"></span>
  <button *ngIf="isDateOk" mat-raised-button class="button-style" color="primary"
    (click)="extend()">{{'cityIdApp.cityIdcoreWallet.extendDialog.add' | translate}}</button>
  <button mat-raised-button type="button" class="button-style" color="warn" (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
