import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    constructor(private translate : TranslateService) {
    }
    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    private defaultOptions(options : any, autoClose : boolean, keepAfterRouteChange : boolean) : any {
        if (options == undefined) {
            options = {};
        }
        if (options.autoClose == undefined) {
            options.autoClose = autoClose;
        }
        if (options.keepAfterRouteChange == undefined) {
            options.keepAfterRouteChange = keepAfterRouteChange;
        }
        return options;
    }

    success(message: string, options?: any, parameters? : any) {
        this.i18nAlert(message, AlertType.Success, parameters, this.defaultOptions(options, true, false));
    }

    error(message: string, options?: any, parameters? : any) {
        this.i18nAlert(message, AlertType.Error, parameters, this.defaultOptions(options, false, true));
    }

    info(message: string, options?: any, parameters? : any) {
        this.i18nAlert(message, AlertType.Info, parameters, this.defaultOptions(options, true, false));
    }

    warn(message: string, options?: any, parameters? : any) {
        this.i18nAlert(message, AlertType.Warning, parameters, this.defaultOptions(options, false, false));
    }

    i18nAlert(key : string, type : AlertType, parameters? : any, options? : any) {
        this.translate.get(key, parameters).subscribe((message : string) => {
            this.alert(new Alert({...options, type: type, message}));
        });
    }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }

    handleError(error, options?: any, parameters?: any) {
        let message = "unknown error";
        if (error.status == 418 && error.error instanceof Array) {
            // Assume it is a message exception
            message = error.error[0].default;
        }
        else if (error.status == 400 && error.error.detail) {
            // Error response body
            message = error.error.detail;
        }
        else if (error.status >= 500) {
            message = "oops, server error, something severe went wrong"
        }
        this.error(message, options, parameters);
    }
}