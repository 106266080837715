<h1 mat-dialog-title>Transaction Row</h1>

<mat-dialog-content>
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="transactionRowNumber">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.transactionRowNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="walletName">
            <th mat-header-cell *matHeaderCellDef> Wallet Name </th>
            <td mat-cell *matCellDef="let element"> {{element.walletName}} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let element"> {{(element.amount | number : '1.2-2').replace(',','').replace('.',',')}} </td>
        </ng-container>

        <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef> Unit </th>
            <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
        </ng-container>

        <ng-container matColumnDef="monetaryAmount">
            <th mat-header-cell *matHeaderCellDef> Amount in Euro </th>
            <td mat-cell *matCellDef="let element"> {{element.monetaryAmount}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</mat-dialog-content>
