import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CostsAndSavingsSetting, LinearConversion } from '../../service/savings-transaction-rules.service';

@Component({
  selector: 'percentage-fixed',
  templateUrl: './percentage-fixed.component.html',
  styleUrls: ['./percentage-fixed.component.scss']
})
export class PercentageFixedComponent implements OnInit {
  @Input() 
  set value(v : CostsAndSavingsSetting) {
    this.copy(v);
    this.original = v;
  };
  get value() {
    return this.get();
  }
  @Output() valueChange = new EventEmitter<CostsAndSavingsSetting>();
  percentage : number;
  fixed : number;
  original : CostsAndSavingsSetting;
  @Input() isDisabled: boolean;

  ngOnInit(): void {
  }

  copy(v : CostsAndSavingsSetting) {
    if (v == undefined || v.value == undefined) return;
    this.percentage = v.value.percentage;
    this.fixed = v.value.fixed;
  }

  get() : CostsAndSavingsSetting {
    var v = new CostsAndSavingsSetting();
    v.isInherited = this.original.isInherited && !this.isChanged();
    if (this.fixed === undefined && this.percentage == undefined) {
      v.isInherited = true;
    }
    else {
      v.value = new LinearConversion();
      v.value.percentage = this.percentage;
      v.value.fixed = this.fixed;
    }
    return v;
  }

  inherited() : boolean {
    return this.original && this.original.isInherited;
  }

  isChanged() : boolean {
    if (this.percentage && this.fixed) {
      return this.original.value.percentage.toFixed(2) != this.percentage.toFixed(2) || 
        this.original.value.fixed.toFixed(2) != this.fixed.toFixed(2);
    }
    return true;
  }

  changed() {
    if (this.percentage == undefined || this.percentage == null || this.fixed == undefined || this.fixed == null) {
      this.fixed = undefined;
      this.percentage = undefined;
    }
    if (this.isChanged()) {
      this.valueChange.emit(this.get());
    }
  }

  public static checkNumber(x : any) : number {
    if (x == undefined || x == null || x.toString().trim().length == 0) return undefined;
    let y = x.toString().replace(',', '.');
    y = y.replace(/[A-Za-z]/g, '');
    if (y.length == 0 || Number.isNaN(y)) {
      return undefined;
    }
    return Number(y);
  }

  percentageChanged() {
    this.percentage = PercentageFixedComponent.checkNumber(this.percentage);
    if (this.percentage < 0 ) {
      this.percentage = 0;
    }
    else if (this.percentage > 100) {
      this.percentage = 100;
    }
    this.changed();
  }

  fixedChanged() {
    this.fixed = PercentageFixedComponent.checkNumber(this.fixed);
    if (this.fixed < 0) {
      this.fixed = 0;
    }
    if (this.fixed > 999) {
      this.fixed = 999;
    }
    this.changed();
  }
}
