import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {TransactionService} from '../../shared/service/transaction.service';
import {ITransaction} from '../../shared/model/cityIdcore/transaction.model';
import { ToolbarService } from 'src/app/shared/service/toolbar.service';
import { VoucherService } from 'src/app/shared/service/voucher.service';
import { IVoucherTransaction } from 'src/app/shared/model/cityIdcore/voucher.model';

@Component({
  selector: 'app-transaction-update',
  templateUrl: './transaction-update.component.html',
  styleUrls: ['./transaction-update.component.scss']
})
export class TransactionUpdateComponent implements OnInit, OnDestroy {
  //dateFormat : Date;
  isSaving: boolean;
  isInEuro: boolean;
  transactionId: number;
  orgTransactionId : number;
  transaction : ITransaction;
  voucherTransaction : IVoucherTransaction;

  editForm = this.fb.group({
    id: [{ value: null, disabled: true }],
    status:[],
    accessToken: [],
    amount: [],
    unit: [],
    providerId: [{ value: null, disabled: true }],
    providerName: [],
    time: [],
    controller: [],
    type: [],
    description: [],
    eanCode: [],
    externalTag: [],
    invoiceAfter: [],
    invoiceId : [],
    orgTransactionId: [],
    createdBy: [],
    lastModifiedBy: [],
    lastModifiedDate: [],
    cancelTime: [],
    cancelReason: [],
    monetaryAmount: []

  });
  constructor(protected transactionService: TransactionService,  protected activatedRoute: ActivatedRoute,
    private toolbarService : ToolbarService, private fb: UntypedFormBuilder, private voucherService : VoucherService) {}

  ngOnInit() {
    this.isSaving = false;
    this.toolbarService.disablePickLists();
    this.activatedRoute.data.subscribe(({ transaction }) => {
      this.transaction = transaction;
      this.transactionId = transaction.id;
      this.orgTransactionId = transaction.orgTransactionId;
      this.isInEuro = (transaction.unit === "EURO");
      this.updateForm(transaction);
      if (this.transaction.voucherId) {
        this.voucherService.findVoucherTransaction(this.transaction.id).subscribe(v => this.voucherTransaction = v);
      }
      //this.editForm.disable();
    });
  }
  ngOnDestroy(): void {
      this.toolbarService.reset();
  }

  updateForm(transaction: ITransaction) {
    this.editForm.patchValue({
      id: transaction.id,
      status: transaction.status,
      accessToken: transaction.accessToken,
      amount: transaction.amount,
      unit: transaction.unit,
      time:  transaction.time,
      providerId: transaction.providerId,
      providerName: transaction.providerName,
      controller: transaction.controller,
      type: transaction.type,
      description: transaction.description,
      eanCode: transaction.eanCode,
      externalTag: transaction.externalTag,
      invoiceAfter: transaction.invoiceAfter,
      invoiceId : transaction.invoiceId,
      orgTransactionId: transaction.orgTransactionId,
      createdBy: transaction.createdBy,
      lastModifiedBy: transaction.lastModifiedBy,
      cancelTime: transaction.cancelTime,
      cancelReason: transaction.cancelReason,
      monetaryAmount: transaction.monetaryAmount === null? Math.abs(transaction.amount):transaction.monetaryAmount.toFixed(2)
    });
  }
  previousState() {
    window.history.back();
  }


  getOrgTransactionId(){
   return this.orgTransactionId;
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<ITransaction>>) {
    result.subscribe(() => this.onSaveSuccess(), () => this.onSaveError());
  }
  protected onSaveSuccess() {
    this.isSaving = false;
    this.previousState();
  }
  protected onSaveError() {
    this.isSaving = false;
  }
}
