import { UserAccountService } from 'src/app/shared/service/user-account.service';
import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-useraccount-list-basic',
  templateUrl: './useraccount-list-basic.component.html',
  styleUrls: ['./useraccount-list-basic.component.scss']
})
export class UseraccountListBasicComponent {

  displayedColumnsUser: string[] = ['name', 'lastName', 'userName', 'email', 'edit'];
  dataSourceUser: any;
  @Input() id: number;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userAccountService: UserAccountService) { }

  ngOnInit() {
    this.load(this.id);
  }

  load(id: number) {
    this.userAccountService.query({ "partnerId.equals": id }).subscribe(res => {
      this.dataSourceUser = new MatTableDataSource<any>(res.body);
    });
  }

}
