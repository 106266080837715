import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-number-input-dialog',
  templateUrl: './number-input-dialog.component.html',
  styleUrls: ['./number-input-dialog.component.scss']
})
export class NumberInputDialogComponent  implements OnInit {

  title: string;
  min: number;
  max:number;
  step:number;
  value = 1;


  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<NumberInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
    this.title = data.title;  
    this.min = data.min;
    this.max = data.max;
    this.step = data.step;
    this.value = this.min;  
  }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(this.value);
  }

  cancel() {
    this.dialogRef.close();
  }

}
