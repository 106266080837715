import { fromEvent as observableFromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { CognitoServiceProvider } from '../../../auth/cognito-service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserAccountService } from '../../../shared/service/user-account.service';
import { AlertService } from '../alert';
import { ToolbarService } from '../../service/toolbar.service';
import { Subscription } from 'rxjs';
import { LoginService } from '../../service/login.service';
import { UntypedFormControl } from '@angular/forms';
import { IOrganisation } from '../../model/cityIdcore/organisation.model';
import { IProgram } from '../../model/cityIdcore/program.model';
import { GlobalService } from '../../service/global.service';
import { ProgramService } from '../../service/program.service';
import { OrganisationService } from '../../service/organisation.service';
import { merge } from 'rxjs';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output()
  toogleIcon = new EventEmitter<boolean>();
  mode: string;
  currentUserName = '';
  isOpen = false;
  showOptionalSearchPanel: boolean = false;
  optionalPanelChecked: boolean = false;

  tokenSearch = '';
  lastNameSearch = '';
  postalCodeSearch = '';
  houseNumberSearch = '';

  queryFilter = {};
  private clearSearchFieldSubscription: Subscription;

  @ViewChild('inputOrganisation') inputOrganisation: ElementRef<HTMLInputElement>;
  @ViewChild('inputProgram') inputProgram: ElementRef<HTMLInputElement>;
  @ViewChild('search', { static: true }) tokenFilter: ElementRef;
  @ViewChild('lastNameSearchId', { static: true }) lastNameFilter: ElementRef;
  @ViewChild('postalCodeSearchId', { static: true }) postalCodeFilter: ElementRef;
  @ViewChild('houseNumberSearchId', { static: true }) houseNumberFilter: ElementRef;
  
  readonly TOKEN_SEARCH_MINLENGTH = 6;
  readonly LAST_NAME_SEARCH_MINLENGTH = 3;
  readonly POSTAL_CODE_SEARCH_MINLENGTH = 4;
  readonly HOUSE_NUMBER_SEARCH_MINLENGTH = 1;

  private organisations: IOrganisation[] = [];
  private programs: IProgram[] = [];

  filteredOrganisationOptions: IOrganisation[];
  filteredProgramOptions: IProgram[];

  organisationControl = new UntypedFormControl();
  programControl = new UntypedFormControl();

  constructor(
    private cognitoService: CognitoServiceProvider,
    private router: Router,
    protected userAccountService: UserAccountService,
    private alertService: AlertService,
    private programService: ProgramService,
    public toolbarService: ToolbarService,
    private loginService: LoginService,
    private globalService: GlobalService,
    private organisationService: OrganisationService) { }

  ngOnInit() {
    this.mode = environment.mode;
    this.cognitoService.getCurrentUser()
      .then(user => {
        this.currentUserName = user.getUsername();
      })
      .catch(error => {
        this.router.navigate(['login'])
      });
    this.loadOrganisations();
    this.globalService.newProgram.subscribe(() => {
      this.onOrgChange(this.globalService.getOrganisation());
    });
    this.globalService.newOrganisation.subscribe(() => {
      this.loadOrganisations();
    });
    this.globalService.programChange.subscribe(v => {
      if (v) {
        this.updateFormControls();
      }
    });
    this.toolbarService.disableChange.subscribe((v) => {
      // console.log('disableChange ', v, this.partialAccessTokenSearch, this.clearSearchFieldSubscription);
      if (v) {
        this.organisationControl.disable();
        this.programControl.disable();
      } else {
        this.organisationControl.enable();
        this.programControl.enable();
        this.clearMainSearchField();
        this.clearOptionalSearchFields();
        this.showOptionalSearchPanel = false;
        this.optionalPanelChecked = false;
      }
    });
    this.clearSearchFieldSubscription = this.toolbarService.clearSearchField.subscribe(() => {
      console.log('clearSearchField subscription triggered, field is cleared');
      this.clearMainSearchField();
    });
    const searchEvents$ = merge(
      observableFromEvent(this.tokenFilter.nativeElement, 'keyup'),
      observableFromEvent(this.tokenFilter.nativeElement, 'search'),
      observableFromEvent(this.lastNameFilter.nativeElement, 'keyup'),
      observableFromEvent(this.lastNameFilter.nativeElement, 'search'),
      observableFromEvent(this.postalCodeFilter.nativeElement, 'keyup'),
      observableFromEvent(this.postalCodeFilter.nativeElement, 'search'),
      observableFromEvent(this.houseNumberFilter.nativeElement, 'keyup'),
      observableFromEvent(this.houseNumberFilter.nativeElement, 'search')
    ).pipe(
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => this.trimSearchFields()),
      tap(() => this.searchAccessTokens())
    );
    const subscription = searchEvents$.subscribe();      
  }

  trimSearchFields() {
    this.tokenSearch = this.tokenSearch.trim();
    this.lastNameSearch = this.lastNameSearch.trim();
    this.postalCodeSearch = this.postalCodeSearch.trim();
    this.houseNumberSearch = this.houseNumberSearch.trim();
  }

  clearMainSearchField() {
    this.tokenSearch = '';
  }

  clearOptionalSearchFields() {
    this.lastNameSearch = '';
    this.postalCodeSearch = '';
    this.houseNumberSearch = '';
  }

  isSearchViable(): boolean {
    const val =
      (!this.tokenSearch.length || (this.tokenSearch.length && this.isTokenSearchFieldValid())) &&
      (!this.lastNameSearch.length || (this.lastNameSearch.length && this.isNameSearchFieldValid())) &&
      (!this.postalCodeSearch.length || (this.postalCodeSearch.length && this.isPostalSearchFieldValid())) &&
      (!this.houseNumberSearch.length || (this.houseNumberSearch.length && this.isHouseNumberSearchFieldValid())) &&
      (this.isTokenSearchFieldValid() || this.isNameSearchFieldValid() || this.isPostalSearchFieldValid() || this.isHouseNumberSearchFieldValid());
    return val;
  }

  isTokenSearchFieldValid(): boolean {
    return this.tokenSearch.length >= this.TOKEN_SEARCH_MINLENGTH
  }

  isNameSearchFieldValid(): boolean {
    return this.lastNameSearch.length >= this.LAST_NAME_SEARCH_MINLENGTH
  }

  isPostalSearchFieldValid(): boolean {
    return this.postalCodeSearch.length >= this.POSTAL_CODE_SEARCH_MINLENGTH
  }

  isHouseNumberSearchFieldValid(): boolean {
    return this.houseNumberSearch.length >= this.HOUSE_NUMBER_SEARCH_MINLENGTH
  }

  toggleOptionalSearchPanel() {
    this.showOptionalSearchPanel = !this.showOptionalSearchPanel;
    if (!this.showOptionalSearchPanel) {
      this.clearOptionalSearchFields();
      if (this.isTokenSearchFieldValid()) {
        this.searchAccessTokens();
      }
    } else {
      this.optionalPanelChecked = true;
    }
  }

  ngAfterViewInit(): void {
    // this.loadOrganisations();
  }

  ngOnDestroy(): void {
    this.clearSearchFieldSubscription.unsubscribe();
  }

  changeStatus() {
    this.isOpen = !this.isOpen;
  }

  signOut() {
    this.loginService.logout();
  }

  private updateFormControls() {
    const program: IProgram = this.globalService.getProgram();
    this.programControl.setValue(program);
    this.organisationService.findForProgram(program.id).subscribe(res => {
      const organisation = res.body;
      this.organisationControl.setValue(organisation);
    });
  }

  private setDefaultOrganisationValue() {
    let organisation = this.globalService.getOrganisation();
    if (organisation) {
      this.organisationControl.setValue(organisation);
    } else {
      organisation = this.organisations.find(o => o.id === 1);
      this.globalService.setOrganisation(organisation);
      this.organisationControl.setValue(organisation);
    }
    this.onOrgChange(organisation);
  }

  private setDefaultProgramValue() {
    let program = this.globalService.getProgram();
    if (!program) {
      // just select the first in the row
      program = this.programs.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))[0];
    }
    if (program && this.programs.map(p => p.id).includes(program.id)) {
      this.globalService.setProgram(program);
      this.programControl.setValue(program);
    }
  }

  onOrgChange(val: IOrganisation) {
    this.globalService.setOrganisation(val);
    // this.organisationId = val.id;
    this.programControl.setValue('');
    this.programService.findByOrganisation(val.id).subscribe(res => {
      this.programs = res.body;
      this.setDefaultProgramValue();
      this.filteredProgramOptions = this.programs;
    });
  }

  onProgChange(val: IProgram) {
    this.globalService.setProgram(val);
    this.router.navigate([this.router.url.replace('\\', '')]);
  }

  private loadOrganisations() {
    this.organisationService.query().subscribe(res => {
      this.organisations = res.body;
      this.setDefaultOrganisationValue();
      this.filteredOrganisationOptions = this.organisations;
    });
  }

  onOrganisationClose() {
    this.organisationControl.setValue(this.globalService.getOrganisation());
  }

  onProgramClose() {
    this.programControl.setValue(this.globalService.getProgram());
  }

  displayFn(option) {
    return option ? option.name : '';
  }

  myProfile() {
    this.userAccountService.findByUserName(this.currentUserName).subscribe(res => {
      if (res.ok) {
        this.router.navigate(['/useraccount', res.body.id, 'edit']);
      }
    });
  }

  searchAccessTokens() {
    this.trimSearchFields();
    if (this.tokenSearch) {
      // if (this.partialAccessTokenSearch.length < this.MINLENGTH) {
      //   this.alertService.error('Partieel AccessToken moet minimaal ' + this.MINLENGTH + ' karakters lang zijn', { autoClose: true });
      //   return;
      // }
      const isNumeric = /^\d+$/.test(this.tokenSearch);
      if (!isNumeric) {
        this.alertService.error('Accesstoken kan alleen maar cijfers bevatten', { autoClose: true });
        return;
      }
    }
    this.setFilter();
    if (this.isSearchViable()) {
      this.router.navigate(['/accesstoken', 'globalsearchresult'], { queryParams: this.queryFilter });
    }
  }

  setFilter() {
    this.queryFilter['size'] = 1000;
    if (this.tokenSearch !== null && this.tokenSearch !== '') {
      this.queryFilter['token.contains'] = this.tokenSearch;
    } else {
      delete this.queryFilter['token.contains'];
    }
    if (this.lastNameSearch !== null && this.lastNameSearch !== '') {
      this.queryFilter['lastName.contains'] = this.lastNameSearch;
    } else {
      delete this.queryFilter['lastName.contains'];
    }
    if (this.postalCodeSearch !== null && this.postalCodeSearch !== '') {
      this.queryFilter['postalCode.contains'] = this.postalCodeSearch;
    } else {
      delete this.queryFilter['postalCode.contains'];
    }
    if (this.houseNumberSearch !== null && this.houseNumberSearch !== '') {
      this.queryFilter['houseNumber.equals'] = this.houseNumberSearch;
    } else {
      delete this.queryFilter['houseNumber.equals'];
    }
  }

  filterOrg(): void {
    const filterValue = this.inputOrganisation.nativeElement.value.toLowerCase();
    this.filteredOrganisationOptions = this.organisations.filter(o => o.name?.toLowerCase().includes(filterValue.toLowerCase()));
  }

  filterProg(): void {
    const filterValue = this.inputProgram.nativeElement.value.toLowerCase();
    this.filteredProgramOptions = this.programs.filter(p => p.name?.toLowerCase().includes(filterValue.toLowerCase()));
  }

}
