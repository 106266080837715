<div class="upload-result">
    <span class="green"  >{{ successCount }} Success</span>
    <span class="red" >{{ errorCount }} Failed</span>
    <section class="progress-section" *ngIf="showProgressBar">
      <mat-progress-bar
          class="progress-margin"
          mode="determinate"
          [value]="progress"
         >
      </mat-progress-bar>
    </section>
  </div>
