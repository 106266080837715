import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from 'src/app/shared/service/provider.service';
import { IPartner } from 'src/app/shared/model/cityIdcore/partner.model';
import { MatSelect } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { IProvider } from 'src/app/shared/model/cityIdcore/provider.model';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'partner-picker-dialog',
  templateUrl: './partner-picker.component.html',
  styleUrls: ['./partner-picker.component.scss']
})
export class PartnerPickerDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('select') select: MatSelect;
  // selected = [];
  // allSelected = false;
  selectedProgramId: number;
  // filteredList;
  // partners: Partner[] = [];
  // tag: string = "vouchers"

  //nieuw KVG
  protected partners: IPartner[] = [];
  public partnerMultiCtrl: FormControl<IPartner[]> = new FormControl<IPartner[]>([]);
  public partnerMultiFilterCtrl: FormControl<string> = new FormControl<string>('');
  public filteredPartnersMulti: ReplaySubject<IProvider[]> = new ReplaySubject<IPartner[]>(1);
  @ViewChild('multiSelect', {static: false}) multiSelect: MatSelect;


//   private multiSelect: MatSelect;
//   @ViewChild('multiSelect',  {static: false}) set content(content: MatSelect) {
//     if(content) { // initially setter gets called with undefined
//         this.multiSelect = content;
//     }
//  }


  protected _onDestroy = new Subject<void>();



  constructor(
    public dialogRef: MatDialogRef<PartnerPickerDialogComponent>,
    private providerService: ProviderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.partnerMultiCtrl.setValue([]);
    this.filteredPartnersMulti.next([]);
    this.selectedProgramId = this.data.programId;
    if ( this.selectedProgramId) {
      this.loadPartners(this.selectedProgramId);
    }

    this.partnerMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe( () => {
      this.filterPartnersMulti();
    });
  }

  ngAfterViewInit(): void {
    this.setInitialValue();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  protected setInitialValue() {
    this.filteredPartnersMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe( () => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredPartners are loaded initially
        // and after the mat-option elements are available        
        if (this.multiSelect) {
          this.multiSelect.compareWith = (a: IProvider, b: IProvider) => a && b && a.id === b.id;
        }
      });
  }


  protected filterPartnersMulti() {
    if (!this.partners) {
      return;
    }
    //get the search keyword
    let search = this.partnerMultiFilterCtrl.value;
    if (!search) {
      this.filteredPartnersMulti.next(this.partners.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    //filter the partners
    this.filteredPartnersMulti.next(this.partners.filter( partner => partner.name.toLowerCase().indexOf(search) > -1));
  }


  loadPartners(programId: number) {
    this.providerService.findByProgramId(programId).subscribe(res => {
      this.partners = res.body.map(provider => provider.partner).sort((a, b) => a.name.localeCompare(b.name));
      this.filterPartnersMulti();
    });
  }


  close() {
    this.dialogRef.close({ data: this.partnerMultiCtrl.value.map(partner => partner.id) });
  }


  onProgramChange(programId: number) {
    this.selectedProgramId = programId;
    this.loadPartners(programId);
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredPartnersMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        if (selectAllValue) {
          this.partnerMultiCtrl.patchValue([...this.partners]);
        } else {
          this.partnerMultiCtrl.patchValue([]);
        }
      });

  }



}
